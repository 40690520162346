.suspects-list {
  &__card {
    background-color: white;
    padding: 1rem;
    display: flex;
    border-radius: 20px;
    align-items: center;
    justify-content: space-between;
  }

  &__empty{
    justify-content: center;
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__check {
    padding: 0.5rem;
    background-color: @approved-status-color;
    color: white;
    border-radius: 50%;
    font-size: 1.5rem;
    font-weight: bolder;
  }

  &__filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__user {
    font-size: 1.2rem;
    font-weight: bolder;
  }

  &__identifier {
    font-weight: bolder;
    color: @primary-color;
    padding: 0 !important;
    margin: 0 !important;
  }

  &__delete {
    color: @refused-status-color;
    font-size: 1.5rem;
    cursor: pointer;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;

    @media @tab-md {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__header {
    display: flex;
    background-color: white;
    border-radius: 20px;
    align-items: center;
    margin-bottom: 1rem;
    justify-content: space-between;
    padding: 2rem;
    @media @tab-md {
      flex-direction: column;
    }
    svg {
      min-width: 150px!important;
      min-height: 150px !important;
      margin-right: 1rem;
      @media @tab-md {
        display: none;
      }
    }
  }

  &__add-user {
    @media @tab-md {
      margin-top: 1rem;
    }
  }

  &__input-search {
    width: 30%;

    @media @tab-md {
      width: 100%;
    }
  }
}