.configuration-side-bar {
  max-width: 200px;
  min-width: 200px;
  border-right: 1px solid @light-gray;
  padding-right: 25px;
  overflow: auto;

  &::-webkit-scrollbar {
    background: @light-gray  !important;
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: @light-gray-3;
    border-radius: 20px;
    transition: all .2s ease-in-out;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: lighten(@primary-color, 5%);
  }

  @media @tab-xl {
    width: 100%;
    border-right: transparent;
    max-width: none;
    padding-right: 0px;
  }

  &__item {
    padding: .5rem;
    border: none;
    background-color: transparent;
    text-align: start;
    cursor: pointer;
    transition: all .2s ease-in-out;
    border-radius: 10px;

    &--selected {
      background-color: @light-gray;
    }

    &:hover {
      background-color: @light-gray;
    }
  }

  &__collapse-title {
    font-size: 1rem;
    font-weight: bold;
    color: @primary-color;
  }

  &__collapse-body {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-height: 30px;
    transition: all 0.2s ease-out;

    &--collapsed {
      min-height: 0px;
      transition: all 0.2s ease-out;
    }

    &>* {
      width: 100%;
    }
  }

  &__collapse-icon {
    transition: transform .2s ease-out;
    transform: rotate(-180deg);

    &--collapsed {
      transform: rotate(0);
    }
  }
}