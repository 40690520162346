.proposals-details-department-skeleton {
  &__title {
    width: ~"min(200px, 100%)";
    border-radius: 4px;
  }

  &__progress-bar-text {
    width: ~"min(200px, 100%)";
    border-radius: 4px;
    &, .ant-skeleton-input {
      height: 16px;
    }
  }
}
