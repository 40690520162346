.credit-modal-wrapper {
  padding: 0;
  margin: 0;

  width: 100%;

  @media (max-width: 768px) {
    width: 100% !important;
  }
}

.credit-approve-modal-title {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  text-align: center;

  &:after {
    content: '';
    margin-top: 10px;
    margin-bottom: 15px;
    display: block;
    margin-left: 50%;
    width: 50%;
    height: 2px;
    left: 50%;

    background-color: @primary-color;
    transform: translate(-50%, -50%);
  }
}

.credit-modal-layout {
  width: 50vw !important;

  .ant-modal-header {
    display: none;
  }

  .ant-modal-content {
    border-radius: 20px;
  }

  .ant-modal-close-x {
    color: @primary-color;
    font-size: 25px;
  }

  @media (max-width: 768px) {
    width: 90vw !important;
  }
}


.credit-modal-title {
  font-family: Montserrat;
  font-size: 28px;
  font-weight: 700;

  line-height: 33.6px;
  color: @primary-color;
}

.credit-modal-description {
  color: @primary-color;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 1em !important;
}

.credit-modal-description-margin {
  margin-bottom: 34px !important;
}

.credit-base-button {
  background: @primary-color !important;
  min-height: 50px !important;
  font-size: 18px !important;
  color: white !important;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;

  word-break: normal;

  &:hover,
  &:visited,
  &:focus {
    border-color: @secondary-color !important;
  }
}

.credit-ghost-button {
  min-height: 50px !important;
  font-size: 18px !important;
  color: @primary-color !important;
  border: 1px solid @primary-color !important;

  word-break: normal;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;

  &:hover,
  &:visited,
  &:focus {
    border-color: @primary-color !important;
  }
}

.credit-modal-progress {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: @primary-color;
  margin: 40px 0 24px 0;
}

.credit-choose-step-render-contionals {
  background-color: transparent !important;
  overflow: auto;
}
