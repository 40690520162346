// width
.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100%;
}
.h-50 {
  height: 50%;
}

.h-100 {
  height: 100%;
}

@media screen and (max-width: 768px) {
  .w-md-100 {
    width: 100%;
  }

  .w-md-75 {
    width: 75%;
  }

  .w-md-50 {
    width: 50%;
  }
}

// flex

.flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-space-between {
  justify-content: space-between !important;
}

.f-row {
  flex-direction: row;
}

.d-block {
  display: block !important;
}

.display-none {
  display: none !important;
}

.display-block {
  display: block !important;
}

.display-inline-block {
  display: inline-block !important;
}

.display-inline {
  display: inline !important;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-start {
  justify-content: flex-start;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-justify-between {
  justify-content: space-between;
}

.flex-justify-around {
  justify-content: space-around;
}

.flex-justify-evenly {
  justify-content: space-evenly;
}

.pointer {
  cursor: pointer;
}

.not-allowed {
  cursor: not-allowed;
}

.flex-align-center {
  align-items: center;
}

.flex-align-start {
  align-items: flex-start;
}

.flex-self-align-end {
  align-self: flex-end;
}

.flex-align-baseline {
  align-items: baseline;
}

.flex-align-end {
  align-items: flex-end;
}

.flex-vertical-center {
  display: flex;
  align-items: center;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

@media screen and (max-width: 1400px) {
  .flex-lg-column {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .flex-md-column {
    flex-direction: column;
  }
}

// POSITION

.position-relative {
  position: relative;
}

// grid

.grid {
  display: grid;
}

.grid-column-5 {
  grid-template-columns: repeat(5, 200px);
}
