@import './components/DisplayErrorsModal/DisplayErrorsModal.less';
@import './components/LoadingNotaryFeesBar/LoadingNotaryFeesBar.less';

.notary-fees {
  &__card {
    background-color: white;
    border-radius: 20px;
    padding: 1rem;
    height: 100%;
    .flex-full-center();
    flex-direction: column
  }

  &__buttons {
    .flex-full-center();
    flex-direction: column;
    @media @tab-lg {
      width: 100%;
    }
  }

  &__buttons-top {
    @media @tab-lg {
      .flex-full-center();
    }
  }

  &__title {
    padding: 1rem;
  }

  &__buttons-bottom {
    margin-bottom: 0.5rem;
    .flex-full-center();

  }

  &__card-title {
    font-weight: bold;
    color: @primary-color;
  }

  &__icon {
    .flex-full-center();
  }

  &__icon-bg {
    .flex-full-center();
    height: 8.5rem;
    width: 8.5rem;
    border-radius: 12px;
    font-size: 5.6rem;
    background-color: fade(@primary-color, 25%);
    opacity: 1;

    @media @tab-lg {
      display: none;
    }

  }

  &__card-mobile {
    @media @tab-lg {
      margin-top: 1rem;
    }
  }

  &__download-icon {
    color: @primary-color;
    font-size: 1.5rem;
  }

  &__upload-list-item {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
  }

  &__upload-list-item-delete {
    padding: 1rem;
    border-radius: 10px;
    padding: 0.5rem;
      &:hover {
        background-color: @light-gray;
        cursor: pointer;
      }
  }

}