.date-input {
  position: relative;

  &__calendar {
    visibility: hidden;
    width: 0 ;
    height: 40px !important;
    padding: 0 !important;
    border: 0 !important;
    position: absolute !important;
    left: 0px;
    top: 0px;
  
    .ant-picker-input{
        display: none;
    }
  }
  
  &__datepicker{
    z-index: 10;
  }
}
