.pre-signup {
  margin: auto;
  text-align: center;

  padding: 1rem;

  @media @tab-md {
    max-width: 100%;
    padding: 0.5rem;
  }

  &__title {
    font-size: 28px;
    margin-bottom: 3rem;
    color: @primary-color;

    @media @tab-md {
      font-size: 20px;
      margin-bottom: 2rem;
    }
  }

  &__text-container {
    margin-bottom: 2.5rem;
  }

  &__text {
    font-size: 16px;
    text-align: left;
    color: @primary-color;

    @media @tab-md {
      font-size: 14px;
    }
  }

  &__link {
    color: @primary-color;
    font-weight: 700;
    text-decoration: underline !important;
  }

  &__button {
    min-height: 50px;
    font-size: 18px;
    min-width: 200px;

    @media @tab-md {
      min-width: 50%;
    }
  }
}
