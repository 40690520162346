.contact-button {
  height: 50px !important;
  background-color:#25d366 !important;
  box-shadow: 2px 2px 3px #999 !important;
  border: 0 !important;
  position: fixed !important;
  right: 20px !important;
  bottom: 10px !important;
  width: 50px !important;
  z-index: 11 !important;

  p {
    animation: textAnimation 0.5s;
    font-size: 18px !important;
    line-height: 37.5px !important;
  }

  &:hover {
    width: 150px !important;
    color: white !important;
  }
}

#up-contact-button {
  bottom: 90px !important;
}

#user-contact-support {
  bottom: 50px !important;
}