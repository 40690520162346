.signature-step-ecpf {
  &__steps {
    .ant-steps-item-content {
      padding: 1rem;
      background-color: #f3f3f3;
      border-radius: 10px;
      margin-bottom: 10px;
    }

    &--loading {
      .ant-steps-item-content {
        background-color: #f3f3f3;
        min-height: 90px !important;
        border-radius: 10px;
        margin-bottom: 10px;
      }
    }
  }

  &__last-error {
    margin-top: 1rem;
  }
}