.footer {
  background: @black;
  color: @white;
  height: 54px;
  text-align: center;
  border-top: 0.3px solid #000;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  
  @media @tab-md {
    font-size: 0.7rem;
  }
  
}
