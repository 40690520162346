.full-screen-error {
  .flex-full-center();

  min-height: 100vh;

  flex-direction: column;
  background-color: var(--secondary-color);

  &__img {
    width: 160px;
    margin-bottom: 20px;
  }

  &__card {
    max-width: 500px;
    border-radius: @border-radius-base;
    background-color: @light-gray;
    padding: 1rem;
  }
}