#root .proposals-details-resume {
  display: flex;

  &__title {
    color: @primary-color;
    font-size: 1.5rem;

    padding: 11px;
    border-bottom: 1px solid @primary-color;

    @media @tab-lg {
      font-size: 1.2rem;
    }
  }

  &__anchor {
    color: @tertiary-color;
    text-decoration: underline;
  }

  &__line {
    padding: 10px;
    border-bottom: 1px solid @primary-color;

    display: flex;
    justify-content: space-between;
  }

  &__line-title {
    color: @primary-color;

    font-size: 1rem;

    @media @tab-lg {
      font-size: .75rem;
    }
  }

  &__line-value {
    color: @primary-color;
    font-weight: bold;

    @media @tab-lg {
      font-size: .75rem;
    }
  }
}