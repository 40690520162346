@import './views/PortabilityCreate/PortabilityCreate';
@import './components/PortabilityData/PortabilityData';
@import './components/PortabilityAcceptModal/PortabilityAcceptModal';
@import './views/PortabilityAccept/PortabilityAccept';

.portability {
  padding: 3rem 10% !important;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @media @tab-xl {
    flex-direction: column;
    padding: 3rem !important;
  }

  @media @phone-sm {
    padding: 2rem !important;
  }

  &__info-container {
    display: flex;
    flex-direction: column;
    width: 45%;
    margin-right: 5%;

    @media @tab-xl {
      flex-direction: column;
      width: 100%;
      margin-right: 0;
      margin-bottom: 30px;
    }

    @media @phone-sm {
      margin-bottom: 10px;
    }
  }

  &__title {
    font-weight: bold;
    color: @primary-color;
    font-size: 1.5rem;
  }

  &__vantages-list {
    padding-left: 16px;
  }

  &__svg-container {
    display: flex;
    align-items: center;
  }

  &__buttom {
    @media @tab-xl {
      width: 100%;
    }
  }
}
