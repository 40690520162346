@hover-primary-color: #B6D13D;

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.antd-base-table {
  width: 100% !important;
  overflow-x: scroll;

  .ant-table-container {
    max-width: 100% !important;
  }

  table:first-of-type {
    font-size: 12px;

    thead {
      position: relative;
    }

    thead th {
      position: sticky;
      top: 0;
      z-index: 1;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      text-transform: uppercase;
      color: white;
      padding: 5px !important;
      background-color: @primary-color;

      .ant-table-column-sorters {
        padding: 0px 0px !important;
        min-height: 28px;
      }

      :hover {
        color: @primary-color;
      }
    }

    tbody tr {
      td {
        padding: 9.5px 5px !important;

        &:first-child {
          text-align: center;
        }
      }

      &:nth-child(even) {
        background: #f6f8fa;
      }

      &:nth-child(odd) {
        background: #FFF;
      }
    }
  }
}

button .ant-pagination-item-link {
  padding-right: 5px;
}

.table-filter {
  button {
    border-radius: 0px 20px 20px 0px !important;
  }

  & .ant-input-group-addon {
    border-radius: 25px 0 0 25px;
  }

  &__select {
    width: 150px !important;
  }

}

.server-table-filters {
  position: relative;

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-col.ant-form-item-label {
    padding-bottom: 0 !important;
  }

  &__overlay {
    @media @server-table-fiters-min {
      position: fixed;
      z-index: 49;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.2);

      &--text-container {
        position: relative;
        height: 100%;
        width: 100%;
      }

      &--text {
        position: absolute;
        color: white !important;
        font-style: italic;
        font-weight: 500 !important;
        top: 10%;
        left: 45%;
        user-select: none;
      }

      &--icon {
        margin-right: 20px;
      }
    }

    @media @server-table-fiters-max {
      z-index: 0 !important;
      display: none;
    }
  }

  &__layout-primary {
    margin-right: 1rem;
    max-width: 500px;
    flex: 1 1 100px;

    @media @tab-md {
      margin-right: 0rem;
      margin-bottom: 10px;
    }

    .ant-input {
      min-width: 100px;
      margin-right: 0.5rem;
    }

  }

  &__grid-expanded {
    @media @server-table-fiters-min {
      transition: padding-bottom 1s linear;
      position: absolute;
      top: 110%;
      padding: 0px 7.5px 7.5px 7.5px;
      z-index: 51;
      border-radius: 5px 0px 5px 5px;
      margin-left: 0px !important;
      width: 100%;
      background-color: white;
      animation: fadein 200ms;
      border: 1px solid @primary-color;
    }

    @media @server-table-fiters-max {
      position: static;
      margin-left: 0px !important;
      width: 100%;
      align-items: flex-end;
      justify-content: space-between;
    }

    @media @laptop {
      top: 110%;
    }

  }

  &__layout-grid-expanded {
    flex: 0 1 33.3% !important;

    @media @server-table-fiters-max {
      flex: 0 1 50% !important;
    }

    @media (max-width: 767px) {
      flex: 1 100% !important;
    }
  }



  &__justify-group-second-col {
    justify-content: flex-end;
    flex-wrap: nowrap;

    @media @server-table-fiters-max {
      justify-content: flex-start;
    }

    @media @tab-xl {
      margin-top: 0.5rem;
    }
  }

  &__dropdown {
    & button {
      border-radius: 20px 20px 20px 20px;
    }

    &:hover {
      transition: 0.2s;
      cursor: pointer;

      .ant-badge-count {
        background-color: lighten(@primary-color, 3%) !important;
      }
    }

    @media @server-table-fiters-max {
      display: none;
    }
  }

  &__button-actived {
    & button {
      transition: border-radius 260ms;

      border-radius: 20px 20px 0px 0px;
      border-bottom: none;
      margin-bottom: -6px;
      height: 45px;
      box-shadow: none;
      background-color: #fff;
    }
  }

  &__button-more {
    z-index: 51;
    position: relative;
    border: 1px solid @primary-color;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    user-select: none;
    outline: none;
    touch-action: manipulation;
    padding: 4px 15px;
    font-size: 14px;
    background-color: #F0F2F5;
    transition: border-radius 260ms;
    height: 40px;

    &:hover,
    &:focus {
      border-color: lighten(@primary-color, 10%);
    }

    &__disabled {
      background-color: #F4F5F4;
      border: 1px solid #D8D9D8;

      &:hover {
        background-color: #F4F5F4;
        border: 1px solid #D8D9D8;
      }
    }
  }

  &__badge-count {
    position: absolute;
    top: -16px;
    right: -20px;

    .ant-badge-count {
      background-color: @primary-color;
      color: #fff;
      box-shadow: 0 0 0 0.5px @primary-color inset;
      border-radius: 100%;
      line-height: 25px;
      height: 25px;
      width: 25px;
      padding: 0px;
      font-weight: bold;
      font-size: 13px;
    }
  }

  &__width-button-sm {
    @media @server-table-fiters-max {
      width: 100%;
    }
  }

  &__mobile-full {
    @media @server-table-fiters-max {
      width: 100%;
    }
  }

  &__more-options-button-alone>button {
    background-color: @primary-color;
    color: #fff;

    &:hover,
    &:focus {
      background-color: lighten(@primary-color, 2%) !important;
      color: #fff !important;
    }
  }

  &__more-options-dropdown {
    position: relative;
    padding-right: 0px !important;
    padding-left: 0px !important;

    & button {
      border-radius: 20px 20px 20px 20px;
    }

    section {
      svg {
        color: #fff !important;
        cursor: pointer;
      }

      &>div {
        color: #fff !important;
        cursor: pointer;
      }

    }

    @media @server-table-fiters-max {
      width: 100%;
    }
  }

  &__button-options-actived {
    & button {
      transition: border-radius 260ms;
      transition: background-color 250ms;

      border-radius: 20px 20px 0px 0px;
      border-bottom: none;
      box-shadow: none;
      border: 1px solid @primary-color;
      background-color: #fff;

      section {
        svg {
          transition: 110ms;
          color: @primary-color !important;
          cursor: pointer;
        }

        &>div {
          transition: 110ms;
          color: @primary-color !important;
          cursor: pointer;
        }

      }

      &:hover,
      &:focus {
        background-color: #fff;
      }
    }
  }

  &__grid-more-buttons {
    position: absolute;
    top: 45px;
    padding: 0px 7.5px 7.5px 7.5px;
    z-index: 55;
    border-radius: 0px 0px 5px 5px;
    border: 1px solid @primary-color;
    border-top: none;
    margin-left: 0px !important;
    width: 250px;
    background-color: white;
    animation: fadein 200ms;

    @media @server-table-fiters-max {
      position: static;
      width: 100%;
      margin: 0px;
    }
  }

  &__grid-more-buttons-new-server-table {
    position: absolute;
    top: 110%;
    left: 0;
    margin: 0;

    button {
      border: none;
    }
  }

  &__button-options-actived-new-server-table {
    position: relative;
  }


  &__divider-options {
    padding: 0px !important;
    margin: 0px;

    @media @server-table-fiters-max {
      z-index: 100;
      margin-top: 7.5px !important;
    }
  }

  &__section-relative {
    position: relative;
  }

  &__icon-plus-less {
    font-size: 16px;
    margin-top: 1px;
    align-self: center;
    padding-left: 2px;
    padding-right: 2px;

    svg {
      color: @primary-color !important;
      cursor: pointer;
    }

    &:hover {
      transition: 0.2s;
      cursor: pointer;

      svg {
        color: lighten(@primary-color, 5%) !important;
      }
    }

    &__disabled {
      svg {
        color: #CDCDCD !important;
        cursor: pointer;
      }

      &:hover {
        transition: 0.2s;
        cursor: pointer;

        svg {
          color: #CDCDCD !important;
        }
      }
    }
  }

  &__text-more-filters {
    font-weight: bold;
    font-size: 15px;
    color: @primary-color !important;
    cursor: pointer;

    &:hover {
      transition: 0.2s;
      cursor: pointer;

      &>div {
        color: lighten(@primary-color, 5%) !important;
      }
    }

    &__disabled {
      color: #CDCDCD !important;

      &:hover {
        transition: 0.2s;
        cursor: pointer;

        & div {
          color: #CDCDCD !important;
        }
      }
    }
  }

  &__more-options-text {
    font-size: 15px;
  }

  &__section-layout {
    display: flex;
    justify-content: center;
  }

  &__more-options-button {
    width: 250px;
    position: relative;
    border: 1px solid transparent;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    user-select: none;
    outline: none;
    touch-action: manipulation;
    padding: 4px 15px;
    font-size: 14px;
    background-color: @primary-color;
    transition: border-radius 260ms;
    height: 40px;

    &:hover,
    &:focus {
      background-color: lighten(@primary-color, 3%);
    }

    &__disabled {
      background-color: #F4F5F4;
      border: 1px solid #D8D9D8;
      cursor: not-allowed;

      &:hover,
      &:focus {
        background-color: #F4F5F4;
        border: 1px solid #D8D9D8;
      }
    }

    @media @server-table-fiters-max {
      width: 100% !important;
      z-index: 0 !important;
    }
  }

  &__icon-button-model {
    font-size: 16px;
    margin-top: 1px;
  }

  &__max-width-button {
    min-width: 170px;

    @media @server-table-fiters-max {
      width: 100%;
    }
  }

  &__text-button {
    width: 100%;

    &:hover,
    &:focus {
      width: 100%;
      background-color: #aed10f1e !important;
      color: @primary-color !important;
    }
  }
}

.server-table {
  &__scroll-style {
    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background: #C8C8C8;
      border-radius: 20px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #9e9e9e;
    }
  }

  &__pagination {
    .ant-pagination-options {
      display: none !important;
    }
  }

  &__tag-totals {
    font-size: 12px;
    height: 32px;
    align-self: center;
    border: 1px solid @primary-color;
    text-transform: uppercase;
    background-color: white;
    border-radius: 3px;
    padding: 5px;
  }

  &__color-black {
    color: black
  }

  &__limits-pagination {
    margin: 0 5px;
  }
}

.switch-responsive-layout {
  margin-bottom: 5px;

  @media @server-table-fiters-max {
    margin-left: 5px;
  }

  @media (max-width: 767px) {
    margin-bottom: 0px;
  }
}

.server-table-switch-filter {
  @media (min-width: 1200px) {
    transform: translateY(30px);
  }
}

.table-name {
  @media (max-width: 1440px) {
    white-space: nowrap;
    font-size: 10px;
  }
}