.simulator-input {
  height: 50px !important;
  font-size: 16px;

  .ant-input-number-input-wrap {
    input {
      text-align: center;
    }

    .ant-input-number-input {
      height: 38px;
      font-size: 16px;
    }
  }
}

.simulator-input::placeholder {
  color: @primary-color;
}

.simulator-input:hover,
.simulator-input:focus {
  border-color: @primary-color !important;
  box-shadow: 0px 0px 0px 2px rgba(15,64,111,0.1);
}

.simulator-input.ant-input-number-focused {
  border-color: @primary-color !important;
  box-shadow: 0px 0px 0px 2px rgba(15,64,111,0.1);
}

.simulator-inputs .simulator-calculation-button,
.simulator-inputs .simulator-specialist-button {
  font-weight: bold;
}

.simulator-calculation-button {
  background: @primary-color !important;
  min-height: 50px !important;
  font-size: 18px !important;
  color: white !important;
}

.simulator-calculation-button:hover,
.simulator-calculation-button:visited,
.simulator-calculation-button:focus {
  border-color: @secondary-color !important;
}

.simulator-radio {
  display: flex;
  justify-content: center;
  text-align: center;

  .ant-radio-wrapper {
    display: flex;
    align-items: center;
  }

  .ant-radio-inner {
    height: 25px !important;
    width: 25px !important;
    border-color: @secondary-color !important;

  }

  .ant-radio-inner:hover,
  .ant-radio-inner:focus {
    border-color: @secondary-color !important;
  }

  .ant-radio-checked .ant-radio-inner::after {
    height: 15px !important;
    width: 15px !important;
    background-color: @secondary-color !important;
    transition: 1ms;
    right: 4px !important;
    bottom: 4px !important;
    top: 4px !important;
    left: 4px !important;
  }

  & :first-child{
    border-radius: 30px 0 0 30px;
    width: 50%;

    @media (max-width: 380px) {
      font-size: 12px;
    }
  }

  & :last-child {
    border-radius: 0 30px 30px 0;
    width: 50%;

    @media (max-width: 380px) {
      font-size: 12px;
    }
  }

  &.ant-radio-group-large .ant-radio-button-wrapper {
    height: 50px !important;
    line-height: 48px !important;

    transition: border-color 0.3s, box-shadow 0.3s !important;
    transition-duration: 0.3s, 0.3s, 0.3s, 0.3s;
    transition-timing-function: ease, ease, ease, ease;
    transition-delay: 0s, 0s, 0s, 0s;
    transition-property: color, background, border-color, box-shadow;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border: none;
  }

  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    background-color: @primary-color !important;
    color: #fff !important;
    font-weight: 700;
  }

  .ant-radio-button-wrapper {
    background-color: #fff !important;
    color: @primary-color !important;
    font-weight: 700;
  }
}

.simulator-specialist-button {
  min-height: 50px !important;
  font-size: 18px !important;
  border-color: @primary-color !important;
  border-width: 2px !important;
}

.simulator-specialist-button:hover {
  border-color: @secondary-color !important;
}

.simulation-tag-input {
  background-color: #FFF4CE;
  color: @primary-color !important;
  border-radius: 10px;
  padding: 0px 10px;
  position: absolute;
  font-size: 0.75rem;
  transform: translateY(-50%);
  top: 50%;
  left: 50%;
  margin-bottom: 0px;

  @media (max-width: 768px) {
    transform: translate(-50%, -50%);
    top: 0px;
    left: 50%;;
    white-space: nowrap;
  }
}
