.omnichannel {
  &__action {
    font-size: 1.5rem;
    margin-left: .15rem;
    margin-right: .15rem;
    padding: .25rem;
    cursor: pointer;
    &--disabled {
      cursor: not-allowed;
      color: var(--disabled-color) !important;
    }
  }
}