
@import '../../Score/Score';

.score-step {
  &__collapse-panel .ant-collapse-header{
    font-size: 1.2rem;
    color: @primary-color;
    font-weight: bold;
  }
}
