div.find-user-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  margin: auto;
  padding: 4rem;
  width: ~"min(35.8rem, 100%)";
  min-height: 35.8rem;

  @media @phone-sm {
    padding: 1rem;
    min-height: unset;
  }
}
