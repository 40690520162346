.warning-text {

  &__alert-title {
    color: @text-color;
    font-size: 1.3rem;
    margin-bottom: .1rem;
  }

  &__alert-icon {
    @media @phone-sm {
      display: none;
    }

    font-size: 35px;
    margin-top: 10px;
  }

  &__alert-description {
    margin-bottom: .5rem;
  }
}
