.user-details-permissions-list {


  &__checkbox-list {
    padding: 5px;

    display: flex;
    flex-direction: column;
  }

  &__permission-description {
    margin-left: 24px;
  }
}