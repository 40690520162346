.chat-header {
  &__error-message {
    color: var(--red-status);
  }

  &__input-skeleton {
    min-width: 9.375rem;
    height: 1.25rem !important;
    border-radius: 0.3125rem !important;
  }
}
