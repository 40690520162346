
.proposal-range-picker-modal {
  &__button {
    background-color: white;
    color: @gray;
    margin-bottom: 8px;
    transition: all .2s ease-in-out;
    min-width: 170px;

    @media @phone-sm {
      min-width: 100%
    }

    &:focus,
    &:active,
    &:visited {
      background-color: white;
    }

    &:hover{
      background-color: @light-gray-4;
      color: @black;
    }
  }

  &__range-form-item {
    & label {
      width: 100%;
    }
  }
}