.search-filter-by-select {
  min-width: 380px;

  @media @phone-xs {
    min-width: 100%;
  }

  &__group {
    display: flex;
    align-items: flex-end;
  }

  &__identifier {
    width: 125px;
    .ant-select .ant-select-selector {
      background-color: @light-gray;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
    }
  }

  &__search {
    flex: 1;
    .ant-input,
    .ant-input-affix-wrapper {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &__large {
    padding: 0 20px;
    .ant-select-selector {
      min-width: 10rem !important;
      height: 40px !important;
    }
    .ant-input-affix-wrapper {
      padding: 8.1px 11px !important;
    }
  }

  &__borderless {
    .ant-input-group-addon {
      border: none;
    }
    .ant-input-affix-wrapper {
      border-color: transparent;
    }
    .ant-input-affix-wrapper-focused, body .ant-input-affix-wrapper-focused {
        border-color: black !important;
        box-shadow: 0 0 0 1px black !important;
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector, body .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-color: var(--primary-color) !important;
      box-shadow: 0 0 0 1px var(--primary-color) !important;
    }
    .ant-select-selector {
      &:hover {
        border-color: black !important;
        box-shadow: 0 0 0 1px black !important;
      }
    }
    .ant-input-group {
      box-shadow: 0 3px 5px 1px rgb(0 0 0 / 50%);
      border-radius: 30px;
    }
  }
}