.proposal-history-button {
  height: unset !important;
  border: none !important;

  font-size: 1.75rem;
  padding: 5px;

  @media @phone-sm {
    font-size: 1.35rem;
    margin-left: auto;
  }
}
