.document-preview-pop {
  .ant-notification-notice-btn {
    float: left;
  }
}

.documents-view-content {
  display: grid !important;
  grid-template-columns: repeat(7, 1fr) !important;
  justify-content: center !important;
  gap: 20px !important;

  &__image {
  width:150px;
  height:150px;
  border-radius: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  object-fit: cover;

    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }
  }

  &__document-card {
    width: 150px !important;
    height: 150px !important;

    border: 1px solid @primary-color;
    border-radius: 15px;
    padding: 15px;
    flex-direction: column;

    .flex-full-center();
  }

  &__modal {
    display: flex;
    justify-content: center;
    z-index: 9999;

    .ant-modal-body {
      height: 90vh;
      padding: 3.5rem 1.5rem 1.5rem;
    }
  }
}

.file-pdf-filled{
  font-size: 35px;
}

.decode-uri{
  text-align: center;
  font-size: 12px;
  cursor: pointer;
}

.decode-uri-name {
  text-align: center;
  cursor: pointer;
}

.animation-image {
  z-index: 999;
  color: #fff;
  position: absolute;
}

.drawer-title{
  width: 400px;
}

.card-width {
  width: 850px;
}

.pulsate-fwd {
	-webkit-animation: pulsate-fwd 2s ease-in-out infinite both;
	        animation: pulsate-fwd 2s ease-in-out infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-5-28 13:52:5
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation pulsate-fwd
 * ----------------------------------------
 */
 @-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
