.forgot-password-onidata {
  &__container {
    display: flex;
    flex-direction: column;
    padding: 2rem 0;

    @media @phone-sm {
      padding: 1rem;
    }
  }

  &__info-text {
    text-align: center;
    margin-bottom: 30px;
    color: @primary-color;
    font-size: 1rem;

    &--password {
      font-size: .8rem;
    }
  }

  &__form {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &__sent-token {
    color: @back;
  }
}