.caption-h1 {
  font-weight: bold;
  font-size: 16px;
  display: flex;
  justify-content: flex-end;
}

.caption {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  flex: 1;

  &__container {
    display: flex;
    justify-content: center;
    gap: .5rem;

    @media @tab-xl {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__text {
    font-size: 14px;

    @media @tab-xl {
      font-size: 7px;
    }
  }
}
