.proposal-history-container {
  .flex-full-center();
  flex-direction: column;
  font-family: 'Montserrat';
  width:100%;
  background-color: white;
  position: relative;
}

.proposal-history-wrapper {
  position: relative;
  overflow: hidden;
  font-family: Montserrat;
}

.revision-timeline {
  label {
    font-size: 16px;
  }
  .tox-tinymce {
    border: 0;
  }
}