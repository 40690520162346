.logs-menu {
  &__header {
    display: flex;
    align-items: center;
    padding: 2rem 0;
    @media @tab-md {
      display: none;
    }
  }
  &__title {
    font-weight: bold;
    font-size: 2rem;
    color: @primary-color;
  }

  &__text {
    max-width: 80%;
  }

  &__description {
    padding-left: 3rem;
  }
}