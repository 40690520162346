.multi-datepicker {
  display: flex;

  &__button {
    padding: 0 11px !important;
    border: @border-width-base @border-style-base #d9d9d9;
    border-right: none;
    font-size: 1.3em;
    border-radius: 20px 0 0 20px;
    width: 40px !important;
    color: #fff;
    .flex-full-center();
    background-color: @primary-color;
    cursor: pointer;
    box-shadow: none;

    &:hover,
    &:focus {
      border-color: var(--primary-color);
    }

    span {
      .flex-full-center();
      width: 22.09px;
      height: 22.09px;
      transition: transform .15s ease-in, transform .25s ease-in !important;
      transform: rotate(0);
    }

    &.ant-dropdown-open {
      span {
        transform: rotate(70deg);
      }
    }
  }

  .ant-picker-input {
    padding-left: 10px;
  }

  .ant-dropdown-menu-item-selected {
    border-radius: 20px;

  }

  .ant-dropdown-menu-item:hover {
    border-radius: 20px;
  }

  .ant-picker {
    border-left: none;
    border-radius: 0 20px 20px 0 !important;
    padding-left: 0;
    width: 200px !important;

    @media @tab-md {
      width: 100% !important;
    }

    box-shadow: none !important;
    opacity: 1 !important;
  }

  &__disabled {
    cursor: not-allowed;
    background-color: @disabled-color!important;
    border: none !important
  }
}