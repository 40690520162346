.review-backoffice-history {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 0.8rem;
  }

  &__action {
    color: var(--primary-color);
    cursor: pointer;
    padding: 0.2rem 0rem;
    border-radius: 1rem;

    @media @phone-sm {
      font-size: 0.8rem;
    }

    &:hover {
      text-decoration: underline;
      font-weight: 500;
    }
  }
}