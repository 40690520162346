.card-pricing-hover {
  &:hover {
    transition: .7s;

    border: 1px solid @primary-color;
    -webkit-box-shadow: 0px 0px 22px 4px rgba(0,0,0,0.13);
    box-shadow: 0px 0px 22px 4px rgba(0,0,0,0.13);
  }
}

.pricing-products-card {
  width: 120px;
  height: 120px;
}