.simulator-card-fields .ant-select.unauth-inputs.ant-select-single.ant-select-show-arrow :hover {
  border-color: @secondary-color !important;
}

.simulator-card-fields .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: @primary-color !important;
  box-shadow: 0px 0px 0px 2px rgba(15,64,111,0.1);
}

.simulator-card-loading {
  background-color: rgba(26,26,26,.36);
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  pointer-events: none;
}

.simulator-card-fields .ant-form-item-control-input-content {
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color: @primary-color !important;
    box-shadow: 0px 0px 0px 2px rgba(15,64,111,0.1);
  }
}

.simulator-card-fields {
  background-color: white;
  padding: 25px;
  width: 468px;
  border-radius: 3px;
  height: fit-content;

  @media (max-width: 1024px) {
    width: 100%;
  }
}