.progress-card {
  &__title {
    font-size: 1.3rem; 
    font-weight: bold;
    color: @primary-color;
  }

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__data {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin-right: 10px;
  }

  &__sub-title {
    font-size: .9rem;
  }

  &__value {
    font-weight: bold;
    font-size: .9rem;
  }

  &__no-parcels-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70%;
    justify-content: space-between;
  }

  &__no-parcels-svg {
    font-size: 40px;
    color: @primary-color;
  }

  &__no-Parcels-text {
    text-align: center;
  }
}