.portability-accept {
  &__contract-container {
    padding: 0 2rem;

    @media @phone-sm {
      padding: 0;
    }
  }

  &__title {
    color: @primary-color;
    font-weight: bold;
    border-bottom: 1px solid @primary-color;
    padding-bottom: 10px;
    font-size: 1.2rem;

    &--terms {
      font-size: 1rem;
    }
  }

  &__pdf {
    height: 60vh;
  }

  &__inputs-container {
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media @phone-sm {
      padding: 0;
    }
  }

  &__download-button-container {
    margin-top: 20px;
    border-top: 1px solid #00441F;
    padding: 1rem 0;
  }

  &__download-button {
    padding-left: 0;
    text-align: start;
    font-weight: bold;
  }

  &__decline-button {
    border-color: @primary-color;
    color: @primary-color;
    transition: all .2s ease-in-out;

    &:hover {
      color: @white;
      background-color: @primary-color;
    }

    @media @tab-xl {
      margin-bottom: 10px;
    }
  }
}
