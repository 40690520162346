.full-screen-loading {
  .flex-full-center();

  height: 100vh;
  flex-direction: column;
  background-color: var(--secondary-color);

  &__img {
    width: 280px;
  }

  &__card {
    background-color: @light-gray;
    width: 500px;
    border-radius: @border-radius-base;
    padding: 1rem;

    @media @phone-sm {
      width: 100%;
    }

    &--flex-centered {
      display: flex;
      align-items: center;
    }
  }

  &__description {
    font-size: 20px;
  }

  &__sub-title {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 15px;
    color: var(--primary-color);
  }

  &__logo-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 30px;
  }
}
