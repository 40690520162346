
.proposal-list-search {

  &__filters {
    justify-content: center !important;
  }

  &__container {
    text-align: center;
    margin: 40px 0;

    @media @tab-lg {
      margin: 20px 0;
    }
  }

  &__title {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 3px;
    text-decoration: uppercase;
  }

  &__description {
    font-size: 14px;
    margin-bottom: 25px;
  }

  &__input {
    border-left: none;
    border-radius: 0 20px 20px 0 !important;
    padding-left: 0;
    width: 500px !important;

    @media @tab-lg {
      width: 100% !important;
    }

    box-shadow: none !important;
    opacity: 1 !important;

    .ant-btn.ant-input-search-button {
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }

    .ant-input-wrapper.ant-input-group .ant-input-group-addon:first-child {
      border-radius: 25px !important;
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }

    .ant-checkbox + span {
      padding-right: 0;
    }
  }

  &__svg-wrapper {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50vh;

    @media @phone-sm {
      height: 50vh;
    }
  }

  &__search-svg-container {
    height: 200px;
    margin-bottom: 20px;

    @media @phone-sm {
      height: 200px;
    }
  }

}


