.loading-spinner {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    &__icon {
      font-size: 2em;
    }
  
    &--rich-text {
      height: 350px;
    }
  }
  