.select-level-modal {
  .ant-modal-body {
    padding: 30px;
    max-height: 80vh;
    overflow: auto;
  }

  &__icon-container {
    margin-bottom: 30px;
    text-align: center;

    svg {
      width: 160px;
    }
  }

  &__text-container {
    margin-bottom: 30px;
    text-align: center;
  }

  &__title {
    font-size: 24px;
    margin-bottom: 5px;
    font-weight: bold;

    @media @phone-sm {
      font-size: 18px;
    }
  }

  &__subtitle {
    font-size: 16px;
    color: @gray;

    @media @phone-sm {
      font-size: 12px;
    }
  }
}

.select-level {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__button {
    display: flex;
    gap: 15px;
    text-align: left;
    border-radius: @border-radius-base;
    background-color: @white;
    outline: none;
    border: 1px solid @light-gray-2;
    padding: 10px;
    cursor: pointer;
    transition: all .2s;

    &:hover {
      background-color: @light-gray;
    }
  }

  &__level {
    font-weight: bold;
    color: var(--primary-color);
  }

  &__icon-container {
    display: flex;
    svg {
      width: 42px;
      height: 42px;
      color: var(--primary-color);
    }
  }

  &__icon {
    align-self: center;
    flex: 1;
    text-align: right;
  }
}