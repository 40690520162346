.product-item-svg {
  width: 40px;
  height: 40px;
}

.product-item {
  &__item-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__item-name {
    font-weight: bold;
  }

  &__item-image {
    flex: 0 0 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: auto;

    &--backgroud {
      background-color: gray;
    }
  }

  &__item {
    text-align: start;
    flex: 1;
    max-width: 100%
  }

  &__item-subtitle {
    font-weight: bold;
    margin-bottom: 10px;

    &:last-of-type {
      margin-top: 10px;
    }
  }

  &__item-description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 70%;
  }
}