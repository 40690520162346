.generic-origin-radio-ecpf {

  &__input-search {
    padding: 15px;

    .ant-input-search-button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__pagination {
    padding: 15px;
  }

}