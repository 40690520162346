
.tab-contract .ant-tabs-tab {
  border-color: @secondary-color !important;
  color: @secondary-color !important;
  border-radius: 15px 15px 0px 0px !important;
  border-bottom-color: @white;
}

.tab-contract .ant-tabs-tab.ant-tabs-tab-active    {
  .ant-tabs-tab-btn{
      font-weight: 700;
  }
  border-color: @primary-color !important;
  border-bottom-color:#FFF;
}

.card-container{
    padding: 5px;
    box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.2);
}

.tab-contract .ant-tabs-nav{
    margin: 0px !important;
}

.form-contract {

  &__title {
    font-size: 1.1rem;
    text-transform: uppercase;
    color: @secondary-color !important;
    position: relative;
    display: inline-block;

    &::after {
      content: '';
      width: 45px;
      height: 3px;
      border-radius: 2px;
      position: absolute;
      bottom: -5px;
      left: 0;
      background-color: @primary-color;
    }
  }
}

.final-contract {
  &__container-signature{
    margin-top: 35px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .watermark-container {
    transform: translate(-10%, 0) skewY(-30deg) !important;
  }
}


.contract-container{
  margin-bottom: 15px;
  flex-direction: column;
  width:100%;
  .flex-full-center();

  .signature-input{
      border:1px black solid;
  }

  &__input {
    margin-top: 35px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.contract-editable {
  border: 1px solid black;
  margin-bottom: 10px;
  border-radius: 8px;
  outline: none;

  &--bold,
  &--italic {
    width: 28px;
    height: 28px;
    font-family: cursive;
    border: none;
    font-size: 18px;
    background-color: transparent;
    border-radius: 4px;
    transition: 300ms;
    margin-bottom: 2px;
    cursor: pointer;

    &:hover {
      background-color: #A3D8ED;
    }
  }

  &--active {
    background-color: #A3D8ED;
  }

  &--bold {
    margin-right: 5px;
  }
}
