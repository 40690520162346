.error-details {
  margin-bottom: 1rem;

  &__container {
    .flex-full-center()
  }

  &__toggle {
    text-align: center;
    width: 50%;

    @media @phone-sm {
      width: 70%;
    }
  }

  &__toggle-content {
    position: relative;
  }

  &__checkbox {
    display: none;
    user-select: none;
  }

  &__label {
    font-weight: 600;
    color: var(--primary-color);
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
    text-decoration: underline;
    margin: auto;
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
  }

  &__checkbox:not(.error-details__checkbox:checked)~&__wrapper {
    display: none;
  }

  &__checkbox:checked~&__label-on {
    display: none;
  }

  &__checkbox:not(.error-details__checkbox:checked)~&__label-off {
    display: none;
  }

  .error-details__copy {
    cursor: pointer;
    position: absolute;
    right: calc(70px + .5em);

    @media @phone-sm {
      right: 0;
    }

    top: .5em;

    img {
      width: 1em;
    }
  }

  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }


}