.proposal-list-search-ecp {

  &__container {
    display: flex;
    min-width: 500px;
    margin-bottom: 30px;
  }

  &__select {
    width: 150px;

    .ant-select-selector {
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }
  }

  &__input {
    border-left: none;
    border-radius: 0 !important;
    padding-left: 0;

    @media @tab-lg {
      width: 100% !important;
    }

    box-shadow: none !important;
    opacity: 1 !important;

    .ant-btn.ant-input-search-button {
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }

    .ant-input-wrapper.ant-input-group .ant-input-group-addon:first-child {
      border-radius: 0 !important;
    }

    .ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child {
      border-radius: 0 !important;
    }

    .ant-checkbox + span {
      padding-right: 0;
    }
  }
}