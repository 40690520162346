.ecp-token-header {
  &__title  {
    font-weight: bold;
    text-align: center;
    font-size: 1.9rem;
    line-height: 1.9rem;
  }
  
  &__icon-container {
    margin: 30px 0;
  }
  
  &__icon-container svg {
    height: 150px;
    width: 100%;
  
    @media @phone-sm {
      height: 100px;
    }
  }
  
  &__info-text {
    text-align: center;
    color: @primary-color;
    font-size: 1rem;
  }
}