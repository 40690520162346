.proposal-step-button-with-history {
  width: 100%;

  &__content {
    display: flex;
    gap: 1.5rem;
    align-items: center;

    @media @phone-sm {
      flex-wrap: wrap;
    }
  }

  &__children {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    @media @phone-sm {
      flex-wrap: wrap;
    }
  }

  &__container {
    border: 1px solid #d9d9d9;
    background-color: white;
    z-index: 200;
    position: relative;
    border-radius: 20px;
    top: 10px;

    @media @phone-sm {
      border: none;
      top: 5px;
    }
  }

  &__button {
    max-height: 0px;
    max-width: 0px;

    display: flex;
    align-items: center;
    justify-content: center;

    @media @phone-sm {
      max-height: unset;
      max-width: unset;
      margin-left: auto;
    }
  }

  &__open-button {
    font-size: small;
    margin-left: 2.4rem;
  }
}