.proposal-contract-pdf {
  padding: 0 2rem;

  @media @phone-sm {
    padding: 0;
  }

  &__title {
    color: @primary-color;
    font-weight: bold;
    border-bottom: 1px solid @primary-color;
    padding-bottom: 10px;
    font-size: 1.2rem;
  }

  &__pdf {
    height: 47vh;

    &--only-view {
      height: 68vh;

      @media @phone-sm {
        height: 40vh;
      }
    }
  }

  &__download-button-container {
    margin-top: 20px;
    border-top: 1px solid #00441F;
    padding: 1rem 0;
  }

  &__download-button {
    padding-left: 0;
    text-align: start;
    font-weight: bold;
  }

  &__localization {
    margin: 1rem;
    background-color: @light-gray;
    border-radius: 30px;
    padding: 1rem;
  }
  &__localization-item {
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
  }
  &__localization-title {
    color: @primary-color;
    font-weight: bold;
  }
}
