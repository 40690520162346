.storage-files {
  &__comment-placement {
    display: flex !important;
    flex-direction: row;
    width: 100% !important;
    overflow-wrap: break-word;
  }

  &__comment {
    width: 100% !important;
    margin-top: 0.5rem;
    margin-left: 5px;
    overflow-wrap: break-word;
  }
}