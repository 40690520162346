.rh-transfers-submited {
  &__value-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-bottom: 1px solid @light-gray;
    padding-bottom: 2em;
  }

  &__icon svg{
    width: 100px;
    height: 100px;
    color: @primary-color;
  }

  &__value {
    color: @primary-color;
    font-size: 1.2rem;
    font-weight: bold;
  }

  &__sub-title {
    text-align: start;
    font-size: 1rem;
    font-weight: bold;

  }
}