.rh-details {
  &__button-wrapper {
    display: flex;
    justify-content: space-between;

    @media @phone-sm {
      flex-direction: column;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 1.2rem;
  }

  &__button {
    min-width: 200px;
  }

  &__section {
    margin-bottom: 2rem;

    .ant-typography {
      font-size: 1.5rem !important;

      @media @phone-sm {
        font-size: 1.25rem !important;
      }
    }
  }

  &__permissions-value {
    color: @primary-color;
  }
}