@import './Skeleton/DepartmentSectionSkeleton.less';

#root .department-section {
  display: flex;

  &__content {
    flex: 1;
  }

  &__image-wrapper {
    flex: 0 0 200px;
    text-align: center;

    @media @tab-xl {
      display: none;
    }
  }
}