.dynamic-color-button {
  &, &:focus,  &:focus-visible, &:hover  {
    background: @refused-status-color;
    border-color: @refused-status-color;
    color: white;
  }

  &:focus-visible, &:hover {
    filter: opacity(.8)
  }
}

.button-red {
  &, &:focus,  &:focus-visible, &:hover  {
    background: @refused-status-color;
    border-color: @refused-status-color;
  }

  &:focus-visible {
    filter: brightness(75%);
  }
}

.button-green {
  &, &:focus,  &:focus-visible, &:hover  {
    background: var(--status-client-ativo);
    border-color: var(--status-client-ativo);
  }
}