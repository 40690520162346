@import './components/ChangeEmailModal/ChangeEmail.less';
@import './components/ChangePhoneModal/ChangePhone.less';

.profile {
  position: relative;

  &__selfie {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    align-items: center;
  }

  &__info {
    border-left: 1px solid #ddd;
    padding: 1rem 0 1rem 1.5rem;
    display: flex;
    flex-direction: column;
  }

  &__section-title {
    color: @primary-color;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
  }

  &__buttons {
    display: flex;
    justify-content: center;
  }

  &__change-email-button {
    margin: 0 0.5rem;
  }

  &__change-telephone-button {
    margin: 0.5rem;
  }

  &__avatar>.ant-upload {
    .flex-full-center();
    width: 170px;
    height: 170px;
    border: 1px solid #ddd;
    border-radius: 50%;
    align-items: center;
  }

  &__avatar>.ant-upload span {
    .flex-full-center();
    cursor: pointer;
  }

  &__avatar img {
    cursor: pointer;
    border-radius: 50%;
  }
}

@media @tab-lg {
  .profile {
    &__info {
      border: none;
      padding: 0;
    }
  }
}

.client-form {
  &__fields {
    margin-top: 10px;
    padding: 1.5rem;
    padding-bottom: 0;
  }

  &__bottom-divider {
    margin: .5rem 0;

  }

  &__footer {
    padding: 0.5rem 0;
    display: flex;
    justify-content: space-between;
    gap: 1em;
    flex-wrap: wrap;
  }
}
