@import './CndReview/CndReview';
@import './ReviewBackofficeForm/ReviewBackofficeForm';
@import '../ReviewBackofficeHistory/ReviewBackofficeHistory';
@import '../ReviewStepHistory/ReviewStepHistory';

@-webkit-keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

.can-review-wrapper {
  border: 2px solid @analysis-status-color;
  border-radius: 5px;
}

.review-backoffice-card-title {
  color: @primary-color !important;
  font-weight: 700 !important;
  font-family: Montserrat !important;
  font-size: 1.5rem !important;
  line-height: 20px !important;
}

.review-label {
  color: #65737a;
  font-size: 14px !important;
  font-weight: 500;
}

.review-backoffice-card {

  &__histories-container {
    margin-bottom: 2rem;
  }
}

.review-backoffice-history-card {
  border: 1px solid @secondary-color;
  border-radius: 5px;
}

.slide-in-fwd-center {
	-webkit-animation: slide-in-fwd-center 1000ms steps(1000, end) infinite alternate both;
  animation: slide-in-fwd-center 1000ms steps(1000, end) infinite alternate both;
}

.review-backoffice-expired-proposal {
  background-color: @light-gray;
  padding: 1rem;
  border-radius: 20px;
  &__title {
    font-size: 1.3rem;
  }
}

.egi-step{
  &__section-title {
    color: @primary-color;
    font-weight: bold;

    @media @phone-sm {
      font-size: 1.2rem;
    }
  }
}




.review__rich {
  .tox {
    border-radius: 20px!important;
    border: #d9d9d9d9 1px solid;
    color: @text-color;
  }
}