.department-select {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 10px;

  &__filters {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    justify-content: space-between;
    gap: 10px;

    @media @tab-md {
      display: flex;
      flex-direction: column;
    }
  }

  &__icon-container {
    background: lighten(@primary-color, 75%);
    border-radius: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 5px;
    width: min-content;
  }

  &__filters-buttons {
    display: flex;
    gap: 10px;
  }

  &__button {
    border-color: @primary-color;

    @media @tab-md {
      width: 100%;
    }
  }

  &__menu-button {
    border-radius: 0 !important;
    border-bottom: 0;
    width: 100%;
  }

  &__menu-item {
    margin: 0 !important;
    padding: 0 !important;

    &:first-child {
      border-radius: 12px 12px 0 0 !important;
    }

    &:last-child {
      border-radius: 0 0 12px 12px !important;
    }
  }

  &__menu {
    border-radius: 12px;
  }

  &__status-select {
    width: 200px;

    .ant-select-selector {
      background: @primary-color !important;
      border: 0 !important;
      color: @white !important;
      text-align: center !important;

      .ant-select-selection-placeholder {
        color: @white !important;
      }
    }

    .ant-select-arrow {
      color: @white !important;
    }

    @media @tab-md {
      width: 100% !important;
    }
  }

  &__status-color {
    border-radius: 100%;
    display: block;
    height: 8px;
    width: 8px;
  }

  &__status-label {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 5px;
  }
}
