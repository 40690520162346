@import './views/RenegociationCreate/RenegotiationCreate.less';
@import './components/DebitBalanceModal/DebitBalanceModal.less';
@import './components/RenegotiationSimulation/RenegotiationSimulation';

.renegotiation {
  padding: 3em 10% !important;
  margin-bottom: 25px;

  &__title {
    font-weight: bold;
    color: @primary-color;
    font-size: 1.5rem;
    margin: 0;
  }
}