.invalid-fields {
  font-size: 0.8rem;
  &__item {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
  }

  &__wrapper {
    text-align: start;
    max-height: 300px;
    max-width: 500px;
    overflow: auto;
    margin-top: 1em;
    position: relative;
    background: @light-gray;
    color: @primary-color;
    padding: 1rem;
    border-radius: 20px;
  }

  &__checkbox {
    display: none;
    user-select: none;
  }

  &__checkbox:not(&__checkbox:checked)~.invalid-fields__wrapper {
    display: none;
  }

  &__checkbox:checked~.invalid-fields__label--on {
    display: none;
  }

  &__checkbox:not(&__checkbox:checked)~.invalid-fields__label--off {
    display: none;
  }

  &__label {
    font-weight: 600;
    color: var(--primary-color);
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
    text-decoration: underline;
    margin: auto;
    display: flex;
    justify-content: center;

  }
}