.portability-create {
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  align-items: center;

  .center-content-width {
    margin: auto
  }

  &__sub-title {
    color: var(--primary-color);
    font-weight: bold;
    margin: 0;
    font-size: 1.3rem;
  }

    // ANTD
    .ant-steps-item-wait .ant-steps-item-icon,
    .ant-steps-item-finish .ant-steps-item-icon {
      background: transparent;
      border-color: var(--primary-color);
    }
  
    .ant-steps-item-wait
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title {
      color: var(--primary-color);
    }
  
    .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
      color: var(--primary-color);
    }
}