.find-user-description {
  font-size: 1.15rem;
  line-height: 1.22;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
  gap: 2rem;

  @media @phone-sm {
    gap: 1rem;
    font-size: 1rem;
  }
}
