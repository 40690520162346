html body .proposal-history-item {
  font-size: 0.9rem;

  @media @phone-sm {
    font-size: 0.9rem;
  }

  &__title {
    font-weight: 600;
    margin-bottom: 0;
    line-height: normal;

    @media @phone-sm {
      font-size: 0.8rem;
    }
  }

  &__sub-title {
    margin-bottom: 6px;

    @media @phone-sm {
      font-size: 0.8rem;
    }
  }

  &__description {
    text-align: justify;

    @media @phone-sm {
      font-size: 0.6rem;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-weight: 600;
  }

  &__user {
    min-height: 44px;
    text-align: right;
  }

  &__user-name {
    &--unauth {
      color: @base-gray-color;
    }
  }

  &__datetime {
    color: var(--primary-color);
  }
}
