.plus-button {
  height:55px;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  &__button-container {
    background-color:white;

    &--background-less {
      background-color: transparent;
    }

    .flex-full-center();
  }

  &__button-circle {
    width:25px;
    height:25px;
    background-color:white;
    display:flex;
    padding:2px;
    position: relative;

    &--background-less {
      background-color: transparent;
    }

    &:hover{
      cursor:pointer;
    }
  }

  &__plus-icon {
    font-size:26px;
    color: @plus-button-green;
    top:-12px;
    left:1px;
    position: relative;

    &:hover {
      color: @plus-button-green-hover;
    }
  }
}
