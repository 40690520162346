@import './ServerTableFilters';

button .ant-pagination-item-link {
  padding-right: 5px;
}

.table-filter {
  button {
    border-radius: 0px 20px 20px 0px !important;
  }
}

.ant-table-thead {
  height: @header-table;
}

.server-table {
  .ant-table-thead th {
    background-color: transparent;
  }

  &__updateTime {
    font-size: 14px;
  }

  &-expand-image {
    height: 40px;
    width: 40px !important;
    object-fit: scale-down;
    border-radius: 50%;
  }

  &__color-text-total {
    color: var(--primary-color)
  }

  &__limits-pagination {
    margin: 0 5px;
  }


  &__check-icon {
    color: var(--primary-color);
    margin-left: 5px;
  }

  &__pagination {
    margin-top: 15px;

    & .ant-pagination-options {
      display: none;
    }
  }

}

.switch-responsive-layout {
  @media @tab-lg {
    margin-left: 5px;
    margin-bottom: 5px;
  }
  @media @tab-md {
    margin-bottom: 0px;
  }
}

.server-table-switch-filter {
  transform: translateY(30px);

  @media @tab-xl {
    transform: translateY(0);
  }
}

.image-cell {
  padding-left: 10px !important;
}

.switch-input {
  width: 90px;
  white-space: nowrap
}