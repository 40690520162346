.crivo-status-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.crivo-subtitle {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
