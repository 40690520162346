.letter-avatar {
  border: 2px solid @primary-color;
  border-radius: 50%;
  width: 65px;
  height: 65px;
  .flex-full-center();
  font-size: 2rem;
  font-weight: 600;
  color: @primary-color;

  @media @phone-sm {
    display: none;
  }

  &--filled {
    background-color: @primary-color;
    color: @white;
    font-weight: normal;
  }

  &--medium {
    width: 40px;
    min-width: 40px;
    height: 40px;
    font-size: 1rem;
  }

  &--extra-large {
    width: 160px;
    min-width: 160px;
    height: 160px;
    font-size: 5rem;
  }
}

