.resend-documental {
  margin-bottom: 40px;

  &__link {
    padding: 0;
    height: fit-content!important;

    & * > {
      text-decoration: underline!important;
    }
  }
}
