.steps-container {
  & .ant-steps-item.ant-steps-item-wait {
    padding: 0;

    .ant-steps-item-icon > .ant-steps-icon {
      color: @primary-color !important;
    }

    .ant-steps-item-icon{
      border-color: @primary-color !important;
    }
  }

  & .ant-steps-item-finish.ant-steps-item {
    padding: 0 !important;
  }

  & .ant-steps-item-icon  {
    margin: 0;
  }

  & .ant-steps-item-finish {
    & .ant-steps-item-icon {
      background-color: @primary-color !important;
      border-color: @primary-color !important;
      margin: 0;
      padding: 0;
    }
  }

  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #fff !important
  }
}

.ant-steps-item.ant-steps-item-process.steps-simulator.ant-steps-item-active {
  padding: 0 !important;
}

.steps-simulator {
  & .ant-steps-item-icon {
    background-color: #fff !important;
    border-color: @primary-color !important;
    margin: 0;
    padding: 0;

    & .ant-steps-icon {
      color: @primary-color !important
    }
  }
}