.infobanc-skeleton {
  width: 300px !important;
  height: 60px !important;
}

.infobanc {
  width: 100%;
  .flex-full-center();

  &__panel {
    .ant-collapse-header {
      color: @secondary-color !important;
    }
  }
}
