.iframe-pdf {
  position: relative;
  width: 100%;

  &__iframe {
    border: none;
    width: 100%;
    transition: opacity 0.3s ease-in-out;
    &--loading {
      opacity: 0;
    }
  }

  &__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    font-size: 2rem;
    color: var(--primary-color);
  }
}
