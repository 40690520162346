#root .proposals-details-section {
  display: flex;

  .ant-collapse-header:focus-within {
    outline: 2px solid @analysis-status-color;
    outline-offset: -2px;
  }

  &__content {
    flex: 1;
  }

  &__image-wrapper {
    flex: 0 0 200px;
    text-align: center;

    @media @tab-xl {
      display: none;
    }
  }
}
