.signin-b2c-check-user-ecpf {
  text-align: center;

  &__title {
    font-size: 1.2rem;
    margin-bottom: 3px;
  }

  &__loading-svg {
    text-align: center;
    padding-bottom: 2rem;

    svg {
      max-width: 300px;
      width: 300px !important;
    }
  }

  &__spinner {
    margin-bottom: 1rem;

    span {
      font-size: 2.5rem;
    }
  }
}