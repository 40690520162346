.flex-full-center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

.two-lines-clamp() {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
