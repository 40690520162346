.json-container-diff {
  background: inherit;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 10px;

  &--proposal {

    @media @tab-lg {
      grid-template-columns: none;
      grid-template-rows: repeat(3, 1fr);
    }
  }
}

.json-item {
  background-color: @white;
  border: 1px solid @primary-color;
  border-radius: 12px;
  font-size: 12px;
  margin: 0;
  max-height: 40rem;
  overflow: scroll;
  overflow-x: scroll;
  padding: 10px;
  text-align: left;
  font-weight: bold;

  pre {
    margin: 0;
  }

  &--header {
    font-weight: bold;
    font-family: 'Montserrat';
  }

  &--error {
    color: var(--red-status);
  }

  &--loading {
    align-items: center;
    display: flex;
    height: 20rem;
    justify-content: center;
  }
}

.logs-onidata-filters-select {
  width: 100%;
}