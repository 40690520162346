.options-choose-modal {

  &__title {
    font-weight: 700;
    font-size: 1.25rem;
    margin-bottom: 25px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

}