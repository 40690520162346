.action-container {
  margin-left: 20px;

  @media (max-width: 1024px) {
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.request-cnd-checkbox .ant-checkbox-wrapper {
  font-size: 15px !important;
}

.cnd-request-buttons {
  width: auto;
  @media (min-width: 1440px) {
    width: 25%;
  }
}