.email-config {

  &__config-banner {
    .flex-full-center();
    text-align: center;
  }

  &__banner-title {
    color: @primary-color;
    width: 40%;
  }

  &__card {
    background-color: white;
    border-radius: 20px;
    padding: 1.5rem;
  }

  &__title {
    font-weight: bold;
    color: @primary-color;
  }

  &__logs-table-header {
    color: @primary-color;
    border-bottom: 1px solid @primary-color;
  }

  &__logs-table-header-item {
    padding: 0.5rem 0;

  }

  &__logs-table-header-first-item {
    width: 15%;
  }

  &__logs-table-item {
    width: fit-content;
    white-space: nowrap;
    padding: 0.2rem 0.5rem;
    border-radius: 10px;
  }

  &__logs-table-property {
    margin: 1rem 0.5rem;
    background-color: @light-gray;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

  }

  &__logs-table-new {
    background-color: rgba(0, 207, 123, 0.19);
  }

  &__logs-table-old {
    background-color: rgba(255, 106, 106, 0.25);
  }

  &__form-container {
    display: flex;
    justify-content: center;
  }

  &__form {
    width: 80%;
    @media @phoneScreen {
      width: 100%;
    }
  }

  &__input-label {
    color: @primary-color;
    font-weight: bold;
  }

  &__title-header {
    display: flex;
    justify-content: left;
    padding-bottom: 1rem;
  }

  &__warning-smtp {
    background-color: @light-gray;
    .flex-full-center();
    border-radius: 10px;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &__warning-smtp-icon {
    color: @secondary-button;
    padding: 0 0.5rem;
  }

  &__config-not-finished {
    .flex-full-center()
  }

  &__config-not-finished-icon {
    font-size: 3rem;
    padding: 0 2rem;
    color: @warning-color;
  }

  &__old-config-column {
    padding: 0 2rem;
    @media @tab-lg {
      display: none;
    }
  }


  &__mobile-hide {
    @media @tab-lg {
      display: none;
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
  }
}