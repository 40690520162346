.limit-bar {
  display: flex;
  flex-direction: column;
  gap: .5rem;

  .ant-progress-bg {
    position: relative;
    height: 2.5rem!important;

    &:before {
      content: '';
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      background: var(--primary-color);
      border-radius: 100px;
    }
  }

  .bar-label {
    color: @primary-text;
  }
}