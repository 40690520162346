@media (min-width: 1200px) {
  .progress-card {
    padding: 0 20px;
  }
}

@media (max-width: 1200px) {
  .progress-card {
    padding: 20px 0;
  }
}

.dashboard-areas-card {
  border-radius: 15px;
  background-color: white;
  -webkit-box-shadow: 0px 3px 6px #272D3B33;
  box-shadow: 0px 3px 6px #272D3B33;

  .ant-progress-circle-gradient {
    width: 80px !important;
    height: 80px !important;
    font-size: 15px !important;
  }
}

.mlm-proposals-border-align {
  margin-left: 25px;
  margin-right: 25px;

  @media (max-width: 1280px) {
    margin-left: 14px;
  }
}

@media (max-width: 1199px) {
  .mlm-proposals-border-align {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .mlm-card-padding {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .mlm-margin-proposal {
    margin-top: 15px;
  }

  .dashboard-border-right,
  .dashboard-border-left,
  .dashboard-border-bottom,
  .dashboard-border-top {
    border: 0 !important;
  }
}

.dashboard-border-top {
  border-top: 1px solid;
  border-color: rgba(192, 192, 192, 0.4);
}

.dashboard-border-bottom {
  border-bottom: 1px solid;
  border-color: rgba(192, 192, 192, 0.4);
}

.dashboard-border-left {
  border-left: 1px solid;
  border-color: rgba(192, 192, 192, 0.4);
}

.dashboard-border-right {
  border-right: 1px solid;
  border-color: rgba(0, 0, 0, 0.50);
}

.mlm-product-select {
  padding: 7.5px;

  @media (max-width: 991px) {
    padding: 0 !important;
  }
}

@media (max-width: 1024px) {

  .dashboard-border-right,
  .dashboard-border-left,
  .dashboard-border-bottom,
  .dashboard-border-top {
    border: 0;
  }

  .mlm-proposals-border-align {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .mlm-card-padding {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .mlm-margin-proposal {
    margin-top: 15px;
  }
}

.mlm-fitlers {
  margin-bottom: 15px !important;
}

.dashboard-status-card {
  border-radius: 20px;
  padding: 10px 20px;
  background-color: white !important;
  -webkit-box-shadow: 0px 3px 6px #272D3B33;
  box-shadow: 0px 3px 6px #272D3B33;

  &__proposal-history {
    padding: 1rem;
    .flex-full-center();
  }
}

.dashboard-status-card-square {
  padding: 10px 20px;
  min-height: 145px;
  background-color: white !important;
  -webkit-box-shadow: 0px 3px 6px #272D3B33;
  box-shadow: 0px 3px 6px #272D3B33;


  @media @tab-lg-min {
    .ant-col {
      padding: 1rem !important;
    }

    .ant-col:nth-child(-n+3) {
      border-bottom: 1.2px solid #cbcfdb !important;
    }

    .ant-col:nth-child(1),
    .ant-col:nth-child(2),
    .ant-col:nth-child(4) {
      border-right: 1.2px solid #cbcfdb !important;
    }
  }

  @media @tab-xl-min {
    .ant-col {
      padding: 0 15px !important;
    }

    .ant-col:nth-child(-n+4):not(:last-child) {
      border-right: 1.2px solid #cbcfdb !important;
      border-bottom: none !important;
    }
  }


}

.dashboard-status-card-height {
  font-family: 'Montserrat', sans-serif;
  min-height: 165px;
}

.dashboard-status-card-half {
  min-height: 123.5px;
}

.dashboard-card-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600 !important;
  font-size: 1.2em !important;
  color: @primary-color !important;
}

.dashboard-card-title-areas {
  font-family: 'Montserrat', sans-serif;
  font-weight: normal !important;
  font-size: 1.18em !important;
  color: @primary-color !important;

  @media (min-width: 1024px) {
    word-break: break-word;
  }
}

.rotate-360-vertical-fwd {
  -webkit-animation: rotate-90-vertical-fwd 4s linear infinite;
  animation: rotate-90-vertical-fwd 4s linear infinite;
}

@-webkit-keyframes rotate-90-vertical-fwd {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }

  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

@keyframes rotate-90-vertical-fwd {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }

  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

.dashboard-leads-funnel {
  width: 120px;
  height: 120px;
  position: relative;
  transform-style: preserve-3d;
  clip-path: polygon(50% 100%, 0 0, 100% 0);
}

.dashboard-leads-funnel-generate {
  position: absolute;
  border-top: 1px solid white;
  clip-path: polygon(50% 100%, 0 0, 100% 0);
  bottom: 0;
}

.dashboard-card-title-letter-spacing {
  font-family: 'Montserrat', sans-serif;
  color: @primary-color !important;
  text-transform: uppercase;
  font-size: 1.1em !important;
  font-weight: bold !important;
  letter-spacing: 3px;
}

.dashboard-card-number {
  margin: 0 !important;
  font-size: 1.8em !important;
  font-family: 'Montserrat', sans-serif;
}

.dashboard-icon-size {
  font-size: 3.5em;
}

.dashboard-icon-size-mini {
  font-size: 2.5em;
}

.dashboard-status-card-main-text {
  margin-bottom: 3px;
  text-transform: uppercase;
}

.dashboard-status-card-title {
  font-size: 1.4rem;
  margin-bottom: 3px;
  text-transform: uppercase;

  @media (max-width: 1281px) {
    font-size: 1.2rem;
  }
}