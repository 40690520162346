.department-card {
  &__progress-bar-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media @tab-xl {
      display: block;
    }

  }

  &__documents-download {
    max-height: 35px;
  }

  &__zip-buttons {
    display: flex;

    @media @tab-xl {
      margin: 1rem 0;
      padding-left: 0;
    }
  }

  &__zip-button-left {
    height: 35px !important;
    background-color: 'red';
    border-radius: 20px 0 0 20px !important;
    border-right: 0;
  }

  &__zip-button-right {
    height: 35px !important;
    border-radius: 0 20px 20px 0 !important;
  }
}

.department-card-status-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  font-size: 18px;
  color: @primary-color;
}

.department-card-title {
  color: @primary-color !important;
  text-transform: uppercase !important;
  font-weight: 700 !important;
  font-family: Montserrat !important;
  font-size: 1.4rem !important;
  line-height: 31px !important;
  display: flex;
  align-items: center !important;
}

.department-steps-collapse {
  & .ant-collapse-header {
    color: @primary-color !important;
    font-weight: 500 !important;

    & span {
      color: @tertiary-color !important;
    }
  }
}

.icon-container {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.divider-style {
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 20px;
  border-width: 2px;
  background-color: rgba(97, 97, 97, 0.7);
}

.department-card-title-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 1024px) {
    display: block;
    min-width: 0px;
  }

  @media (max-width: 768px) {
    display: contents;
    min-width: 0px;
  }
}

.department-card-sla-container {
  justify-self: flex-end;
  width: max-content;
}

.step-reservation {
  position: absolute;
  top: -8px;
  right: -4px;
  color: @remaking-status-color;
}

.sla-info {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;

  @media (max-width: 1320px) {
    font-size: 10px;
  }

  @media (max-width: 1024px) {
    justify-content: flex-start;
  }

  @media (max-width: 768px) {
    justify-content: flex-start;
  }
}

.department-card-step-font {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
}

.progress-bar {
  width: 395px;
  margin-right: 70px;

  display: flex;
  align-items: center;
}

@media screen and (max-width: 520px) {
  .progress-bar {
    display: flex;
    align-items: center;
    flex-direction: column !important;
  }

  .department-card-status-text {
    font-size: 0.9rem !important;
  }

  .department-card-step-font {
    font-size: 13px !important;
  }

  .department-card-title {
    font-size: 1.2rem !important;
  }
}

.statistic-simulation-card-secondary {
  padding: 10px 15px;

  .ant-statistic-content-value {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 22px;
    line-height: 26px;

    color: @secondary-color;
  }

  @media (max-width: 1385px) {
    padding: 10px 7px;

    .ant-statistic-title {
      font-size: 12px;
    }

    .ant-statistic-content-value {
      font-size: 18px;
    }
  }

  @media (max-width: 1125px) {
    .ant-statistic-title {
      font-size: 12px;
      white-space: nowrap !important;
    }

    .ant-statistic-content-value {
      font-size: 14px;
    }
  }
}

.statistic-simulation-card-primary {
  padding: 10px 15px;

  .ant-statistic-content-value {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;

    color: @primary-color;
  }

  .ant-statistic-title {
    font-weight: 500;
    color: @primary-color;
  }

  @media (max-width: 1385px) {
    padding: 10px 7px;

    .ant-statistic-title {
      font-size: 12px;
    }

    .ant-statistic-content-value {
      font-size: 18px;
    }
  }

  @media (max-width: 1125px) {
    .ant-statistic-title {
      font-size: 12px;
      white-space: nowrap !important;
    }

    .ant-statistic-content-value {
      font-size: 14px;
    }
  }
}

.simulator-tooltip-h1 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
  color: black;
}