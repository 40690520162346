.proposal-debit-balance-ecpf {
  padding: 2rem;
  display: flex;
  gap: 2rem;

  &__content {
    width: 82%;
  }

  &__icon {
    font-size: 5rem;
    color: @primary-color;
    width: 18%;
  }

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__title h3 {
    color: @primary-color;
    font-size: 1.5rem;
    font-weight: bold;
  }

  &__totals {
    display: flex;
    align-items: stretch; 
    padding: 1rem;
    gap: 1rem;
    border-radius: 10px;
    border: 1px solid @light-gray-3;
    width: fit-content;

    @media @phone-sm {
      flex-direction: column;
      width: 100%;
    }
  }

  &__totals-container {
    @media @tab-xl {
      flex-direction: column;
      gap: 1rem;
      align-items: start;
    }
  }
  
  &__totals-divider {
    width: 1px;
    background-color: @light-gray-3;

    @media @phone-sm {
      width: 100%;
      height: 1px;
    }
  }

  &__left-total {
    display: flex;
    align-items: center;
    gap: 5px;

    @media @phone-sm {
      flex-direction: column;
      align-items: start;
    }
  }

  &__simulate-button {
    @media @tab-xl {
      width: 100%;
    }
  }
}

@media @tab-xl {
  .proposal-debit-balance-ecpf {
    flex-direction: column;

    &__icon {
      width: 100%;
    }

    &__content {
      width: 100%;
    }

    &__title {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      width: 100%;
    }

    &__title h3 {
      padding: 1rem 0;
      text-align: center;
    }
  }

}