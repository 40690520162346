.has-pendencies-warning {
  background-color: @light-gray;
  padding: 1rem;
  border-radius: 20px;
  align-self: flex-end;
  width: calc(100% - 200px);
  margin: 2rem 0;

  @media @phone-sm {
    margin: 1rem 0;
    padding: 0.8rem;
  }

  @media @tab-xl {
    width: 100%;
  }

  &__title {
    font-weight: bold;
    color: @primary-color;
    margin: 0!important;

    @media @phone-sm {
      font-size: 0.9rem;
    }
  }

  &__button {
    @media @phone-sm {
      margin-top: 5px;
      max-height: 30px;
      font-size: 0.8rem;
    }
  }

  &__description {
    color: @primary-color;
    margin-bottom: 0.5rem;

    @media @phone-sm {
      font-size: 0.7rem;
    }
  }

  &__instructions {
    font-size: 0.8rem;
  }

  &__skeleton-container {
    align-self: flex-end;
    width: calc(100% - 200px);
    margin: 1rem 0 ;
    @media @tab-xl {
      width: 100%;
    }
  }

  &__skeleton {
    height: 5rem!important;
    width: 100%!important;
  }

  &__text {
    width: 100%;

    @media @tab-md {
      flex-direction: column;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media @tab-md {
      flex-direction: column;
    }
  }
}