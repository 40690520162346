.wrapper-action-button {
  display: inline-flex;
  justify-content: center;

  .action-button-label {
    padding-right: 0;
    align-self: center;
  }

  &:hover {
    background-color: @primary-color;
    cursor: pointer;
  }
}

.action-icon-button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: @primary-color;
}
