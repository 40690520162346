.selfie {
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 150px;
    height: 150px;
    cursor: pointer;
  }

  &__upload>.ant-upload {
    .flex-full-center();
    width: 170px;
    height: 170px;
    border: 1px solid #ddd;
    border-radius: 10px;
    align-items: center;

    transition: all 0.3s ease;

    &:hover {
      border-color: @tertiary-color;
      color: var(--primary-color);
    }
  }

  &__upload>.ant-upload span {
    .flex-full-center();
    cursor: pointer;
  }
}