.client-card-list {
  &__header {
    .flex-full-center();
    flex-direction: column;
    margin-top: 30px;
  }

  &__header-tittle {
    color: var(--primary-color);
    display: block;
    font-weight: bold;
    border-bottom: var(--primary-color) 1px solid;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  &__header-search {
    width: 80%;
    @media (max-width: 1200px) {
      width: 100%;
    }
  }

  &__header-paragraph {
    font-size: 1.5em;
    margin-bottom: 0;
    color: var(--primary-color);
    margin-bottom: 30px;

    @media (max-width: 768px) {
      font-size: 1.2em;
      max-width: 100%;
      text-align: center;
    }
  }

  &__clients-list-grid {
    margin-top: 40px;
    width: 80%;

    display: grid;
    gap: 25px;
    grid-template-columns: repeat(auto-fill, calc(25% - 20px)) calc(25% - 25px);

    @media (max-width: 1200px) {
      grid-template-columns: repeat(auto-fill, calc(50% - 20px)) calc(50% - 25px);
      width: 100%;
    }

    @media (max-width: 768px) {
      grid-template-columns: 100%;
    }
  }

  &__clients-link {
    &:hover {
      transition: .3s ease-in-out;
      filter: brightness(90%);
    }
  }

  &__clients-card {
    transition: 200ms;
    width: 100%;
    height: 19rem;
    border-radius: 33px;
    box-shadow: 3px 3px 10px 2px rgb(146, 146, 146);

    &:hover {
      transition: .2s ease-in-out;
      transform: scale(1.02);
    }

    @media (max-width: 768px) {
      height: 19rem;
    }
  }

  &__clients-name {
    color: var(--primary-color);
    font-weight: 800;

    &--skeleton {
      background-color: rgb(226, 226, 226);
      height: 25px;
      width: 100%;

      border-radius: 12px;
    }
  }

  &__clients-selfie {
    max-width: 100%;
    object-fit: cover;
    height: 200px;

    display: flex;
    justify-content: center;
    align-items: center;
    
    background-color: @light-gray;
    border-radius: 33px 33px 0px 0px !important;

    &--skeleton {
      background-color: rgb(218, 218, 218);
      width: 100%;
      height: 200px;
      border-radius: 33px 33px 0px 0px !important;
    }
  }

  &__clients-cpf--skeleton {
    background-color: rgb(218, 218, 218);
    height: 25px;
    width: 70%;
    border-radius: 12px;
  }

  &__pagination {
    width: 80%;
    display: flex;
    justify-content: center;

    @media (max-width: 1200px) {
      width: 100%;
    }
  }
}
