.proposal-validity-modal {
  &__header {
    display: flex;
    align-items: center;
    padding-bottom: 0.5rem;
  }

  &__title {
    font-weight: 600;
  }

  &__content {
    margin: 0.5rem 0 1rem 0;
  }
}