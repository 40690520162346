.avaliation-card {
  &__form {
    display: flex;
    align-items: flex-end;
    gap: 0.5rem;
  }

  &__item {
    position: relative;
  }

  &__checkbox {
    z-index: 2;
  }
}