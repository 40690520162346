@import './views/ProposalDetails/ProposalDetails';
@import './views/ProposalCreate/ProposalCreate';
@import './views/ProposalClientList/ProposalClientList';
@import './views/ProposalUnauthContract/ProposalUnauthContract';
@import './views/ProposalSummary/ProposalSummary';
@import './views/ProposalListSearch/ProposalListSearch';

@import './components/ProposalFilters/ProposalFilters';
@import './components/ContractFormContent/ContractFormContent';
@import './components/BarLabel/BarLabel';
@import './components/LimitBar/LimitBar';
@import './components/LimitDetails/LimitDetails';
@import './components/RangeLabel/RangeLabel';
@import './components/SliderInput/SliderInput';
@import './components/ProposalDepartmentSkeleton/ProposalDepartmentSkeleton';
@import './components/ProposalDepartment/ProposalDepartment';
@import './components/ContractSignatureHelper/ContractSignatureHelper';
@import './components/ProposalHistoryButton/ProposalHistoryButton';
@import './components/ProposalHistoryItem/ProposalHistoryItem';
@import './components/ProposalLineWidget/ProposalLineWidget';
@import './components/ProposalResume/ProposalResume';
@import './components/ProposalDetailsSectionSkeleton/ProposalDetailsSectionSkeleton';
@import './components/ProposalsDetailsSection/ProposalDetailsSection';
@import './components/ProposalContractPDF/ProposalContractPDF';
@import './components/ProposalStepButton/ProposalStepButton';
@import './components/ResendDocumental/ResendDocumental';
@import './components/ClientInstructions/ClientInstructions';
@import './components/HasPendenciesWarning/PendenciesWarning';
@import './components/OnidataErrorStatus/OnidataStatusError';

.proposal-radio-filter {
  .ant-radio-group.ant-radio-group-outline {
    width: 100%;
  }
}

.text-ellipsis {
  text-overflow: ellipsis;
  width: 100%;
  overflow-x: hidden;
  display: inline-block;
  word-break: break-all;
  white-space: nowrap;
}
