.simple-pagination {
  display: flex;
  gap: 8px;

  &__button {
    max-height: 32px;
    height: 32px;
    width: 32px;
    background-color: @white;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &:active,
    &:focus {
      background-color: @white !important;
    }
  }

  &__page {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @white;
    border-radius: 50%;

    height: 32px;
    width: 32px;
  }
}