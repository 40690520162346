.documental-step {

  &__logs-container {
    margin-bottom: 1rem;
  }

  &__container {
    margin-bottom: 2.5rem;
  }
}
