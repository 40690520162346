.documental-process {
  &__validate {
    display: flex;
    justify-content: space-between;
  }

  &__process {
    padding: 1rem;
    color: @primary-color;
    display: grid;
    grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr);
    grid-template-rows: auto;
    gap: 10px;
    padding: 0.5rem 0;
  }

  &__process-header {
    font-weight: bold;
  }

  &__process-container {
    max-width: 100%;
    border-radius: 10px !important;
    overflow-x: auto;
    background-color: @light-gray;
    padding: 1rem 0;
    margin: 40px 0;
  }

  &__title-container {
    display: flex;
    justify-content: space-between;

    @media @phone-sm {
      display: block;
      text-align: center;
      margin-bottom: 1rem;
    }
  }

  &__description {
    margin: 0 !important;
  }

  &__email-age {
    padding: 0.5rem;
    border-radius: 10px;
  }

  &__email-age--green {
    background-color: @soft-approved;
  }

  &__email-age--red {
    background-color: @soft-reproved;
  }

  &__email-age--yellow {
    background-color: @soft-neutral;
  }

  &__reports-button {
    @media @phone-sm {
      margin-top: 2rem;
    }
  }
}