.progress-queue-card {
  -webkit-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.13);
  -moz-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.13);
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.13);
  animation: showProgressQueue .3s cubic-bezier(.43,-0.01,.32,1.01);
  overflow-y: hidden;
  width: 500px;
  max-height: 700px;
  min-height: 100px;

  @media @phone-sm {
    max-width: 80vw;
  }

  &__header {
    background-color: @primary-color;
    color: @white !important;
  }

  &__header-button {
    background-color: transparent;
    border: none;
    color: @white;
    cursor: pointer;
  }

  & .ant-collapse-content-box {
    padding: 0px 40px 20px !important;
  }

  & .ant-collapse-borderless {
    background-color: @white;
  }

  &__show-button {
    transform: rotateX(0deg);
  }

  &__message {
    word-break: break-all;
  }

  &__body {
    max-height: 70vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
      margin-left: 10px;
      margin-right: 10px;
    }
  
    &::-webkit-scrollbar-track {
      background: @white;
      height: 20px;
      border-radius:20px ;
    }
  
    &::-webkit-scrollbar-thumb {
      background: #c8c8c8;
      border-radius: 10px;
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background: #9e9e9e;
    }
  }
}

@keyframes showProgressQueue {
  0% {
    opacity: 0;
    transform: translatex(450px);
  }
  100% {
    opacity: 1;
    transform: translatex(0);
  }
}
