.contract-signature-helper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-self: center;

  @media @phone-sm {
    padding: 1rem;
  }

  &__outter-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap-reverse;
  }

  &__header {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-weight: bold;
    font-size: 1.3rem;
    margin-bottom: 10px;
    color: var(--primary-color);
  }

  &__icon {
    font-size: 72px;
    color: var(--primary-color);
    margin-right: 2.5vw;
  }

  &__button {
    margin-left: auto;
  }
}
