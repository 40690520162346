@page {
  size: auto;
  margin: 5mm;
}

#cet-template,
#print-template {
  display: none;
}

&.cet-container {

  &__w-10 {
    width: 10%;
  }

  &__cet-result {
    width: 15%;
    border: 3px solid black;
    text-align: center;
  }

  &__proponente {
    border-top: 1px solid;
  }

  &__text {
    max-width: 780px;

    & p {
      margin-bottom: 0px;
    }
  }
}

@media print {
  body {
    -webkit-print-color-adjust: exact !important;
  }

  @page {
    size: A4 landscape;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  #cet-template,
  #print-template {
    display: block;
  }
}