.onidata-status-error {
  background-color: @light-gray;
  padding: 1rem;
  border-radius: 20px;
  display: flex;
  align-items: center;
  width: calc(100% - 200px);
  align-self: flex-end;

  @media @tab-xl{
    flex-direction: column;
    margin: 0 1rem!important;
    width: 100%;
    align-self: center;
  }

  @media @phone-sm{
    flex-direction: column;
    width: 90%!important;
    align-self: center;
  }

  &__json {
    margin-bottom: 0!important;
    white-space: normal;
    text-align: center;
  }

  &__icon {
    font-size: 2rem;
    color: @refused-status-color;
    @media @tab-xl {
      margin: 1rem 0;
    }

  }

  &__skeleton {
    height: 4rem!important;
    border-radius: 20px;
    width: calc(100% - 200px);
    align-self: flex-end;

    @media @tab-xl{
      width: 100%;
    }

    @media @phone-sm{
      flex-direction: column;
      width: 90%!important;
      align-self: center;
    }
  }
}