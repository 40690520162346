.introduction-main-text {
  font-size: 3.2rem;
  font-family: sans-serif;
}

.introduction-secondary-text {
  font-size: 2.5rem;
}

.introduction-main-width-white {
  max-width: 750px;
  text-shadow: 2px 2px black;
}

.introduction-main-card {
  padding: 3rem 5rem;
  display: flex;
  position: relative;

  @media screen and (max-width: @screen-sm-min) {
    padding: 2rem;
    display: block;
  }

  @media screen and (min-width: @screen-md-min) {
    &::after {
      content: "";
      width: 70%;
      height: 100%;

      position: absolute;
      border-left: 2px solid #C2BEBE;
      border-bottom: 2px solid #C2BEBE;

      background-image: linear-gradient(to right bottom, @gradient-color);

      border-bottom-left-radius: 115%;
      top: 0;
      right: 0;
      z-index: 0;

      .scale-up-hor-right
    }
  }
}

.introduction-first-half {
  flex: 1;
  max-width: 400px;
  z-index: 10;

  @media screen and (max-width: @screen-sm-min) {
    padding: 10px;
    max-width: auto;
  }
}

.introduction-second-half {
  flex: 2;
  z-index: 10;
}

.introduction-second-half-cards {
  flex: 2;
  z-index: 10;
  justify-content: space-evenly;

  @media screen and (max-width: @screen-sm-min) {
    display: block;
  }.introduction-main-text {
  font-size: 3.2rem;
  font-family: sans-serif;
}

.introduction-secondary-text {
  font-size: 2.5rem;
}

.introduction-main-width-white {
  max-width: 750px;
  text-shadow: 2px 2px black;
}

.introduction-main-card {
  padding: 3rem 5rem;
  display: flex;
  position: relative;

  @media screen and (max-width: @screen-sm-min) {
    padding: 2rem;
    display: block;
  }

  @media screen and (min-width: @screen-md-min) {
    &::after {
      content: "";
      width: 70%;
      height: 100%;

      position: absolute;
      border-left: 2px solid #C2BEBE;
      border-bottom: 2px solid #C2BEBE;

      background-image: linear-gradient(to right bottom, @gradient-color);

      border-bottom-left-radius: 115%;
      top: 0;
      right: 0;
      z-index: 0;

      .scale-up-hor-right
    }
  }
}

.introduction-first-half {
  flex: 1;
  max-width: 400px;
  z-index: 10;

  @media screen and (max-width: @screen-sm-min) {
    padding: 10px;
    max-width: auto;
  }
}

.introduction-second-half {
  flex: 2;
  z-index: 10;
}

.introduction-second-half-cards {
  flex: 2;
  z-index: 10;
  justify-content: space-evenly;

  @media screen and (max-width: @screen-sm-min) {
    display: block;
  }
}

.introduction-card {
  box-sizing: border-box;
  min-height: 150px;
  padding: 30px 45px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    border: 1px solid @primary-color;
    box-shadow: 0 8px 16px 0 rgba(170,187,187,0.5);
    transition: .25s;
  }

  @media screen and (max-width: @screen-sm-min) {
    display: block;
    max-width: 100%;
    margin-bottom: 30px;
  }
}

.introduction-card-margin {
  margin-left: 5rem;
}

.introduction-image {
  padding-left: 3rem;
  min-width: 400px;
  min-height: 450px;
}

.introduction-icon {
  height: 150px;
}

@media screen and (max-width: 1366px) {
  .introduction-image {
    padding-left: 0;
  }
}

@media screen and (max-width: 1024px) {
  .introduction-secondary-text {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .introduction-second-half {
    display: none;
  }
}

.introduction-main-text {
  font-size: 3.2rem;
  font-family: sans-serif;
}

.introduction-secondary-text {
  font-size: 2.5rem;
}

.introduction-main-width-white {
  max-width: 750px;
  text-shadow: 2px 2px black;
}

.introduction-main-card {
  padding: 3rem 5rem;
  display: flex;
  position: relative;

  @media screen and (max-width: @screen-sm-min) {
    padding: 2rem;
    display: block;
  }

  @media screen and (min-width: @screen-md-min) {
    &::after {
      content: "";
      width: 70%;
      height: 100%;

      position: absolute;
      border-left: 2px solid #C2BEBE;
      border-bottom: 2px solid #C2BEBE;

      background-image: linear-gradient(to right bottom, @gradient-color);

      border-bottom-left-radius: 115%;
      top: 0;
      right: 0;
      z-index: 0;

      .scale-up-hor-right
    }
  }
}

.introduction-first-half {
  flex: 1;
  max-width: 400px;
  z-index: 10;

  @media screen and (max-width: @screen-sm-min) {
    padding: 10px;
    max-width: auto;
  }
}

.introduction-second-half {
  flex: 2;
  z-index: 10;
}

.introduction-second-half-cards {
  flex: 2;
  z-index: 10;
  justify-content: space-evenly;

  @media screen and (max-width: @screen-sm-min) {
    display: block;
  }
}

.introduction-card {
  box-sizing: border-box;
  min-height: 150px;
  padding: 30px 45px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    border: 1px solid @primary-color;
    box-shadow: 0 8px 16px 0 rgba(170,187,187,0.5);
    transition: .25s;
  }

  @media screen and (max-width: @screen-sm-min) {
    display: block;
    max-width: 100%;
    margin-bottom: 30px;
  }
}

.introduction-card-margin {
  margin-left: 5rem;
}

.introduction-image {
  padding-left: 3rem;
  min-width: 400px;
  min-height: 450px;
}

.introduction-icon {
  height: 150px;
}

@media screen and (max-width: 1366px) {
  .introduction-image {
    padding-left: 0;
  }
}

@media screen and (max-width: 1024px) {
  .introduction-secondary-text {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .introduction-second-half {
    display: none;
  }
}

.jello-horizontal {
	-webkit-animation: jello-horizontal 0.9s both;
	        animation: jello-horizontal 0.9s both;
}

 @-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}


.scale-up-hor-right {
	-webkit-animation: scale-up-hor-right 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-hor-right 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

 @-webkit-keyframes scale-up-hor-right {
  0% {
    -webkit-transform: scaleX(0.4);
            transform: scaleX(0.4);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}
@keyframes scale-up-hor-right {
  0% {
    -webkit-transform: scaleX(0.4);
            transform: scaleX(0.4);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}

.slide-in-right {
	-webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

}

.introduction-card {
  box-sizing: border-box;
  min-height: 150px;
  padding: 30px 45px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    border: 1px solid @primary-color;
    box-shadow: 0 8px 16px 0 rgba(170,187,187,0.5);
    transition: .25s;
  }

  @media screen and (max-width: @screen-sm-min) {
    display: block;
    max-width: 100%;
    margin-bottom: 30px;
  }
}

.introduction-card-margin {
  margin-left: 5rem;
}

.introduction-image {
  padding-left: 3rem;
  min-width: 400px;
  min-height: 450px;
}

.introduction-icon {
  height: 150px;
}

@media screen and (max-width: 1366px) {
  .introduction-image {
    padding-left: 0;
  }
}

@media screen and (max-width: 1024px) {
  .introduction-secondary-text {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .introduction-second-half {
    display: none;
  }
}

.jello-horizontal {
	-webkit-animation: jello-horizontal 0.9s both;
	        animation: jello-horizontal 0.9s both;
}

 @-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}


.scale-up-hor-right {
	-webkit-animation: scale-up-hor-right 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-hor-right 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

 @-webkit-keyframes scale-up-hor-right {
  0% {
    -webkit-transform: scaleX(0.4);
            transform: scaleX(0.4);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}
@keyframes scale-up-hor-right {
  0% {
    -webkit-transform: scaleX(0.4);
            transform: scaleX(0.4);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}

.slide-in-right {
	-webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
