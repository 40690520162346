.RHList {
  &__covenants {
    display: flex;
    align-items: center;
  }

  &__see-more-covenants {
    cursor: pointer;
    color: @secondary-button
  }
}