.drawer-filters {
  &__button.ant-btn-ghost {
    border-color: var(--primary-color);
    color: var(--primary-color);
    background: #fff;
    font-weight: 600;
    position: relative;

    &:hover {
      background: darken(#fff, 5%);
    }
  }

  &__button.ant-btn-ghost[disabled] {
    color: @btn-disable-color;
    background: @btn-disable-bg;
    border-color: @btn-disable-border;
  }

  &__drawer {
    .ant-drawer-body {
      padding: 0;
      padding-bottom: 50px;
    }

    .ant-drawer-header {
      border-width: 2px;
      padding: 1em;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .ant-drawer-title {
      font-weight: bold;
    }

    .ant-drawer-close {
      padding: 0px;
      position: static;
    }

    .ant-form-item, .ant-checkbox-group {
      margin: 0;
      width: 100%;
    }

    .ant-checkbox {
      top: unset;
    }

    .anticon {
      display: flex;
      align-items: center;
    }

    .ant-form-item-control-input {
      min-height: unset!important;
    }

    .ant-radio-wrapper, .ant-checkbox-wrapper {
      margin: 0;
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      align-items: center;
    }

    .ant-radio-wrapper {
      padding: 1em 1.25em;
    }

    label:not(label .ant-checkbox-wrapper) {
      user-select: none;
      min-width: 100%;
    }

    p {
      margin: 0;
    }

    .ant-radio-wrapper > span, .ant-checkbox + span {
      padding: 0;
    }

  }

  &__button.ant-btn-ghost > &__badge-count {
    position: absolute!important;
    top: -.5em;
    right: -.5em;
    pointer-events: none;

    .ant-badge-count {
      background-color: var(--primary-color);
      color: #fff;
      box-shadow: 0 0 0 0.5px var(--primary-color) inset;
      border-radius: 100%;
      line-height: 25px;
      height: 25px;
      width: 25px;
      padding: 0px;
      font-weight: bold;
      font-size: 13px;
    }
  }

  &__selected-filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--secondary-color);
    padding: .5em 1em;
    font-size: .9em;
  }

  &__button-clear {
    padding: 0;
    display: inline;
    height: unset!important;
    border: none;
    line-height: 1;
    font-weight: 600;

    &:focus-visible {
      outline: 1px solid var(--primary-color);
    }
  }

  &__line {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    padding: 1em;
    border-bottom: 1px solid var(--secondary-color);
    cursor: pointer;

    &:focus-within {
      background: darken(#fff, 10%);
    }
  }

  &__line-collapsable {
    align-items: center;
    width: 100%;
  }

  &__line-collapsable-header {
    transition: background .3s;

    &:hover, &:focus-visible {
      background: var(--secondary-color);
    }
  }


  &__line-collapsable-icon {
    transition: transform .2s ease-out;
    transform: rotate(-180deg);
    &--collapsed {
      transform: rotate(0);

    }
  }

  &__line-collapsable-content {
    display: flex;
    flex-direction: column;
    background: darken(white, 2.5%);

    &--collapsed {
      display: none;
    }

    & > * {
      width: 100%;
    }
  }


  &__line-collapsable-item {
    display: flex;
    justify-content: space-between;
    padding: 1em 1.25em;

    &:focus-within {
      background: darken(white, 10%);
    }

    &:last-child {
      border-bottom: 1px solid var(--secondary-color);
    }
  }

  &__button-search {
    position: absolute;
    z-index: 100;
    top: 95%;
    right: 5%;
  }
}
