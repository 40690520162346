.signin-b2c-modal-ecpf {

  &__content {
    display: flex;
    gap: 30px;

    @media @phone-sm {
      gap: 15px;
    }

    & > div {
      flex: 1;
    }
  }
}

.signin-b2c-modal-ecpf-card {
  padding: 15px;
  border: 1px solid @light-gray-2;
  border-radius: 20px;
  cursor: pointer;

  @media @phone-sm {
    padding: 12px;
  }

  &:hover {
    background-color: @light-gray;
    transition: 0.3s;
  }

  &__icon {
    margin-bottom: 12px;

    svg {
      color: @primary-color;
      font-size: 2.8rem;

      @media @phone-sm {
        font-size: 1.8rem;
      }
    }
  }

  &__title {
    font-size: 1.2rem;
    font-weight: 600;
    color: @primary-color;
    margin-bottom: 7px;

    @media @phone-sm {
      font-size: 1rem;
    }
  }

  &__description {
    color: lighten(@text-color, 20%);
    font-size: 0.8rem;

    @media @phone-sm {
      font-size: 0.6rem;
    }
  }
}