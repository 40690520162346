.value-card {
  &__info-card {
    background-color: @white;
    padding: 1.5rem;
    border-radius: 15px;
    text-align: center;
  }

  &__info-label {
    color: @gray;
  }

  &__info-value {
    font-weight: bold;
  }

  &__skeleton {
    &--center .ant-skeleton-content {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--end .ant-skeleton-content {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}