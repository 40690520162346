.review-description {
  width: 100% !important;
  overflow-wrap: break-word;

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__text {
    font-size: 14px;
    font-weight: 500;

    & table {
      width: 100%;
      padding: 5px;
    }

    & td {
      border: 1px solid;
      min-width: 40px;
      padding: 3px;
    }
  }
}