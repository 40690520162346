.proposal-contract {
  padding: 2rem;
  display: grid;
  grid-template-columns: 2fr 8fr;

  &__icon {
    font-size: 5rem;
    color: @primary-color;
  }

  &__title h3 {
    color: @primary-color;
    font-size: 1.5rem;
    font-weight: bold;
  }

  &__section {
    margin-bottom: 1rem;
  }

  &__renegociation-button-container {
    display: flex;
    align-items: center;

    @media @tab-md {
      font-size: 0.9rem;
      justify-content: space-between;
    }
  }

  &__only-contract {
    @media @tab-md {
      font-size: 1rem;
    }
  }

  &__renegociation-button {
    height: 30px !important;
    margin-left: 1rem;
  }

  &__renegociation-contract {
    background-color: @light-gray;
    padding: 0.3rem;
    border-radius: 10px;
    margin: 0.2rem !important;
    white-space: nowrap;

    @media @tab-md {
      justify-content: center
    }
  }

  &__renegociation-contracts-container {
    @media @tab-md {
      justify-content: center
    }
  }

}

@media @tab-md {
  .proposal-contract {
    grid-template-columns: 1fr;

    &__title h3 {
      padding: 1rem 0;
      text-align: center;
    }
  }

}