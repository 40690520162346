.summary-proposal {
  & &__box {
    padding: 4rem;
    width: 100%;
    @media @tab-lg {
      width: 100%;
      padding: 2rem;
    }
  }

  &__container  {
    .flex-full-center();
    width: 100%;
    margin-bottom: 1em;
  }

  &__item-container {
    width: 33%;
    display: inline-block;

    @media @tab-lg {
      width: 50%;
    }

    @media @phone-sm {
      width: 100%;
    }
  }
}
