.proposal-due-ecpf {
  padding: 2rem;
  display: flex;
  gap: 2rem;

  &__content {
    width: 82%;
  }

  &__icon {
    font-size: 5rem;
    color: @primary-color;
    width: 18%;
  }

  &__title h3 {
    color: @primary-color;
    font-size: 1.5rem;
    font-weight: bold;
  }
}


@media @tab-md {
  .proposal-due-ecpf {
    flex-direction: column;

    &__icon {
      width: 100%;
    }

    &__content {
      width: 100%;
    }

    &__title h3 {
      padding: 1rem 0;
      text-align: center;
    }
  }

}