.proposal-history {
  padding: 2rem;
  padding-top: 2.35rem;

  .ant-timeline-item {
    padding-bottom: 40px;

    @media @phone-sm {
      padding-bottom: 25px;
    }
  }

  .ant-timeline-item-right .ant-timeline-item-content {
    padding-right: 1em;
  }

  .ant-timeline-item-last {
    padding-bottom: 0;
  }

  @media @phone-sm {
    padding: 1rem;
    padding-top: 1.35rem;
  }
}
