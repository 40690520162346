.change-email {
    &__title {
        font-weight: bold;
        text-align: center;
        color: @primary-color;
    }

    &__icon {
        font-size: 3rem;
        color: @primary-color;
    }

    &__header {
        .flex-full-center();
        flex-direction: column;
        margin: 0 1.5rem;
    }

    &__content {
        margin: 0 2rem;
    }

    &__subtitle {
        font-size: 1.1rem;
        text-align: center;
    }

    &__current-email {
        font-weight: bold;
    }

    &__token-content {
        margin: 1rem;
    }
}