.rh-covenant-select {

  &__table-header {
    background-color: @primary-color;
    color: @white;
    width: 100%;
    border-radius: 20px;
  }

  &__table-title-item {
    height: 60px;
    
    &:first-child {
      -webkit-border-top-left-radius: 20px;
      -webkit-border-bottom-left-radius: 20px;
      -moz-border-radius-topleft: 20px;
      -moz-border-radius-bottomleft: 20px;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    &:last-child {
      -webkit-border-top-right-radius: 20px;
      -webkit-border-bottom-right-radius: 20px;
      -moz-border-radius-topright: 20px;
      -moz-border-radius-bottomright: 20px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }

  &__covenant-row {
    background: @white;
    border-radius: 20px;
    height: 60px;
    margin-top: 1rem;
    transition: all .2s ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: @light-gray;
    }
  }

  &__table-container {
    overflow: auto;

    &::-webkit-scrollbar {
      background: @secondary-color !important;
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--primary-color);
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: lighten(@primary-color, 5%);
    }
  }

  &__table {
    border-collapse: separate;
    text-indent: initial;
    border-spacing: 0px 10px;
    min-width: 1200px;
  }

  &__row-item {
    &:first-child {
      -webkit-border-top-left-radius: 20px;
      -webkit-border-bottom-left-radius: 20px;
      -moz-border-radius-topleft: 20px;
      -moz-border-radius-bottomleft: 20px;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    &:last-child {
      -webkit-border-top-right-radius: 20px;
      -webkit-border-bottom-right-radius: 20px;
      -moz-border-radius-topright: 20px;
      -moz-border-radius-bottomright: 20px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }

  &__row-item-skeleton {
    border-radius: 20px;
    overflow: auto;
  }

  &__error-container {
    min-width: 1200px;
  }
}