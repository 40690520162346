.renegotiation-simulation {

  &__warning-title {
    font-size: 1.05rem;
    text-transform: uppercase;
    margin-bottom: .1rem;
  }

  &__warning-card {
    margin-top: 1.5625rem;
    align-items: center;

    .ant-alert-with-description .ant-alert-message {
      margin-bottom: 2px;
    }
  }

  &__warning-icon {
    svg {
      font-size: 35px;
    }
  }
}