.RHList-Covenants {
	width: 100%;
	max-height: 400px;
	overflow: scroll;
	font-size: 13;

	&__title {
		text-align: center;
		font-weight: bold;
	}

	&__description {
		display: flex;
		align-items: center;
		margin-bottom: 1rem;
		color: @primary-color;
		font-weight: bold;

	}

	&__rh-title {
		display: flex;
		padding: 0 1rem;
		border-radius: 10px;
		flex-direction: column;
	}

	&__name {
		background: @light-gray;
		padding: 0 0.5rem;
		border-radius: 5px;
		justify-content: center;
		width: fit-content;
		align-items: center;
		display: flex;
		margin: 0.3rem 0;
	}

	&__column-title {
		color: @primary-color
	}

	&__data {
		font-weight: 400;
		font-size: 13px;
		padding: 0.5rem 0;
		border-bottom: 1px solid @primary-color;
	}
}