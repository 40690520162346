.auth-layout {

  &__header-title {
    font-size: 18px;
    color: @primary-color;
    font-weight: bold;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    word-wrap: break-word;
  }

  &__content {
    overflow-x: hidden;
    position: relative;
    background-color: var(--secondary-color);
  }

  &__warning-button {
    font-weight: bold;
    border: none;
    background-color: @warning-button-color;
    padding: 0 20px;
  }

  &__full-view-height {
    min-height: 100vh;
    max-height: 100vh;
    overflow: hidden;
  }

  &__layout-container {
    height: 90vh;
  }

  &__full-height {
    height: 100%;
  }

  &__fallback-spin {
    width: 100%;
    .flex-full-center();
  }
}
