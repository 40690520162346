
.cnd-review-date-container-mixin() {
  background-color: @light-gray;
  border-radius: @border-radius-base;
  padding: 1rem;

  .ant-form-item {
    margin-bottom: 0;
  }
}

.cnd-review {

  &__expiration {
    margin-bottom: 3rem;
  }

  &__expiration-error-message {
    color: var(--red-status);
  }

  &__expiration-title {
    color: @primary-color;
    font-weight: bold;
    font-size: 1.2rem;
  }

  &__expiration-description {
    margin-bottom: 1.5rem;
  }

  &__expiration-date-container {
    display: flex;
    align-items: center;

    .cnd-review-date-container-mixin()
  }

  &__expiration-edit-date-container {
    .cnd-review-date-container-mixin()
  }

  &__expiration-buttons-container {
    display: flex;
    gap: .5rem;
    margin-top: .5rem;
  }

  &__expiration-save-button {
    align-self: flex-end;
  }

  &__expiration-icon {
    font-size: 1.1rem;
    margin-left: .5rem;
    cursor: pointer;

    &--green {
      color: var(--green-status);
    }

    &--primary-color {
      color: @primary-color;
    }

    &--text {
      display: inline-block;
      cursor: auto;
    }
  }
}
