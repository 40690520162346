.customer-flow-ecpf {

  &__card {
    padding: 40px 20px;
    text-align: center;
  }

  &__card-image {
    margin-bottom: 20px;
  }

  &__card-content {
    padding: 20px;
    background-color: @light-gray;
    border-radius: 20px;
    width: fit-content;
    margin: auto;
  }

  &__cnpj-button {
    @media @phone-sm {
      width: 100%;
    }
  }

  &__info-tooltip {
    color: var(--blue-status);

    svg {
      width: 14px;
    }
  }
}