@import './components/ChatHeader/ChatHeader';

.generic-chat {
  display: flex;
  height: @full-container-chat;
  margin: auto 0;
  border-radius: 20px;

  &__create-chat {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 10px;
  }

  &__new-message {
    font-size: 15px !important;
    font-weight: bold;
    color: var(--primary-color);
    margin: 0;
    padding: 0;
    text-align: center;
  }

  &__plus-icon {
    transition: 200ms;
    text-align: center;
    background: @body-background;
    height: 25px;
    width: 25px;
    border-radius: 5px;

    & svg {
      margin-bottom: -1px;
      color: var(--primary-color);
      font-size: 17px;
    }

    &:hover,
    &:focus {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  &__search {
    width: 100%;
    margin-bottom: 15px;
  }

  &__search-input {
    margin-bottom: 10px;

    .ant-input {
      z-index: 0 !important;
      width: calc(100% + 45px);
      border-radius: 12px !important;
    }

    .ant-input-group-addon {
      background: transparent !important;

      .ant-btn,
      .ant-btn-icon-only,
      .ant-input-search-button {
        border: none !important;
        background: transparent !important;

        & svg {
          z-index: 15;
          color: @secondary-bg-color-chat;

          &:hover {
            color: var(--primary-color);
          }
        }
      }
    }

    .ant-input-search-button:hover {
      background-color: transparent !important;
    }
  }

  &__modal-chat {
    margin-top: -20px;
  }

  &>.messenger__aside {
    position: relative;
  }

  &__aside-new-message {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 16;
    background-color: @component-background;
    border-radius: 25px 0 0 25px;

    height: 100%;
    width: 0;
    overflow: auto;
    overflow-x: hidden;

    &--active {
      width: 100%;
    }
  }

  &__new-message-info {
    width: 100%;
    margin: 24px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    & p {
      margin-bottom: 0;
      margin-left: 10px;
    }
  }

  &__new-message-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__new-message-loading {
    margin-bottom: 10px;
    padding-left: 15px;
    width: 100%;

    & span {
      width: 100% !important;
    }
  }

  &__new-message-select {
    width: 100%;
  }

  &__new-message-submit {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__communication-header {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--prefix,
    &--suffix {
      display: flex;
      align-items: center;
    }

    &--avatar {
      @media @phone-sm {
        width: 40px;
        height: 40px;
      }
    }
  }

  &__user-info {
    display: flex;
    flex-direction: column;

    p {
      margin: 0;
      line-height: 1;
    }

    &--members {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 5px;
    }

    &--reason {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    &--name,
    &--reason,
    &--members {
      @media @phone-sm {
        font-size: 12px;
      }
    }

    &--name {
      font-weight: bold;
    }
  }

  &__close-chat {
    margin-right: 15px;
    font-size: 24px;
    cursor: pointer;
  }

  &__members-list {
    margin-bottom: 10px;
    font-weight: bold;
    position: relative;

    &::after {
      content: '';
      background-color: var(--primary-color);
      width: 20px;
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }


  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__header-avatar {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    border: 1px solid #fff;

    @media @phone-sm {
      display: none;
    }
  }

  &__header-info {
    display: flex;
    padding: 1rem;
    flex-direction: column;
  }

  &__header-members {
    font-size: 0.8rem;
  }

  &__header-user {
    font-size: 1rem;
  }

  &__header-user--helpdesk {
    cursor: pointer;
  }

  &__header-back-to-list {
    display: none;

    @media @tab-lg {
      display: inline;
    }
  }

  &__end-chat-icon {
    display: none;
    color: @primary-color;
    font-size: 2rem;

    @media @phone-sm {
      display: inline;
    }
  }

  &__modal-buttons {
    display: flex;
    justify-content: flex-end;
    width:100%;
  }

  &__end-chat {
    @media @phone-sm {
      display: none;
    }
  }

  &__user-info-main {
    .flex-full-center();
  }

  &__user-info-container {
    padding: 0 1rem;
  }

  &__user-info-icon {
    padding: 0.5rem;
  }


  .messenger__messages-send__input {
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #dddddd;
      border-radius: 2px !important;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #bbb;
    }
  }

  .messenger__aside-list {
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #334555;
      border-radius: 2px !important;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #24303A;
    }
  }

  .messenger__messages-container {
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #bbb;
      border-radius: 2px !important;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #999;
    }
  }

}
