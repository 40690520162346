.rerequest-button {
  justify-self: center;
  align-self: center;
  border-radius: 50%;
  padding: 4px 8px;
  width: 40px !important;
  height: 40px !important;
  color: @white;

  &:enabled:hover {
    rotate: 180deg;
    color: @primary-color;
    background-color: transparent;
  }

  &--full-width {
    width: 100% !important;

    &:enabled:hover {
      rotate: unset;
      color: @primary-color;
      background-color: transparent;
    }
  }
}
