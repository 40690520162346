@import 'src/components/MultiSelectModal/MultiSelectModal';

.multi-select {
  &__number-of-selecteds {
    font-size: 0.9rem;
    font-style: italic;
    margin: 0.15rem 0 0;
    text-align: end;
    width: 100%;
  }
}