@import './components/DepartmentSection/DepartmentSection.less';
@import './components/PartnerDepartments/PartnerDepartments.less';
@import './components/PartnerStep/PartnerStep.less';
@import './components/ChooseMinuta/ChooseMinuta.less';

.partner-line {
	padding: 5px;
	background-color: white;
	border-radius: 20px;

    &__caption {
			margin: 15px 0px;
    }
}