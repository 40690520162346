.last-activity-modal-buttons {
  &__reason-description-container {
    border-radius: 14px;
    border: 1px solid @light-gray-2;
    padding: .8rem;
  }

  &__detail-item {
    width: 100%;
    height: 100%;
    padding: 15px 0 3px 0;
    border-bottom: 1px solid @light-gray-3;

    &__label {
      font-weight: bold;
      color: @primary-text;
    }
  
    &__value {
      word-break: break-all;
      padding-top: 5px;
      font-size: 1rem;
      color: @primary-text;
  
      @media @phone-sm {
        font-size: 1rem;
      }
    }
  }
}