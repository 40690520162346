@import '../../components/InfoCard/InfoCard';
@import '../../components/ProgressCard/ProgressCard';
@import '../../components/ScoreForm/ScoreForm';

.score {
  &__titles {
    font-weight: bold;
    color: @primary-color;
  }

  & .ecp-card {
    height: 100%;
    padding: 2rem;
  }

  & .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: @primary-color;
  }
}