@base-space: 20px;

.unauth-layout {
  &__icon-container {
    margin-bottom: 30px;
    text-align: center;

    svg {
      max-width: 250px;
      max-height: 250px;
    }
  }

  &__title {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: bold;
  }

  &__text {
    font-size: 16px;

    &--2 {
      font-size: 14px;
    }
  }

  &__text-container {
    margin-bottom: 30px;
    max-width: 500px;
  }

  &__button-container {
    text-align: center;
  }

  &__link {
    text-decoration: underline !important;
  }
}


.unauth-upload-background {
  min-height: 100vh;

  background-color: @secondary-color;
  background-repeat: no-repeat;
  background-size: cover;
}

.container-unauth {
  background-color: @primary-color;

  & img.unauth-dots {
    -drag: none;
    user-select: none;
    width: 25%;
    -webkit-user-drag: none;

    &:first-child {
      transform: rotate(180deg);
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }

  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
  }
}

.full-height-unauth {
  height: 100vh;
  overflow-y: auto;
}

.unauth-upload-photo {
  margin-top: 8px;
  width: 138px;
  color: @secondary-color
}

.unauth-reset-password-text {
  text-align: center;
  font-weight: 500;
  color: @primary-color;
  margin-bottom: 10px;
}

.unauth-header {
  width: 100%;
  padding: 20px 0 20px 10em;
  background-color: @secondary-color;
  position: relative;

  display: flex;
  flex-direction: row;
  @media screen and (max-width: 1100px) {
    display: none;
  }
}

.unauth-header-title {
  font-size: 64px;
  margin-bottom: 0;
  color: white;
  z-index: 2;
}

.unauth-header-shadow {
  font-size: 8rem;
  margin: 0;
  color: rgba(0, 0, 0,0.2);
  font-weight: 700;

  position: absolute;
  top: -0.40em;
  left: 2.5em;
  z-index: 1;
}

.signin-image-logo {
  width: 200px;
  margin: 32px 0;
  object-fit: contain;
  user-select: none;
  -drag: none;
}

#root .unauth-form {
  .ant-form-item {
    margin-bottom: 16px;
  }
}

.unauth-bottom-label {
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 700;
}

@media screen and (max-width: 992px) {
  .image-place {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .image-place {
    flex: 3;
    border: 2px solid white;
    -webkit-box-shadow: 5px 5px 30px -1px rgba(255, 255, 255,0.12);
    box-shadow: 5px 5px 30px -1px rgba(255, 255, 255,0.12);

    background-image: linear-gradient(to left bottom, #9FC519, #46B452, #009C74, #008181, #006476);
    overflow: hidden;

    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-origin: content-box;
    margin: @base-space;
    border-radius: @base-space;
  }

  .text-unauth-container {
    background-color: black;
    opacity: .4;
    border-radius: @base-space;
  }

  .text-unauth-image {
    position: absolute;
    color: white;
    z-index: 10;
    height: 100%;
    font-size: 150px;

    width: 100%;
  }

  .unauth-images {
    position: relative;
    width: 100%;
    height: 100%;
    top: -15%;
    left: -15%;
    opacity: .3;
  }

  .explain-image-unauth {
    text-align: start;
    color: @secondary-color;
    height: 100%;
    padding: 30px 0;
    border-radius: @base-space;
  }
}

.unauth-simulator-date {
  min-height: 50px;
  font-size: 16px;

  input {
    text-align: left;
  }

  .ant-input {
    min-height: 40px !important;

    &::placeholder {
      color: @primary-color !important;
    }
  }

  .ant-select-selector {
    min-height: 50px;
    padding: 9px 11px !important;

    .ant-select-selection-search-input {
      min-height: 40px;
      text-align: left;
    }
  }

  &:hover {
    border-color: @primary-color !important;
  }
}

.unauth-inputs-center {
  min-height: 50px;
  font-size: 16px;

  .ant-input {
    min-height: 40px !important;
  }

  .ant-select-selector {
    min-height: 50px;
    padding: 9px 11px !important;

    .ant-select-selection-search-input {
      min-height: 45px;
      text-align: left;
    }

    .ant-select-selection-item {
      text-align: left;
    }

    .ant-select-selection-placeholder {
      color: @primary-color !important;
      text-align: left;
    }
  }

  &:hover {
    border-color: @primary-color !important;
  }
}

.ant-select.unauth-inputs-center.ant-select-single.ant-select-show-arrow.ant-select-show-search :hover {
  border-color: @primary-color !important;
}

.unauth-inputs {
  min-height: 50px;
  font-size: 16px;
  border-color: @primary-color;

  .ant-form-item-has-error {
    border-color: @error-text !important;
  }

  .ant-select-arrow > span {
    color: @tertiary-color;
  }

  .ant-input {
    min-height: 40px !important;
    border-color: @secondary-color !important;
  }

  .ant-select-selector {
    min-height: 50px;
    padding: 9px 11px !important;
    border-color: @primary-color !important;

    .ant-select-selection-search-input {
      min-height: 45px;
    }
  }

  .ant-input-suffix {
    & .anticon-calendar {
      color: @primary-color !important;
    }

    & .anticon-eye-invisible {
      color: @primary-color;
    }

    & .anticon-eye {
      color: @primary-color;
    }
  }
}

.unauth-signup-border .anticon.anticon-calendar {
  color: @secondary-color !important;
}

.unauth-form .ant-form-item-control-input-content {
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color: @primary-color !important;
    box-shadow: 0px 0px 0px 2px rgba(15,64,111,0.1);
  }
}

.unauth-form-border .ant-select.unauth-inputs.ant-select-single.ant-select-show-arrow :hover {
  border-color: @primary-color !important;
}

.unauth-form-border .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: @primary-color !important;
  box-shadow: 0px 0px 0px 2px rgba(15,64,111,0.1);
}

.unauth-inputs:hover,
.unauth-inputs:focus {
  border-color: @primary-color !important;
}

.unauth-inputs:focus {
  border-color: @primary-color !important;
  box-shadow: 0px 0px 0px 2px rgba(15,64,111,0.1);
}

.unauth-buttons {
  min-height: 50px;
  text-transform: uppercase;
  margin-bottom: 16px;

  span {
    color: white !important;
    font-size: 16px;
    font-weight: 700;
  }
}

.unauth-buttons-ghost {
  text-transform: uppercase;
  min-height: 50px;
  border: 1px solid @primary-color;
  color: @primary-color;
  font-size: 16px;
  font-weight: 700;
}

.unauth-oidc {
  text-transform: uppercase;
  color: #f7931e!important;
  font-weight: bolder!important;
  text-decoration: underline;
  .flex-full-center();

  &:hover {
    opacity: 0.5;
  }
}

.unauth-signup-label {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
}

.unauth-signup-border {
  @media screen and (min-width: 1000px) {
    min-width: 850px;
    max-width: 980px;
    border-radius: 5px;
    background-color: #E0E5CF;
    padding: 35px 250px 35px 250px;
  }

  @media screen and (min-width: 768px) {
    border-radius: 5px;
    background-color: #E0E5CF;
    padding: 25px;
  }

  @media screen and (min-width: 320px) {
    width: 100%;
    background-color: #E0E5CF;
  }
}

@media screen and (min-width: 768px) {
  .container-unauth aside.form-place {
    padding: 20px;
  }
}

@media screen and (max-width: 768px) {
  .container-unauth aside.form-place {
    padding: 0;
  }
}

.unauth-form-border {
  border-radius: 20px;
  padding: 25px;
  width: 567px;
  background-color: #E0E5CF;

  @media screen and (max-width: 1260px) {
    border-radius: 20px;
    background-color: #E0E5CF;
  }

  @media screen and (max-width: 1000px) {
    border-radius: 20px;
    background-color: #E0E5CF;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    border-radius: 20px;
    background-color: #E0E5CF;
  }

  @media screen and (max-width: 425px) {
    width: 100%;
    border-radius: 20px;
    background-color: #E0E5CF;
  }
}

.unauth-list-selfie {
  list-style-type: '- ';
  padding-left: 0;
}

.form-place {
  padding: 20px;
  min-height: 445px;

  display: flex;
  align-items: center;

  @media screen and (max-width: 1200px) {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.unauth-signup-border .ant-checkbox .ant-checkbox-inner,
.request-cnd-checkbox .ant-checkbox .ant-checkbox-inner{
  width: 25px;
  height: 25px;
  border-color: @primary-color;
  border-radius: 6px;
}

.checkbox-lgpd .ant-checkbox .ant-checkbox-inner {
  width: 25px;
  height: 25px;
  border-color: @primary-color;
  border-radius: 6px;
}

.unauth-signup-border .ant-checkbox-disabled .ant-checkbox-inner ,
.checkbox-lgpd .ant-checkbox-disabled .ant-checkbox-inner{
  width: 25px;
  height: 25px;
  border-radius: 6px;
}

.unauth-signup-border .ant-checkbox-checked .ant-checkbox-inner,
.request-cnd-checkbox .ant-checkbox-checked .ant-checkbox-inner{
  width: 25px;
  height: 25px;
  border-radius: 6px;
  border-color: @primary-color;
}

.checkbox-lgpd .ant-checkbox-checked .ant-checkbox-inner {
  width: 25px;
  height: 25px;
  border-radius: 6px;
  border-color: @primary-color;
}

.unauth-signup-border .ant-checkbox.ant-checkbox-checked:hover,
.checkbox-lgpd .ant-checkbox.ant-checkbox-checked:hover{
  border-radius: 6px !important;
}

.unauth-signup-border .ant-checkbox-inner::after,
.checkbox-lgpd .ant-checkbox-inner::after{
  width: 7px;
  height: 15px;
}

.checkbox-lgpd .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
  background-color: @primary-color !important;
}

.checkbox-lgpd .ant-checkbox-checked::after {
  border-color: @primary-color !important;
  border-radius: 6px;
}

.unauth-signup-border .ant-checkbox-checked::after {
  border-radius: 6px;
}

.unauth-terms-label {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: @primary-color;
}

.unauth-terms-label a {
  text-decoration: underline;
}

.signup-form-margin {
  margin: 0 10%;
}

.avatar-uploader > .ant-upload {
  width: 170px;
  height: 170px;
  border: 1px solid @secondary-color;
  max-height: 100%;
}

.ant-upload.ant-upload-select-picture-card:hover {
  border-color: @secondary-color !important;
}


.unauth-divider {
  margin: 0 !important;

  .ant-divider-inner-text {
    color: @primary-color;
    font-weight: bold;
  }
}

