.portability-data {
  &__card {
    padding: 2rem !important;

    @media @phone-sm {
      padding: 1rem;
    }
  }

  &__details {
    tr {
      border-bottom: 1px solid var(--primary-color);
      height: 40px;
    }

    td:last-child {
      white-space: nowrap;
      text-align: right;
    }
    .ant-skeleton-element {
      width: 30px!important
    }

    @media @phone-sm {
      font-size: .75rem;
    }
  }
}
