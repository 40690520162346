.download-modal {
  &__title {
    text-align: center;
    font-size: 1.5rem;
    color: @primary-color;
  }

  &__container-list {
    display: flex;
    justify-content: center;
  }

  &__list {
    width: 100%;
  }

  &__list-item {
    background: @light-gray;
    color: @primary-color;
    margin: 1rem;
    border-radius: 10px;
    padding: 0.5rem;
    list-style: none;
  }
}