.proposal-configuration-fields {
  padding-left: 25px;
  padding-right: 25px;
  overflow: auto;

  &::-webkit-scrollbar {
    background: @light-gray !important;
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: @light-gray-3;
    border-radius: 20px;
    transition: all .2s ease-in-out;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: lighten(@primary-color, 5%);
  }

  @media @tab-xl {
    padding: 0
  }

  &__skeleton-button {
    & .ant-skeleton-button {
      width: 100%;
    }
  }
}