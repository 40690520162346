.qualification-container {
  &__area {
    &--textarea {
      margin-top: 20px;
    }

    &--textarea:empty{
      &::before {
        content: attr(data-placeholder);
        color: grey;
      }
    }
  }
}
