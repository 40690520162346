.step-warning {
  background-color: @light-gray;
  padding: 1rem;
  border-radius: 20px;

  &__icon {
    font-size: 2.5rem;
    margin-right: 1.5rem;
    color: @analysis-status-color;
  }
}