.confirmation-email-modal {
  & .ant-modal-content {
    border-radius: 20px !important;
  }

  .ant-modal-close-x {
    color: @primary-color;
    font-size: 25px;
  }

  &__title-container {
    margin-bottom: 20px;
    text-align: center;
  }

  &__content-container {
    background: @light-gray;
    margin: 20px 0;
    padding: 10px;
  }

  &__title {
    font-weight: bold;
    color: @primary-color;
    margin-bottom: 8px;
  }

  &__enter-link {
    font-size: 14px;
  }

  &__title {
    font-size: 28px;
    color: @primary-color !important;
  }

  &__text {
    font-size: 16px;
    color: @primary-color !important;
    width: 65%;
  }

  &__link {
    color: @primary-color !important;
    font-weight: bold;
    text-decoration: underline !important;
  }

  &__button {
    width: 25%;

    &--confirm {
      background: @primary-color !important;
      min-height: 50px !important;
      font-size: 18px !important;
      color: white !important;
      margin-right: 10px;
    }

    &--cancel {
      min-height: 50px !important;
      font-size: 18px !important;
      border-color: @primary-color !important;
      border-width: 2px !important;
      margin-left: 10px;
    }

    & span {
      font-weight: 700;
    }
  }
}
