.color-white {
  color: @white !important;
}

.color-danger {
  color: @error-text;
}

.color-warning {
  color: @warning-color !important;
}

.color-black {
  color: @black;
}

.color-tertiary {
  color: @tertiary-color !important;
}

.color-secondary {
  color: @secondary-color !important;
}

.color-analysis {
  color: @analysis-status-color !important;
}

.color-refused {
  color: @refused-status-color !important;
}

.color-canceled {
  color: @canceled-status-color !important;
}

.color-approved {
  color: @approved-status-color !important;
}
.color-updated {
  color: @updated-color !important;
}

.color-ligth-blue-dash {
  color: #56D9FE !important;
}

.color-gray {
  color: @base-gray-color !important;
}

.color-primary {
  color: @primary-color !important;
}

.underline {
  text-decoration: underline;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.bold {
  font-weight: bold;
}

.uppercase {
  text-transform: uppercase;
}

.link-style {
  text-decoration: underline !important;
  color: @primary-color;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
}

.primary-color {
  color: @primary-color !important;
}

.font-500 {
  font-weight: 500 !important;
}

.font-400 {
  font-weight: 400 !important;
}


.font-size-12{
  font-size: 12px !important;
}

.font-size-10 {
  font-size: 10px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-25 {
  font-size: 25px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.text-disabled {
  opacity: .5;
  cursor: not-allowed;
}

.capitalize {
  text-transform: capitalize;
}

.word-break-all {
  word-break: break-all;
}