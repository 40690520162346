.show-backoffices {
  &__title {
    font-weight: bold;
    color: @primary-color;
  }

  &__list {
    margin: 0.5rem;
    background-color: @light-gray;
    border-radius: @border-radius;
    padding: 1rem;
    list-style-position: inside;
  }
}