.lgpd-access {
  &__warning{
    background-color: @light-gray;
    border-radius: 10px;
    margin-bottom: 1rem;
    padding: 0.5rem;
  }

  &__warning-icon {
    color: @warning-color
  }

  &__svg {
    .flex-full-center();
    margin-bottom: 2rem;
  }

  &__info-text {
    color: #00441F;
    font-size: 1.2rem;
    margin-bottom: 30px;
  }

  &__upload-list-preview {
    border-radius: 10px;
    padding: 0.5rem;
    word-break: break-all;
    &:hover {
      background-color: @light-gray;
      cursor: pointer;
    }
  }

  &__upload-list-item {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
  }
  
}