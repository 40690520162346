.products-dropdown {
  &__menu {
    max-width: 400px;
    padding: 1rem;

    @media @phone-sm {
      max-width: 80vw;
    }
  }

  &__item-subtitle {
    font-weight: bold;
    font-size: 1rem;
  }

  &__body {
    & > div:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__current-product {
    @media @tab-md {
      color: #000000d9 !important;
      font-size: 14px;
      font-weight: 700;
    }
  }
}
