.settings-card {
  background-color: white;
  padding: 2rem;
  border-radius: @border-radius;

  &__title {
    font-weight: bold;
    color: @primary-color;
    font-size: 1.5rem;
  }

  &__button-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
  }

  &__header-content {
    .flex-full-center();
    @media @tab-md {
      flex-direction: column;
      text-align: center;
    }
  }

  &__header-icon {
    font-size: 4rem;
    color: @primary-color;
    padding: 0 4rem 0 2rem;
    @media @tab-md {
      padding: 1rem 0;
    }
  }
}