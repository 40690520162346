.mixin-titles-lato() {
  font-family: Montserrat;
  font-size: 28px;
  line-height: 33.6px;
}

.simulator-title {
  .mixin-titles-lato();

  color: @primary-color;
  text-transform: uppercase;
  font-weight: 700;
}

.simulator-title-baseline {
  .mixin-titles-lato();

  color: @primary-color;
  font-weight: 700;
}

.simulator-calculation-radio-button {
  background: @primary-color !important;
  min-height: 38px !important;
  font-size: 18px !important;
  color: white !important;
}

.simulator-calculation-radio-button:hover,
.simulator-calculation-radio-button:focus {
  border-color: @primary-color !important;
}

.simulator-specialist-radio-button {
  min-height: 38px !important;
  font-size: 18px !important;
}

.simulator-specialist-radio-button:hover {
  border-color: @primary-color !important;
  color: @primary-color !important;
}

.simulator-informations {
  font-size: 18px;
  line-height: 21.5px;
  text-transform: uppercase;
  margin-bottom: 24px;
  padding-bottom: 0;
  border-bottom: 0;
  width: 100%;

  &:hover {
    color: darken(@primary-color, 5%)
  }
}

.simulator-modal {
  @media (max-width: 1000px) {
    width: 100vw !important;
  }

  .ant-modal-close-x {
    border-radius: 50%;
    border: 2px solid @primary-color;
    color: @primary-color;

    width: 48px;
    height: 48px;
    margin: 28px;

    .flex-full-center();
  }
}

.simulator-result-label {
  color: @primary-color;
  font-size: 15px !important;
  font-weight: 600;
}