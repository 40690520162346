.variables-available {
&__tags-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  column-gap: 0.2rem;
  margin-top: 0.5rem;
}

  &__tag {
    transition: transform 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      transform: scale(1.08);
    }
  }
}
