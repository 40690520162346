@import '../PartnerStepButton/PartnerStepButton.less';
@import './Skeleton/PartnerStepSkeleton.less';
@import '../../components/PartnerStep/steps/BacenStep/BacenStep.less';
@import '../../components/PartnerStep/steps/LGPDStep/FormLGPD/FormLGPD.less';
@import '../../components/ReviewStep/ReviewStep.less';

.partner-step {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &:last-child figure::after {
    content: unset;
  }
}