
.zip-documents-log-modal {
  &__text-container {
    margin-bottom: 2.5rem;
  }

  &__title {
    margin-bottom: 0;
  }

  &__link {
    text-decoration: underline;    
  }

  &__description {
    margin-bottom: .75rem;
  }

  &__icon-container {
    border-radius: 50%;
    padding: 5px;

    width: 25px;
    height: 25px;

    svg {
      color: white;
    }
  }

  &__error {
    color: var(--red-status);
  }

  &__help-text {
    max-width: 43.75rem;
  }

  &__help-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 2px;
  }
}
