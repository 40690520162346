.rh-transfers-detailing {
  &__data-picker-container {
    width: 150px;
  }

  &__table {
    .ant-form-item {
      margin-bottom: 0px;
    }
  }
}