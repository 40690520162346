.proposal-emitter {
  padding: 2rem;
  display: grid;
  grid-template-columns: 2fr 8fr;
  &__icon {
    font-size: 5rem;
    color: @primary-color;
  }
  &__title h3{
    color: @primary-color;
    font-size: 1.5rem;
    font-weight: bold;
  }
  &__section {
    margin-bottom: 1rem;
  }
}

@media @tab-md {
  .proposal-emitter {
    grid-template-columns: 1fr;
    &__title h3 {
      padding: 1rem 0;
      text-align: center;
    }
  }
}