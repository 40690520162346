.proposal-card {
  width: 100%;
  height: 100%;
  padding: 1.75rem 2rem 1.25rem;
  background-color: white;
  border-radius: @border-radius-base;

  @media @phone-sm {
    padding: 1.25rem 1.5rem 1rem;
  }
}