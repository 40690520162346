.upload-step-section {

  @base-gap: 8px;
  @card-size: 12.5rem;

  .base-grid() {
    display: grid;
    gap: @base-gap;
    grid-template-columns: repeat(auto-fill, minmax(@card-size, 1fr));
  }

  &__info-container {
    display: flex;
    gap: 2rem;
    margin-bottom: 1.5rem;
  }

  &__skeleton-container {
    display: block;
  }

  &__files-container {
    .ant-upload-list {
      .base-grid();
      margin-top: @base-gap;

      &::before {
        display: none;
      }
    }

    .ant-upload-list-picture-container {
      width: 100%;
    }

    .ant-upload-list-item {
      margin-top: 0;
    }
  }

  &__skeleton-button-container {
    margin-bottom: @base-gap;
  }

  &__skeleton-container {
    .base-grid();
  }

  &__skeleton-button {
    max-width: 11.125rem;
  }

  &__skeleton-document {
    min-height: 4.125rem;
    border-radius: 1.25rem;
  }

  &__error-container {
    text-align: center;
  }

  &__icon {
    color: var(--blue-status);
    font-size: 3rem;
    margin-top: 1rem;
  }

  &__list {
    list-style-position: inside;
  }

  &__text-container {
    display: block;
  }

  &__subtitle {
    color: @primary-text !important;
    margin-bottom: 10px;
  }

}