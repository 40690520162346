
.simulation-form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;

  &__header {
    width: 100%;
    flex-grow: 1;
    padding: 2rem;

    @media @phone-sm {
      padding: 1rem;
    }
  }

  &__form {
    flex-grow: 1;
    width: 100%;
  }

  &__value-section {
    padding: 2rem;

    @media @phone-sm {
      padding: 1rem;
    }
  }

  &__input-label {
    .flex-full-center();
    padding-bottom: 1rem;
  }

  &__secure-display {
    .flex-full-center()
  }

  &__antd-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__card {
    color: var(--primary-color);
    display: flex;
    flex-direction: column;
    gap: 1em;

    &--max-width {
      max-width: 600px;
      margin: 0 auto;
    }
  }

  &__sub-title {
    color: var(--primary-color);
    font-weight: bold;
    margin: 0;
  }

  &__input {
    .ant-form-item {
      margin: 0;
      input {
        text-align: center;
      }
    }

    text-align: center;
    margin-bottom: 2em;

    &--card-centered {
      background-color: @light-gray;
      padding: .5rem;
      border-radius: @border-radius-base;

      .ant-form-item-label {
        text-align: center !important;
      }
    }
  }

  &__select-option {
    text-align: center;
    width: 97%;
  }

  label {
    color: var(--primary-color);
    font-weight: 600;
  }

  &__slider-label {
    display: flex;
    flex-direction: column;
    color: var(--primary-color);
    &:last-child {
      text-align: right;

    }
  }

  &__input-hide {
    display: none;
  }


}
