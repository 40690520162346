.request-list-filter {
  &__error-type {
    border-top: 1px #e2e2e2 solid;
    padding:1rem 0;
  }

  &__buttons {
    display: flex;
    @media @phone-sm {
      flex-direction: column;
    }
  }
  &__range-picker {
    margin-right: 1rem;
    @media @phone-sm {
      margin-bottom: 0.5rem;
      margin-right: 0rem;
    }
  }
}
