.add-income-container {
  height: 55px;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}


.add-button-container {
  background-color: white;
  .flex-full-center();
}

.add-button-circle {
  width: 25px;
  height: 25px;
  background-color: white;
  display: flex;
  padding: 2px;
  position: relative;
}

.add-button-circle:hover {
  cursor: pointer;
}

.plus-sign {
  font-size: 26px;
  color: #9FC519;
  top: -12px;
  left: 1px;
  position: relative;
}

.plus-sign:hover {
  color: #1f6b33;
}

// TODO - MUDAR CASO NECESSARIO
.form-user-checkbox {
  .ant-form-item-control {
    border-bottom: 1px solid #d9d9d9;
    padding: 4px 4px 4px 15px;
    height: 40px;
  }
}

.form-without-margin-last-form-item {
  .ant-form-item:last-child {
    margin-bottom: 0 !important;
  }
}

.table-form-user-data {
  thead {
    background-color: #2e74b5;

    th {
      color: white;
      text-align: center;
      font-size: 11px;
      border: 1px solid black;
      border-collapse: collapse;
    }
  }

  table,
  td {
    font-size: 11px;
    color: black;
    border: 1px solid black;
    border-collapse: collapse;
  }
}