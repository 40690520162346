.display-errors-modal {
  &__see-errors {
    cursor: pointer;
  }

  &__title {
    font-weight: bold;
    color: @primary-color;
  }

}