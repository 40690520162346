.search {
  width: ~"max(300px, 100%)";
  @media (max-width: 784px) {
    width: ~"max(150px, 100%)";
  }
}

.search-with-tool-tip {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.tooltip-custom {
  display: flex;
  gap: 5px;
  align-items: center;
}