
.loading-bar {

  .ant-progress-inner {
    height: 2.3rem!important;
    align-items: center;
    display: flex;
    overflow: visible;
    border-radius: 6px!important;
  }
  
  .ant-progress-bg {
    height: 2.3rem!important;
    border-radius: 20px!important; 
    background-size: 200% 200% !important;
    border-radius: 6px!important;
    animation: AnimateBG 7s ease infinite;
  }
  
  .ant-progress-text {
    position: absolute;
  }

  @keyframes AnimateBG {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  

}
