.proposal-filter {
  &__rerequest {
    align-self: flex-end !important;

    @media @tab-lg {
      align-self: center !important;
    }
  }

  &__created-date {
    .ant-form-item-label {
      padding: 0.1rem!important;
    }
  }
}