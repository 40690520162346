.find-user-information {
  background-color: @light-gray;
  border-radius: 20px;
  width: 100%;

  &__title {
    color: @primary-color;
    font-weight: bold;
  }

  &__item-title {
    font-weight: bold;
  }

  &__panel {
    border-bottom: none !important;
  }
}