.communication-unico-modal {
  min-width: 904px;

  @media @phone-sm {
    min-width: 100%;
  }

  .ant-modal-body {
    padding: 12px;
  }

  &__img {
    max-width: 100%;
    border-radius: @border-radius-base;
  }

  &__title {
    margin-bottom: 0;
    color: @primary-color;
  }
}
