@max-width-card: 600px;

.find-user {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__card {
    background-color: #fff;
    border-radius: @border-radius-base;
    padding: 2rem;

    width: 100%;
    max-width: @max-width-card;
    margin: 0 auto 1rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__title {
    font-weight: 600;
    color: var(--primary-color);
    text-align: center;
    
    margin-bottom: 0.5rem;
  }

  &__description {
    text-align: center;
    margin-bottom: 1.5rem;
  }

  &__image {
    margin-bottom: 1.5rem;
  }

  &__input {
    width: ~"min(335px, 100%)";
    button {
      border-radius: 0px 20px 20px 0px !important;
    }
  }

  &__button {
    width: 100%;
    max-width: @max-width-card;
  }

  &__client-info-container {
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: @light-gray-1;
    width: 100%;
    margin-bottom: 1rem;
    
    display: flex;
    flex-direction: column;
  }
  
  &__client-info-title {
    font-size: 1.25rem;
    color: var(--primary-color);
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  &__client-info-details {
    display: flex;
    flex-direction: column;
  }
}