.alienating-card {
  border-radius: 5px;
  border: 2px solid @credit-color  !important;
}

.alienating-title {
  background: @credit-color;
  text-align: center;
  color: white;
}
