.swal-error {
  width: 100%;
  text-align: left;
  overflow: auto;
  max-height: ~"max(30vh, 200px)" ;
  padding: 0 1.6em;
}

.swal-error-list {
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;

  list-style: none;
  text-align: start;

  font-size: 0.9rem;

  @media @phone-sm {
    max-height: 60vh;
  }

  li {
    margin: 10px 0;
    padding: 7.5px;
    border-radius: 10px;
    background-color: @light-gray;
  }
}

.swal-error-wrapper {
  margin-top: 1em;
  position: relative;
}

.swal-error-label {
  font-weight: 600;
  color: var(--primary-color);
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
}

.swal-error-checkbox {
  display: none;
  user-select: none;
}

.swal-error-label {
  text-decoration: underline;
  margin: auto;
}

.swal-error-copy {
  cursor: pointer;
  position: absolute;
  right: calc(7px + .5em);
  top: .5em;
  img {
    width: 1em;
  }
}

.swal-error-checkbox:not(.swal-error-checkbox:checked) ~ .swal-error-wrapper  {
  display: none;
}

.swal-error-checkbox:checked ~ .swal-error-label-on {
  display: none;
}

.swal-error-checkbox:not(.swal-error-checkbox:checked) ~ .swal-error-label-off {
  display: none;
}

.swal-error-footer {
  border: 0;
  font-size: .8em;
  padding: 0;
  flex-direction: column;
  margin-top: .5em;

  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
