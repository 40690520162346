@import './FormContractSteps/MinutaStep/MinutaPreviewStep';

.space-between {
  display: flex;
  justify-content: space-between !important;
}

.form-contract {
  &-none {
    display: none !important;
  }

  &-clausulas {
    display: flex;
    align-items: center;
    margin-bottom: 1em;

    span {
      margin-right: 5px;
    }

    p {
      margin: 0;
    }
  }

  &__skeleton {
    width: 100% !important;
    margin-bottom: 5px;
  }

  &__collapse {
    &-active {
      background-color: @tertiary-color;
      padding: 2px 5px;
      border-radius: 4px;
    }

    &-desactive {
      background-color: @refused-status-color;
      padding: 2px 5px;
      border-radius: 4px;
      color: white;
    }
  }
}
