.user-details {
  &__lgpd-modal {
    height: 80%;
    overflow: auto;
    padding-bottom: 0;
  }

  &__info-skeleton {
    width: 80%;
    height: 400px;
    align-items: center;
    background-color: @skeleton-background;
    border-radius: 15px;
  }

  &__perfil {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__perfil-img-container {
    .flex-full-center();
    width: 250px;
    border-radius: 50%;
    overflow: hidden;
    height: 250px;
    background-color: @skeleton-background;
    font-size: 40px;
    box-shadow: 3px 3px 10px 2px rgb(109, 109, 109);
  }

  &__products-tags {
    display: flex;
    align-items: center;
  }

  &__products-tag {
    align-self: center;
  }

  &__perfil-name-container {
    background-color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    size: border-box;
    color: @white;
    padding: 10px 60px;
    border-radius: 15px;
    box-shadow: 3px 3px 10px 2px rgb(109, 109, 109);
    transform: translateY(-50%);

    @media @phoneScreen {
      padding: 10px 40px;
    }
  }

  &__perfil-name {
    font-weight: bold;
    font-size: 1.2em;
    text-align: center;

    @media @phoneScreen {
      font-size: 1em;
    }
  }

  &__perfil-team-admim-container {
    display: flex;
    justify-content: space-around;
    width: 400px;

    @media @phoneScreen {
      flex-direction: column;
      align-items: center;
      width: 50%;
    }
  }

  &__perfil-team-admim-container-loading {
    background-color: @skeleton-background;
    border-radius: 15px;
    height: 40px;
  }

  &__perfil-team-adm {
    color: var(--primary-color);
    font-size: 1.2em;
    font-weight: bold;

    @media @phoneScreen {
      font-size: 1em;
    }
  }

  &__info {
    display: flex;
    justify-content: center;
    margin-bottom: 4em;
  }

  &__info-tabs {
    width: 80%;
  }

  &__info-tabs-header {
    color: var(--primary-color);
    font-weight: bold;
    font-size: 1.2em;
  }

  &__info-tabs-content {
    display: flex;
    width: 100%;
    border-bottom: solid 1px var(--primary-color);

    @media @phoneScreen {
      flex-direction: column;
    }
  }

  &__info-tabs-label {
    display: inline-block;
    width: 50%;
    font-size: 1.2em;
    color: var(--primary-color);

    @media @phoneScreen {
      display: block;
      width: 100%;
    }
  }

  &__info-tabs-information {
    color: var(--primary-color);
    font-size: 1.2em;
    width: 50%;

    @media @phoneScreen {
      font-size: 1em;
      width: 100%;
    }
  }

  &__tables {
    display:  flex;
    justify-content: center;
    width: 100%;
  }

  &__table-tabs {
    width: 80%;
  }

  &__perfil-switch-loading {
    width: 100px;
    height: 30px;
    background-color: @skeleton-background;
    border-radius: 15px;
  }

  &__perfil-cancel-button {
    background-color: @cancel-button-color !important;
    color: white !important;
    height: 24px !important;
    line-height: 0 !important;
    margin-bottom: 15px;

    &:focus,
    &:hover {
      border: 1px solid @cancel-button-color !important;
    }
  }
}

@import './components/UserDetailsPermissionsList/UserDetailsPermissionsList';