@import '../../components/RequestListFilters/RequestListFilters';
@import '../../components/TotalsCard/TotalsCard';
@import '../LogsOnidataDetails/LogsOnidataDetails.less';

.log-list {

  & .ant-radio-button-wrapper {
    background: none !important;
    overflow: hidden;
  }

  & .ant-radio-button-checked {
    background-color: @primary-color;
  }
  &__table {
    font-family: monospace;

  }
  &__small {
    font-size: 0.8rem;

  }
  &__formatted {
    max-width: 15rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

  }


  &__column {
    align-items:center;
    justify-content:center;
    display: flex;
    width: 100%;
  }

  &__average-container {
    display: flex;
    gap: 20px;
    height: 100%;
    border: 1px solid black;
    border-radius: 15px;
    align-items: center;
    justify-content: space-around;
    padding: .5rem;
  }

  &__average-data {
    align-items: center;
  }

  &__average-data-title {
    @media @phone-sm {
      font-size: .7rem;
    }
  }
}