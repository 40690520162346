.review_form{
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100% !important;

  &__infos {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;

    &__item {
      color: @primary-color;
      font-weight: bold;
    }
  }

  &__inputs {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3rem;
    width: 100%;

    &__item {
      margin-right: 10px;
      width: 100%;
    }
  }
  
  &__reason-placement {
    display: flex !important;
    flex-direction: row;
    width: 100% !important;
    overflow-wrap: break-word;
  }

  &__reason {
    width: 100% !important;
    margin-top: 0.5rem;
    margin-left: 5px;
    overflow-wrap: break-word;
  }
  
  &__label {
    color: @primary-color;
    font-weight: bold;
  }

  &__button {
    margin-top: 2rem;
  }

}