.step-lead {
  padding: 4px;
  border-radius: 2px;
  height: 20px;
  font-size: 14px;
  color: #000;
  background: rgba(14, 192, 7, 0.2);
}

.success-lead {
  border: 1px solid @tertiary-color;
  background-color: @tertiary-color;
  border-radius: 2px;
  padding: 5px;
}

.lead-history-item-tail {
  .ant-timeline-item-tail {
    border-left: 2px solid #cccccc;
  }

  .ant-timeline-item-content .timeline-commentary-item,
  .ant-timeline-item-content .timeline-message-item {
    overflow: auto;
  }

  .ant-timeline-item-left .ant-timeline-item-content .timeline-commentary-item,
  .ant-timeline-item-left .ant-timeline-item-content .timeline-message-item {
    border-left: 2px solid;
    padding: 20px;
  }

  .ant-timeline-item-right .ant-timeline-item-content .timeline-commentary-item,
  .ant-timeline-item-right .ant-timeline-item-content .timeline-message-item {
    border-right: 2px solid;
    padding: 20px;
  }

  .ant-timeline-item-last .step-review__timeline-item {
    padding: 0;
  }
}

.leads-drawer-search {
  input {
    height: 40px !important;
  }
}

.left-border-message {
  border-left: 1px solid red !important;
}

.rigth-border-message {
  border-right: 1px solid blue !important;
}
