.proposal-antecipation {
    display: grid;
    
    padding: 2rem;
    grid-template-columns: 2fr 8fr;
    &__icon {
      font-size: 5rem;
      color: @primary-color;
    }
    &__title h3{
      color: @primary-color;
      font-size: 1.5rem;
      font-weight: bold;
    }
    &__section-title {
      font-weight: bold;
      font-size: 1rem;
      color: @primary-color
    }
  }
  @media @tab-md {
    .proposal-antecipation {
      overflow: scroll;
      grid-template-columns: 1fr;
      &__title h3 {
        padding: 1rem 0;
        text-align: center;
      }

    
    }

  }