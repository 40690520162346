.proposal-line-widget {
  width: 100%;

  @media @phone-sm {
    width: auto;
  }

  &--history {
    max-width: 986px;
    margin-left: auto;
    width: 100%;

    @media @phone-sm {
      gap: 0;
    }
  }

  &__content {
    border: 1px solid #d9d9d9;
    border-radius: 20px;

    @media @phone-sm {
      border-right: none;
      border-left: none;
      border-radius: 0px;
    }
  }

  &__history-header {
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
    justify-content: flex-end;

    @media @phone-sm {
      flex-wrap: wrap-reverse;
      padding: 0 1em;
    }

    &--only-buttons {
      justify-content: flex-end;
    }
  }

  &__title {
    font-size: 1.5rem;
    font-weight: 600;
    padding: 11px;
    color: @primary-color;
    border-bottom: 1px solid @primary-color;;

    @media @tab-lg {
      font-size: 1.2rem;
    }
  }

  &__subtitle {
    font-size: 1rem;
    margin: 0;
  }

  &__buttons-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
  }

  &__history-button {
    margin-left: auto;
    border-color: var(--primary-color);
    color: var(--primary-color);
    font-weight: 600;
    display: flex;
    align-items: center;
    min-width: 215px;
    justify-content: space-between;

    span {
      line-height: 0;
    }
  }

  &__history-button-icon {
    font-size: 1.2rem;
  }
}
