@import './components/SignupButton/SignupButton';
@import './components/ConfirmationEmailModal/ConfirmationEmailModal';
@import './components/PreSignup/PreSignup';

.signup {
  &__title {
    color: @primary-color;
    font-weight: bold;
    font-size: 1.6rem;
    margin-bottom: 1.8rem;

    @media @phone-sm {
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }
  }

  &__sub-title {
    color: @primary-color;
    font-weight: bold;
    font-size: 1.4rem;
    margin-bottom: 1.2rem;

    @media @phone-sm {
      font-size: 1rem;
      margin-bottom: 0.8rem;
    }
  }

  &__onidata-help {
    display: flex;
    align-items: flex-start;
  }

  &__warning-first-acess-corban {
    background-color: @light-gray;
    padding: 1rem;
    border-radius: 20px;
  }

  &__onidata-icon {
    margin-top: .5rem;
    margin-right: .5rem;
    font-size: 26px;
    color: @primary-color;
    opacity: .6;

    @media @phone-sm {
      display: none;
    }
  }

  &__link {
    text-decoration: underline !important;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }

  &__already-have-account {
    font-size: 18px;
    line-height: 21.6px;
  }
}
