.onidata-credentials {
  &__form-container {
    background: @white;
    border-radius: 20px;
    display: flex;
    flex: 1 1 1;
    flex-direction: row;
    gap: 1.5rem;
    margin: 0 auto;
    margin-bottom: 1rem;
    padding: 1.5rem;
    
    .ant-card-body {
      width: 100%;
    }

    @media @tab-md {
      flex-direction: column;
    }
  }

  &__card {
    margin-bottom: 2rem;

    @media @tab-md {
      margin-bottom: 1rem;
    }
  }

  &__title {
    font-size: 1.4rem;

    @media @tab-md {
      font-size: 1.2rem;
    }
  }

  &__icon-text-container {
    display: flex;
    align-items: flex-start;

    gap: 8rem;

    @media @tab-md {
      display: block;

      gap: 1rem;
    }
  }

  &__icon {
    flex: 1;
    margin-top: 1.5rem;

    @media @tab-md {
      margin-top: 0rem;
    }
  }

  &__text-form-container {
    flex: 0 0 60%
  }

  &__text-container {
    margin-bottom: 2rem;
  }

  &__form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;
    justify-content: center;
  }

  &__filter {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.75rem;
    width: 100%;
  }

  &__help-text {
    align-items: flex-start;
    display: flex;
    gap: 10px;
    max-width: 50%;

    @media @tab-md {
      max-width: unset;

      svg {
        display: none;
      }
    }
  }
}
