.buttons-step {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;

  &--first {
    justify-content: flex-end;
  }

  &__button-back {
    background-color: transparent;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    padding: 0 2rem 0;

    &:hover,
    &:active,
    &:focus {
      color: white;
    }
  }
}