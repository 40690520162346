.client-proposal-list {
  &__card {
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    margin-bottom: 1rem;
    cursor: pointer;

  }

  &__side {
    background-color: @primary-color;
    border-radius: 10px 0 0 10px;
    color: #fff;
    width: 6rem;
    padding: 1.5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    display: flex;
    flex-grow: 4;
    justify-content: center;
    flex-direction: column;
    margin: 1rem;
  }

  &__title {
    color: @primary-color;
    font-weight: bold;

  }

  &__title-list {
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__results {
    display: flex;
    align-items: center;
    display: flex;
    justify-content: space-between;

  }

  &__icon {
    .flex-full-center();

    padding: 1rem;
  }

  &__new {
    .flex-full-center();
    text-align: center;
    font-size: 1.3rem;
  }

  &__new img {
    width: 30%;
    padding: 0.5rem;
  }



  &__skeleton {
    display: flex;
    padding: 0 2rem;
    justify-content: center;
    align-items: center;
  }

}

@media @tab-lg {
  .client-proposal-list {
    &__card {
      flex-direction: column;
    }

    &__side {
      width: 100%;
      height: 1rem;
      border-radius: 10px 10px 0 0;
    }

    &__content {
      flex-direction: row;
      margin-bottom: 0;
    }

    &__titleSimulation {
      font-size: 1.2rem;
    }

    &__titleList {
      text-align: right;
      padding-right: 1rem;

    }

    &__new {
      flex-direction: column;
    }

    &__new img {
      width: 40%;
    }

    &__icon {
      padding: 0;
      padding: 1rem;
    }

    &__skeleton {

      padding: 2rem;

    }
  }
}