#root .proposals-details-department {
  &__progress-bar-wrapper {
    display: flex;
    gap: 35px;

    @media @tab-lg {
      display: block;
      gap: 20px;
    }

    @media @phone-sm {
      padding: 0 1em;
    }
  }

  &__progress-bar-text {
    color: @primary-color;
    font-weight: 500;
  }

  &__progress-bar {
    max-width: 320px;
  }

  &__steps-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
