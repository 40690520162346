.grecaptcha-modal {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  padding: 1rem;
  text-align: center;

  &__img {
    height: 80px;
  }

  &__title {
    font-size: 1.2rem;
    font-weight: bold;
  }

  &__description {
    font-size: .9rem;
  }
}

.g-recaptcha-bubble-arrow + div {
  position: fixed !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
