@import './Skeleton/PartnerDeparmentsSkeleton.less';

#root .department-info {
  &__title {
    color: @primary-color;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.6rem;
    margin-bottom: 0;
    line-height: 30px;
  }

  &__divider {
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 20px;
    border-width: 2px;
    background-color: @divider-line-color;
  }

  &__progress-bar-wrapper {
    display: flex;
    gap: 35px;

    @media @tab-lg {
      display: block;
      gap: 20px;
    }
  }

  &__progress-bar-text {
    color: @primary-color;
    font-weight: 500;
    font-style: normal;
    line-height: 22px;
    font-size: 18px;
    margin-bottom: 0;
  }

  &__progress-bar {
    max-width: 320px;
  }

  &__steps-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
