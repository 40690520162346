.proposal-step-button {
  display: flex;
  align-items: center;

  &__button {
    display: flex;
    align-items: center;
    background: transparent;
    outline: none;
    border-radius: @border-radius-base;
    border: none;

    &--enabled {
      cursor: pointer;

      &:focus-visible {
        outline: 2px solid @analysis-status-color;
        outline-offset: -2px;
      }

      &:hover>p {
        background-color: darken(#fff, 5%);
        display: flex;
      }
    }
  }

  &__icon {
    margin: 0;
    display: flex;
    position: relative;
    z-index: 1;

    svg {
      width: 32px;
      background-color: #fff;
      height: 32px;

      @media @tab-lg {
        width: 28px;
        height: 28px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      background-color: @step-button-bg;

      z-index: 0;
      left: 50%;
      top: 100%;

      width: 1px;
      height: 3rem;

      @media @tab-lg {
        height: 12px;
      }
    }
  }

  &__name {
    color: @primary-color;
    font-size: 1rem;
    font-weight: 400;
    border-radius: @border-radius-base;

    padding: 0.3rem;
    padding-left: 0.5rem;

    @media @tab-lg {
      padding: 0.2rem;
    }

    @media @tab-lg {
      font-size: 0.8rem;
    }
  }

  &__asterisk {
    color: var(--red-status);
    font-weight: bold;
    font-size: 1rem;
  }
}