.multi-select-modal {
  &__input {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }

  &__info {
    align-items: center;
    display: flex;
    justify-content: space-between;

    margin: 1.5rem 0 0.5rem;

    @media @tab-md {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
  }

  &__quantity {
    cursor: pointer;
    font-size: 0.9rem;
    padding: 0.2rem 1rem;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    border-radius: 100px;

    &:hover {
      background-color: var(--primary-color);
      color: var(--master-text-color);
    }
  }

  &__search-options {
    font-size: 0.82rem;
    border-radius: 5px;

    .ant-checkbox .ant-checkbox-inner {
      border-radius: 5px;
    }
  }

  &__options-container {
    background-color: @multi-select-background;
    border-radius: 0.65rem;

    margin-bottom: 1.5rem;

    height: 350px;
    padding: 0 0.5rem;
  }

  &__first-option {
    border-bottom: 1px solid @border-divisor;
    font-size: 0.82rem;

    padding: 1rem 0rem 0.6rem 0.8rem;
    width: 100%;

    .ant-checkbox .ant-checkbox-inner {
      border-radius: 5px;
    }
  }

  &__checkbox-group {
    column-gap: 0.5rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));

    max-height: 300px;
    overflow-y: scroll;

    padding: 0.5rem 0 1rem;

    &--option .ant-checkbox .ant-checkbox-inner {
      border-radius: 5px;
    }

    &--option .ant-checkbox-wrapper {
      font-size: 0.82rem;
      padding: 0.5rem 0;
      padding-left: 0.8rem;
      margin-left: 0;
      align-items: center;

      @media @tab-md {
        grid-column-end: span 2;
      }

      &:hover {
        background-color: @multi-select-background-hover;
      }
    }

    .ant-checkbox {
      top: .6px;
    }

    &--option .ant-checkbox-wrapper>span:last-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
  }

  &__empty {
    display: flex;
    width: 100%;
    height: 250px;
    justify-content: center;
    align-items: center;
    grid-column-end: span 2;

  }
}