.back-office-details {

  &__header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  
    @media @tab-xl {
      flex-direction: column;
      margin-bottom: 10px;
    }
  }
  
  &__header-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  
    @media @tab-xl {
      width: 80%;
    }
  
    @media @phone-sm {
      width: 65%;
    }
  }
  
  &__header-info-container {
  
    @media @tab-xl {
      flex-direction: row;
      align-items: center;
      max-width: 100%;
      margin-bottom: 10px;
    }
  
    @media @phone-sm {
      flex-direction: column;
      width: 100%;
      align-items: center;
    }
  }
  
  &__initial-container {
    margin-right: 20px;
    align-items: center;
  
    @media @phone-sm {
      margin-right: 0 !important;
      margin-bottom: 10px !important;
    }
  }
  
  &__header-name-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  
    @media @tab-xl {
      flex-direction: column;
      align-items: flex-start;
    }
  
    @media @phone-sm {
      align-items: center;
    }
  }
  
  &__header-name {
    color: @primary-color;
    font-size: 1.5rem;
    font-weight: bold;
    margin-right: 20px;
    overflow: hidden;
    display: inline-block;
    word-break: break-all;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  
    @media @tab-xl {
      margin-right: 10px;
      margin-bottom: 5px !important;
    }
  
    @media @phone-sm {
      margin-right: 0px !important;
      font-size: 1.2rem;
      text-align: center;
    }
  }
  
  &__header-tags {
    display: flex;
    flex-direction: row;
    gap: 10px;
    white-space: nowrap;
  
    @media @tab-xl {
      margin-bottom: 10px !important;
    }
  }
  
  &__header-id {
    margin-bottom: 10px;
  
    @media @phone-sm {
      text-align: center;
    }
  }

  &__header-buttons {
    justify-content: end;
    align-items: center;
    height: 100%;
    gap: 10px;
    flex-wrap: wrap;

    @media @tab-xl {
      margin-bottom: 20px;
      justify-content: center;
    }

    @media @phone-sm {
      flex-direction: column;
    }
  }

  &__button {
    @media @tab-xl {
      width: 100%;
    }
  }
}