.accept-contract-modal {
  &__title {
    font-size: 1.2rem;
  }

  &__decline-icon {
    font-size: 70px;
    color: @refused-status-color;
  }

  &__back-button {
    @media @phone-sm {
      margin-bottom: 10px;
    }
  }

  &__decline-button {
    background-color: @refused-status-color;
    border-color: @refused-status-color !important;
    transition: all .2s ease-in-out;

    &:hover {
      background-color: transparent;
      color: @refused-status-color;
    }
  }
}
