.form-list-buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;

  &__add {
    background-color: transparent;
    color: var(--primary-color);

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      color: var(--primary-color);
    }
  }

  &__remove {
    background-color: #C73535;
    color: white;
    border: none;
    
    &:hover,
    &:active,
    &:focus {
      background-color: #C73535;
      color: white;
      border: none;
    }
  }
}
