.home-card-svg {
  width: 180px;
  height: 120px;
}

.title-home {
  font-size: 1.2rem;
  font-weight: 100;
  letter-spacing: 3px;
}

.padding-divisor {
  padding-top: 80px;
}

.label-user-level {
  @media (max-width: 768px) {
    display: none;
  }
}