.radio-group-square {
  .ant-radio-button-wrapper {
    background-color: @light-gray;
  }
  .ant-radio-button-wrapper {
    height: 40px;
    line-height: 38px;
    &:first-child {
      border-radius: 4px 0 0 4px;
    }
    &:last-child {
      border-radius: 0px 4px 4px 0px;
    }
  }
}