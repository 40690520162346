.debit-balance-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &--total {
      flex-direction: row;
      justify-content: space-between;
      padding: 1rem;
      background-color: #f0f0f0;
      border-radius: 15px;
    }
  }

  &__line {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    color: @gray;
    padding: 0 1rem;
  }

  &__label-total {
    font-size: 1rem;
  }

  &__value-total {
    font-size: 1rem;
    color: var(--red-status);
  }
}