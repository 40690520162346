.product-item-button {
  padding: 16px;
  text-align: start;
  position: relative;
  transition: all 0.4s;
  background-color: #fff;
  width: 100%;
  border: none;
  border-radius: 20px;
  outline: none;
  cursor: pointer;
  border: 1px solid @light-gray;

  &--secondary {
      border: 1px solid var(--primary-color);
    }

    &__text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 70%;
    }

    &--active,
  &:hover {
    background-color: @light-gray;
  }

  &--active&--secondary,
  &:hover&--secondary {
    background-color: var(--primary-color);
    color: @white;

    h5,
    svg,
    svg path {
      color: @white;
      fill: @white;
    }

    .ant-spin-dot-item {
      background-color: @white;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 16px;
    font-size: 30px;
    transform: translateY(-50%);

    > svg {
      color: @primary-color;
    }

    transform: translateY(-50%);
  }
}
