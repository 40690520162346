.details-item {
  width: 100%;
  height: 100%;
  padding: 15px 0 3px 0;
  border-bottom: 1px solid @light-gray-3;

  &__label {
    font-weight: bold;
    font-size: 0.75rem;
    color: @primary-color;
  }

  &__skeleton {

    .ant-skeleton-title {
      margin: 12px 0 5px 0;
      height: 18px;
    }

    .ant-skeleton-paragraph {
      display: none;
    }
  }

  &__value {
    word-break: break-all;
    padding-top: 10px;
    font-size: 1rem;
    color: @primary-color;

    @media @phone-sm {
      font-size: 1rem;
    }
  }
}

.details-item-section-title {
  font-weight: bold;
  font-size: 1rem;
  color: @primary-color
}