.find-user-link {
  display: flex;
  gap: 1em;
  padding: 1em;

  .anticon {
    display: flex;
    align-items: center;
  }
}
