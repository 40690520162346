#root .filter-type-search {
  display: flex;

  .ant-dropdown-menu-item-selected {
    border-radius: 20px;
  }

  .ant-dropdown-menu-item:hover {
    border-radius: 20px;
  }

  &__input {
    border-left: none;
    border-radius: 0 20px 20px 0 !important;
    padding-left: 0;
    width: 300px !important;

    @media @tab-lg {
      width: 100% !important;
    }

    box-shadow: none !important;
    opacity: 1 !important;

    .ant-btn.ant-input-search-button {
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }

    .ant-input-wrapper.ant-input-group .ant-input-group-addon:first-child {
      border-radius: 25px !important;
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }

    .ant-checkbox + span {
      padding-right: 0;
    }
  }

  &__disabled {
    cursor: not-allowed;
    background-color: @disabled-color !important;
    border: none !important
  }

}
