.parcel-antecipation-list {
  &__header {
    display: flex;
    justify-content: space-between;

    &--centered {
      align-items: center;
    }

    @media @tab-md {
      .flex-full-center();
      flex-direction: column;
    }
  }

  &__label {
    display: block;
    font-size: 1.1rem;

    @media @tab-md {
      display: flex;
      font-size: 1rem;
      text-align: center;
      margin-bottom: 1rem;
    }
  }

  &__title {
    color: @primary-color;
    font-weight: bold;
  }

  &__scroll{
    display: flex;
    width: min-content;
    align-items: center;
  }
  &__button{
    @media @tab-md {
      margin-bottom: 2rem;
    }
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
  }

  &__skeleton-item {
    padding: 1rem 0;
    border-bottom: 1px solid rgba(190,190,190,.2);
  }

  &__table-title-item {
    color: @primary-color;
    white-space: nowrap;
    font-weight: bold;

    &--right {
      text-align: right;
    }
  }

  &__status-item {
    display: flex;

    @media @tab-md {
      padding: 2rem;
      .flex-full-center();

    }
  }

  &__status {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 1rem;

  }

  &__table-row-item {
    padding: 1rem 0;
    border-bottom: 1px solid @primary-color;
  }

  &__table {
   overflow-x: scroll;
   min-width: 100%;

   @media @tab-md {
    min-width: 130%;

  }


    th, td {

      padding: 1rem 0;
      @media @tab-md {
        padding: 1rem 0.5rem;

      }
    }
  }

  &__table-row {

    border-bottom: 1px solid @primary-color;
  }

  &__value-item {
    text-align: right;
  }


}
