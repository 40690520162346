.proposal-bill {
  padding: 2rem;
  display: grid;
  grid-template-columns: 2fr 8fr;

  &__icon {
    font-size: 5rem;
    color: @primary-color;
  }

  &__title h3 {
    color: @primary-color;
    font-size: 1.5rem;
    // border-bottom: 1px solid @primary-color;
    font-weight: bold;
  }

  &__table {
    width: 100%;
  }

  &__row {

    &:not(:first-child) {
      border-bottom: 1px solid @primary-color;
      padding: 1rem 0;
    }

    display: grid;
    grid-template-columns: 8fr 4fr 4fr 4fr;

    th {
      color: @primary-color;
    }

  }

  &__empty {
    flex-direction: column;
    .flex-full-center();
  }

  &__exclamation-icon {
    padding-bottom: 0.5rem;
    font-size: 1.5rem;
    color: @primary-color;
  }


}

@media @tab-md {
  .proposal-bill {
    overflow: scroll;
    grid-template-columns: 1fr;

    &__title h3 {
      padding: 1rem 0;
      text-align: center;
    }

    &__table {
      overflow: scroll !important;
      margin-left: 1rem;

    }

    &__row {
      white-space: nowrap;
      grid-gap: 1rem;
    }
  }



}