.open-areas-card {
  background-color: lighten(@primary-color, 5%);
  color: #fff;
  border-radius: 4px;
  user-select: none;
  text-align: center;
}

.add-linked-button {
  height: 0px !important;
}

.skeleton-backoffices {
  width: 400px !important;
  height: 20px;

  @media (max-width: 500px) {
    width: 200px !important;
  }
}