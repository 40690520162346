.simulation-card-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.simulation-card-header-title {
  display: flex;
  flex-direction: row;

  @media @tab-md {
    flex-direction: column;
  }
}

.simulation-card-header-expiration-date {
  display: flex;
  flex-direction: row;
}

.proposal-flag {
  padding: 1px 2px;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 1px;
  margin-right: 5px;
  margin-bottom: 0px;
  color: #fff;

  &--crivo {
    min-width: 120px;
    text-align: center;
  }

  &--signature {
    min-width: 80px;
    max-width: auto;
    border: 1px solid #00CF7B;
    background-color: #00CF7B;
  }

  &--exclusive-tax {
    margin-left: 10px;
    border: 1px solid #FF7A40;
    background-color: #FF7A40;
    height: 28px;
    
    @media (max-width: 476px) {
      margin-left: 10px;
    }
    
    @media (max-width: 375px) {
      font-size: 12px;
      height: 22px;
    }
    
    @media (max-width: 320px) {
      display: block;
      flex-wrap: wrap;
      width: 55px !important;
      height: 35px;
      font-size: 10px;
    }
  }
  
  &--exclusive-revised-tax {
    margin-left: 10px;
    border: 1px solid #00CF7B;
    background-color: #00CF7B;
    height: 28px;
    
    @media (max-width: 476px) {
      margin-left: 10px;
    }
    
    @media (max-width: 375px) {
      font-size: 12px;
      height: 22px;
    }
    
    @media (max-width: 320px) {
      display: block;
      flex-wrap: wrap;
      width: 55px !important;
      height: 35px;
      font-size: 10px;
    }

  }
}

.cnd-tag {
  @media (max-width: 425px) {
    white-space: break-spaces !important;
  }
}

.appendice-size {
  margin-top: -5px;
  width: 2px;
  background-color: #818181;
  height: 15px !important;
  @media (max-width: 991px) {
    height: calc(100% - 35px) !important;
  }
  margin-left: 12px !important;
}

.responsive-cols-step {
  display: flex;
  @media (max-width: 991px) {
    width: 100%;
    display: block;
  }
}

.card-simulation{
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 999px) {
    border: 0 !important;
    flex-direction: column;
  }
}

.loading-simulation-card {
  width: 800px;

  @media screen and (max-width: 880px) {
    width: 550px
  }

  @media screen and (max-width: 635px) {
    width: 320px
  }

  @media screen and (max-width: 395px) {
    width: 210px
  }

}

.card-form-commission {
  border: 1px solid @primary-color;
  border-radius: 10px;

  form:first-of-type {

    div:last-child {
      margin-bottom: 0;
    }
  }
}

.simulation-card-review-modal {
  width: 100%;

  &__img {
    .flex-full-center();
    flex-direction: row;

    img {
      width: 5rem;
      height: 5rem;
    }
  }

  &__label {
    font-size: 1rem;
    font-weight: bold;
  }
}

.simulation-card-icons {
  margin-left: 10px;
  cursor: pointer;
  width: 35px;

  @media @tab-md {
    margin-left: unset;
  }
}
