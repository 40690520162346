@import './components/SignupCheckbox/SignupCheckbox.less';

#root .signup-layout {
  &__header {
    display: flex;
    align-items: center;

    background-color: #E0E5CF;
    height: 60px;

    & div {
      padding: 0 30px;
      margin-left: 20px;
    }

    @media (max-width: 1024px) {
      justify-content: center;

      & div {
        padding: 0;
        margin-left: 0;
      }
    }
  }

  &__logo {
    width: 180px;
    margin-top: 5px;
    object-fit: contain;
    user-select: none;
  }

  &__container {
    padding: 2rem 0;
    max-width: clamp(28rem, 28%, 28%);
    margin: auto;

    @media @tab-md {
      padding: 1rem 1rem;
    }
  }
}
