.navbar-select-level {
  display: flex;
  flex-direction: column;

  &__button {
    padding: 15px;
    text-align: start;
    position: relative;
    transition: all 0.4s ease-in-out;
    background-color: @white;
    width: 100%;
    border: none;
    border-radius: 20px;
    outline: none;
    cursor: pointer;
    min-width: 200px;
    display: flex;
    align-items: center;

    &:hover {
      background-color: @light-gray;
    }

    &--selected {
      background-color: @light-gray;
    }

    & svg {
      width: 18px;
      height: 18px;
    }
  }

  &__display-user-level {
    display: flex;
    flex-direction: column;
  }

  &__user-level-name {
    font-weight: 600;
  }

  &__user-covenant-name {
    font-size: 0.8rem;
    padding-right: 3rem;
    word-break: break-word;
  }

  &__collapsable-icon {
    transition: all .2s ease-out;
    transform: rotate(-180deg);
    display: flex;
    align-items: center;

    &--collapsed {
      transform: rotate(0deg);
    }
  }

  &__possible-levels-container {
    display: flex;
    flex-direction: column;
    max-height: 50vh;
    overflow: auto;
    transition: all .2s ease-out;

    &--collapsed {
      display: none;
    }
  }
}