.simulation-validate-token {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;
  
    &__title {
      color: var(--primary-color);
      font-weight: bold;
      margin: 0;
    }
  
    &__header {
      padding-bottom: 2rem ;
    }
  
    &__container {
      justify-content: center ;
      display: flex;
    }
  
    &__content {
      width: 500px;
    }
  
    &__svg svg {
      max-width: 200px;
      height: auto;
      padding-bottom: 2rem;
    }
  
    &__icon {
      margin: auto;
      max-width: 90%;
    }
  
    &__button-group {
      display: flex;
      flex-direction: column;
      gap: 1em;
      width: 100%;
  
      > * {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
      }
    }
  
    &__secondary-button {
      background: transparent!important;
      border-color: var(--primary-color);
      color: var(--primary-color);
  
      &:hover, &:focus-visible {
        filter: opacity(.75)
      }
    }
  
    &__form {
    display: flex;
    flex-direction: column;
    }
  
    &__button {
    margin-left: auto;
    }
  
    &__bottom {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__method-display {
      background: @light-gray;
      border-radius: 20px;
      padding: 1rem;
      color: @primary-color;
      font-weight: 700;
    }
  }
  
  