.server-table-filter {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: @filters-space;

  @media @phone-sm {
    flex-direction: row!important;
  }

  @media @tab-lg {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;

    & > * {
      width: 100%;
    }

    .search-with-tool-tip {
      justify-content: center;
      flex-direction: column;
      width: 100%;
    }

    .drawer-filters {
      width: 100%;
    }

    button {
      width: 100%;
    }
  }
}
