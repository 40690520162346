.resendLink-title {
  font-size: 16px;
}

.share-button {
  height: auto !important;

  .ant-btn {
    height: auto !important;
  }

  &--small {
    height: 28px !important;
    font-size: 11.5px;
  }
}

.share-steps-button {
  margin-right: 5px;
  font-size: 12.5px !important;
  max-height: 28px;
}