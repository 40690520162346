.proposal-due {

  padding: 2rem;
  display: grid;
  grid-template-columns: 2fr 8fr;

  &__icon {
    font-size: 5rem;
    color: @primary-color;
  }

  &__title h3 {
    color: @primary-color;
    font-size: 1.5rem;
    // border-bottom: 1px solid @primary-color;
    font-weight: bold;
  }

  &__section-title {
    font-weight: bold;
    font-size: 1rem;
    color: @primary-color
  }

  &__table {
    width: 100%;
  }

  &__row {
    display: grid;
    grid-template-columns: 12fr 4fr 4fr 4fr;

    &:not(:first-child) {
      border-bottom: 1px solid @primary-color;
      padding: 1rem 0;
    }

    th {
      color: @primary-color;
    }

  }

  &__empty {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__exclamation-icon {
    padding-bottom: 0.5rem;
    font-size: 1.5rem;
    color: @primary-color;
  }


}


@media @tab-md {
  .proposal-due {
    grid-template-columns: 1fr;

    overflow: scroll;

    &__title h3 {
      padding: 1rem 0;
      text-align: center;
    }

    &__table {
      overflow: scroll !important;
      margin-left: 1rem;
      width: 120%;
    }

    &__row {
      white-space: nowrap;
      grid-gap: 1rem;
    }

  }

}