.form-section-title {
  text-transform: uppercase;
  position: relative;
  display: inline-block;

  &--primary {
    font-size: 1.1rem;
    color: @primary-color !important;

    &::after {
      content: '';
      width: 45px;
      height: 3px;
      border-radius: 2px;
      position: absolute;
      bottom: -5px;
      left: 0;
      background-color: @secondary-color;
    }
  }

  &--secondary {
    font-size: .7rem;
    text-transform: none;
  }
}