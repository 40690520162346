@import '../../views/ProposalEmitter/ProposalEmitter';
@import '../../views/ProposalDemission/ProposalDemission';
@import '../../views/ProposalAntecipation/ProposalAntecipation';
@import '../../views/ProposalDebitBalance/ProposalDebitBalance';
@import '../../views/ProposalBill/ProposalBill';
@import '../../views/ProposalDue/ProposalDue';
@import '../../views/ProposalContract/ProposalContract';
@import '../../views/ProposalLine/ProposalLine';
@import '../../views/ProposalHistory/ProposalHistory';

.proposal-details {
  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  &__tabs {
    .ant-tabs-nav {
      @media @phone-sm {
        padding-inline: 1em;
      }
    }
  }

  &__captions {
    @media @phone-sm {
      margin-inline: auto;
    }
  }

  & &__card {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    padding: 2rem;
  }

  &__client-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1em;
    width: 100%;
  }

  &__client-data {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__client-name {
    font-size: 1.145rem;
  }

  &__client-buttons {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    width: ~'min(300px, 100%)';
    font-weight: 600;

    @media @phone-sm {
      margin: auto;
    }

    > * {
      flex: 1 0 0px;
    }
  }

  @media @phone-sm {
    .ecp-card {
      padding: 0;
      overflow: hidden;
    }
  }
}
