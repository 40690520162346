@import '../../components//ProposalConfigurationSideBarECPF/ProposalConfigurationSideBarECPF';
@import '../../components/ProposalConfigurationFieldsECPF/ProposalConfigurationFieldsECPF';

.proposal-configuration {
  height: 80vh;

  @media @tab-xl {
    flex-direction: column;
    height: 100%;
  }

  &__table {
    & .ant-table-thead th {
      background: transparent !important;
      color: @black !important;
      border-bottom: transparent !important;
    }

    & .ant-table-container {
      overflow: auto;
    }

    & .ant-table-content {
      min-width: 600px;
    }
  }

  &__add-button {
    border-color: @primary-color;
    color: @primary-color;

    &:hover {
      background-color: @primary-color;
      color: @white;
    }
  }
}