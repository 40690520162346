.proposal-report-modal {
  .ant-modal-content {
    border-radius: 20px !important;
  }

  &__title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 20px;
  }

  &__subtitle {
    font-size: 18px;
    margin-bottom: 0px;
  }

  &__text {
    font-size: 14px;
  }

  &__ul-list {
    list-style: none;
    padding-left: 10px;
  }

  &__button-content {
    display: flex;
    justify-content: flex-end;
  }

  &__month-filter {
    margin-bottom: 20px;
  }

  &__warning {
    display: flex;
    gap: 15px;
    margin-bottom: 20px;
  }

  &__icon-content {
    margin-top: 15px;
    svg {
      font-size: 25px;
    }
  }

  &__tip-body {
    background-color: @light-gray;
    padding: 10px;
    margin-bottom: 20px;
  }
}
