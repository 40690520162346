@import './components/ProductItem/ProductItem';
@import './components/ProductItemButton/ProductItemButton';
@import './components/ProductDropdown/ProductDropdown';

.select-products-sidebar {
  &__menu {
    width: 250px;
    padding: 15px;
    box-shadow: 7px 6px 5px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
  }

  &__loading-icon {
    font-size: 30px;
    .ant-spin-dot-item {
      background-color: white;
    }
  }

  @media @phone-xs {
    padding-left: 3rem;
  }
}