.simulation-amortization-tag {
  &::after {
    content: 'MAIS UTILIZADA';

    transform: translateX(-50%);
    border-radius: 10px;
    padding: 0 16px;
    line-height: 1rem;
    background-color: @secondary-color;
    color: @primary-color !important;
    font-size: 0.75rem !important;
    position: absolute;
    top: -7.5px;
    left: 50%;
    white-space: pre;

    @media (max-width: 768px) {
      display: none;
    }
  }
}

.form-slider-simulator .ant-form-item-label,
.amortizationType-radio .ant-form-item-label{
  text-align: center !important;
}

.simulation-info {
  cursor: pointer;
}

.simulator-deadline-increase {
  font-size: 47.5px;
  color: @primary-color !important;
}

.simulator-tooltip-paragraph{
  font-size: 14px;
  color: #3b3d43;

  &:last-child{
    margin-bottom: 0;
  }
}