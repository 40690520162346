html .responsive-modal {
  padding: 0;
  margin: 0;
  @media @tab-md {
    max-height: 100vh;
  }

  &--lg {
    width: 100%!important;
    max-width: 950px;

  }

  .ant-modal {
    width: 80vw!important;
    height: 80%;
    overflow: hidden;
    margin: 0;
    padding: 0;

    @media @tab-md {
      height: 100vh;
      min-width: 100vw;
      border-radius: 0;
    }
  }
  .ant-modal-title {
    font-size: 24px;
    text-transform: uppercase;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 95%;
  }

  .ant-modal-body {
    max-height: 80vh;
    overflow-y: auto;
  }

  .ant-modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 40;
    .ant-modal-body {
      overflow: auto;
      flex-grow: 1;
    }
  }
  .ant-modal-footer {
    @media @phone-sm {
      display: flex;
      flex-wrap: wrap;
      gap: 1em;
      & > * {
        margin: 0!important;
        flex-grow: 1;
      }
    }
  }
}

