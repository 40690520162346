html .rich-text-editor-inner {
  --font-family: "Montserrat", -apple-system, BlinkMacSystemFont, \'Segoe UI\', Roboto, \'Helvetica Neue\', Arial, \'Noto Sans\', sans-serif, \'Apple Color Emoji\', \'Segoe UI Emoji\', \'Segoe UI Symbol\', \'Noto Color Emoji\';
  --border-radius: 10px;

  .se-wrapper-inner {
    font-family: var(--font-family)
  }

  .se-placeholder {
    font-family: var(--font-family);
  }

  .se-toolbar {
    border-radius: var(--border-radius) var(--border-radius) 0 0;
  }

  .sun-editor {
    border-radius: var(--border-radius);
  }

  .se-resizing-bar {
    border-radius: 0 0 var(--border-radius) var(--border-radius);
  }

  &__skeleton span{
    min-height: 300px;
    width: 100% !important;
  }

  &__table td {
    border: 1px solid;
    min-width: 40px;
    width: 100%;
    padding: 5px;
  }
}