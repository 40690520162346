.ant-layout-sider {
  background: @white;
  color: @black;
}

.ant-layout-sider-trigger{
  position: relative;
  background:@white;
  color: @black;
}

.ant-layout-sider-children {
  position: absolute;
  margin-right: 5px;
  height: 100%;
  width: 100%;

  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 10px;
    margin-right: 10px;
  }

  ::-webkit-scrollbar-track {
    background: @white;
    height: 20px;
    border-radius:20px ;
  }

  ::-webkit-scrollbar-thumb {
    background: #c8c8c8;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #9e9e9e;
  }
}

.menu-sidebar {
  @media @phone-sm {
    transition: none;
  }
  .ant-menu-item,
  .ant-menu-submenu-title {
    &:active {
      background-color: rgba(0, 207, 123, .2) !important;
    }
  }

  .ant-menu-item-selected {
    color: @primary-color !important;
    background-color: rgba(0, 207, 123, .2) !important;

    &::after {
      border-right: 3px solid @tertiary-color !important;
    }
  }

  @media (max-width: 575px) {
    padding-top: 5px !important;
  }
}

.sidebar-size * {
  transition: none!important;
  animation: none!important;

  &::after{
    transition: none!important;
    animation: none!important;
  }

  &::before {
    transition: none!important;
    animation: none!important;
  }
}

.ant-layout-sider,
.ant-menu,
.ant-layout-sider-trigger {
  transition: all .3s !important;

  @media @phone-sm {
    transition: none !important;
  }
}



