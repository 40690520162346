@import './components/ProgressQueueCard/ProgressQueueCard';

.progress-queue {
  position: fixed;
  z-index: 11;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 85vh;
  align-items: flex-end;
  overflow-y: auto;
  width: 600px;

  @media @phone-sm {
    max-width: 85vw;
  }

  &::-webkit-scrollbar {
    width: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

  &::-webkit-scrollbar-track {
    background: @white;
    height: 20px;
    border-radius:20px ;
  }

  &::-webkit-scrollbar-thumb {
    background: #c8c8c8;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #9e9e9e;
  }
}