.notification {
  &__notification-read {
    width: max-content;
    border: 1px solid @primary-color;
    color: @primary-color;
    padding: 0 1rem;
    border-radius: 30px;
    margin: 0.5rem;
  }

  &__pagination {
    margin-top: 1rem;
  }

  &__divider {
    height: 55vh;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  &__content {
    height: 55vh;
    display: flex;
    width: 100%;
    max-width: 68%;

    @media (max-width: 1024px) {
      max-width: none;
    }
  }

  &__title {
    text-transform: uppercase;
    color: @primary-color !important;

    @media (max-width: 600px) {
      font-size: 20px !important;
    }
  }

  &__scroll {

    height: calc(100% - 60px);
    max-height: calc(100% - 60px);
    overflow-y: auto;


  }

  &__scroll-title {
    height: 100%;
    min-height: 150px;
    max-height: calc(100vh - 620px);
    overflow-y: auto;

  }

  &__expanded-modal {
    display: flex;
    height: 55vh;
    display: flex;
    gap: 1em
  }

  &__center-message {
    width: 100%;
    height: 100%;
    .flex-full-center();
  }

  &__header-tabs {
    @media (max-width: 1024px) {
      background: white;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  &__modal-header {
    display: flex;
    align-items: center;
  }

  &__new {
    padding-left: 1rem;
  }

  &__new-item {
    margin-left: 0.5rem;
    border: 1px solid @tertiary-color;
    color: @tertiary-color;
    padding: 0 0.5rem;
    font-size: 0.8rem;
    justify-content: center;
    border-radius: 20px;
  }

  &__nav {
    width: 100%;

    @media (min-width: 1024px) {
      max-width: 350px;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    background: @white;
    padding: 5px;
    width: 100%;

    &:not(:last-child) {
      border-bottom: 1px solid black;
    }

    @media (max-width: 1024px) {
      margin: .3rem auto;
      max-width: 500px;
    }

  }

  &__title-card {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 2px;
    color: @primary-color !important;
  }

  &__header {
    margin-top: 2px;
    margin-bottom: 5px;
  }

  &__modal {
    width: 90vw !important;
    max-width: 900px;

    .ant-modal-content {
      width: 100% !important;
    }
  }

  &__date-card {
    font-weight: 500;
  }

  &__relative-icon {
    position: relative;
    width: auto !important;
  }

  &__bx-shadow {
    box-shadow: -4px 4px 4px 1px rgba(0, 0, 0, 0.1)
  }

  &__mobile-hide {
    @media (max-width: 1024px) {
      display: none !important;
    }
  }

  &__redirect-proposal-button {
    padding: 0 !important;

    span {
      text-decoration: underline;
    }
  }

  &__text-padding {
    overflow-wrap: break-word;
    padding: 0 10px;
    cursor: pointer;
  }

  &__goBack-button {
    @media (min-width: 1025px) {
      display: none;
    }

    height: 100%;
  }

}