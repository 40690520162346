.agx-report {
  width: 100%;
  gap: 25px;

  display: flex;
  flex-direction: column;
  align-items: center;

  &__card {
    background-color: @white;
    border-radius: @border-radius-base;
    padding: 2rem 2rem 1rem 2rem;
    gap: 20px;
    width: 50%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @media @tab-xl {
      justify-content: center;
      width: 75%;
    }

    @media @tab-md {
      width: 100%;
    }
  }

  &__image-container {
    background-color: @tertiary-color;
    padding: 15px;
    border-radius: @border-radius-base;

    @media @tab-xl {
      display: none;
    }
  }

  &__filter-container {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__filter-title {
    font-size: 20px;
    margin-bottom: 20px;
  }

  &__radio {
    margin-bottom: 10px;

    &--width {
      width: 50%;

      @media @tab-xl {
        width: 75%;
      }

      @media @tab-md {
        width: 100%;
      }
    }
  }

  &__picker {
    &--width {
      width: 50% !important;

      @media @tab-xl {
        width: 75% !important;
      }

      @media @tab-md {
        width: 100% !important;
      }
    }
  }

  &__text-container {
    width: 16.625rem;
    display: flex;
    flex-direction: column;

    & h1 {
      margin: 0;
    }

    .ant-collapse-item:last-child {
      border: 0;
    }

    @media @tab-xl {
      width: none;

      justify-content: center;
      align-items: center;

      p {
        text-align: center;
      }
    }
  }

  &__select-container {
    display: flex;
    flex-direction: column;
  }

  &__icon {
    color: @white;
    font-size: 4rem;
  }
}
