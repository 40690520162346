.modal-product {
  &__see-more {
    cursor: pointer;
    color: @secondary-button;
  }

  &__content {
    padding: 0 0.5rem 0.5rem 0.5rem;
    display: flex;
    align-items: center;
  }

  &__header {
    display: flex;
    align-items: center;
  }

}