// ANTD MEDIA QUERIES
@phone-xs: ~"only screen and (max-width: 480px)";
@phone-sm: ~"only screen and (max-width: 576px)";
@tab-md: ~"only screen and (max-width: 768px)";
@tab-lg: ~"only screen and (max-width: 992px)";
@tab-lg-min: ~"only screen and (min-width: 992px)";
@laptop: ~"only screen and (max-width: 1021px)";
@tab-xl: ~"only screen and (max-width: 1200px)";
@notebook: ~"only screen and (max-width: 1366px)";
@tab-xl-min: ~"only screen and (min-width: 1200px)";
// [1200 - 1600] - normal style //
@desktop-xxl-min: ~"only screen and (min-width: 1600px)";

//Sizes
@filters-space: 8px;
@header-table: 38px;
@auth-page-title-height-and-margin: 38px;
@container-custom-height--desktop: calc(100vh - 118px);
@container-custom-height--laptop: calc(100vh - 148px);
@container-custom-height--mobile: calc(100vh - 144px);
@container-custom-padding: 15px;
@table-filters-max-height: 157.5px;
@table-filters-min-height: 70px;
@pagination-margin-top: 8px;
@pagination-height: 32px;
@navbar-height: 64px;
@full-container-min: calc(100vh - @navbar-height);
@full-container-max: calc(100vh - 104px);
@security-margin: 8px;

@footer-height: 64px;
@secondary-bg-color-chat: #484C52;
@full-container-chat: calc(@full-container-min - @footer-height - @container-custom-padding * 2 - @auth-page-title-height-and-margin - @security-margin * 2);

@server-table-min: calc(@full-container-min - @container-custom-padding * 2 - @auth-page-title-height-and-margin - @table-filters-min-height);
@server-content-min: calc(@server-table-min - (@pagination-height + @pagination-margin-top + @auth-page-title-height-and-margin) - @security-margin);
@server-content-egi-min: calc(@server-table-min - (@pagination-height + @pagination-margin-top + (@auth-page-title-height-and-margin * 2)) - @security-margin);
@server-content-min: calc(@server-table-min - (@pagination-height + @pagination-margin-top) - @security-margin);
@server-table-fiters-min: ~"only screen and (min-width: 886px)";
@server-table-fiters-max: ~"only screen and (max-width: 885px)";

@back: #919191;
@primary-color: #00441F;
@secondary-color: #E0E5CF;
@tertiary-color: #00CF7B;
@primary-button: #79be39;
@base-gray-color:  #65737A;
@secondary-button: #2196f3;
@cancel-button-color: #DD4E4E;
@upload-button-color:#0f406f;
@primary-text: #000000;
@secondary-text: #27537d;
@error-text: #DD4E4E;
@divider-line-color: rgba(97, 97, 97, 0.7);

@analysis-status-color:#00A8E2;
@refused-status-color:#DD4E4E;
@scanned-status-color:#D7CE01;
@approved-status-color:#00CF7B;
@remaking-status-color:#FF7A40;
@starting-status-color:#cacaca;
@canceled-status-color: #949292;
@multi-select-background: #cccccc;
@multi-select-background-hover: #dddddd;
@border-divisor: #ffffff1f;

@gradient-color: #2196f3, #1d8be6, #1880d9, #1375cd, #0d6ac0;
@gradient-color: #79be39, #46B452;

@gradient-primary-color-5:#feffed;
@chat-background: #476077;

@error-color: #DD4E4E;
@success-color: #79be39;
@updated-color: #FFBF00;
@white: #fff;
@black: #000;
@normal-color: #f5f5f5;

//chat
@chat-sender-message-container-color:#9798CC;
@chat-receiver-message-container-color:#0A0B50;
@chat-input-container-color:#C5C5CD;
@chat-analysis-status-color:#FFC107;
@chat-refused-status-color:#DC3545;
@chat-scanned-status-color:#00A8E2;
@chat-approved-status-color:#28A745;
@chat-send-icon-color:#000000;
@chat-icon-color:#0A0B50;

//header
@header-color:@white;
@header-text-color: @black;

//footer
@footer-color:@white;
@footer-text-color:@black;

//Override

@info-color: @primary-color;
@success-color: #79be39;
@warning-color: #FFC107;
@warning-button-color: #FFEE82;
@highlight-color: #d70101;
@white: #fff;
@black: #000;
@btn-default-bg: @primary-color;
@step-button-bg: #b5b5b5;

//Icon

@char-icon-color-1:#070857;
@char-icon-color-2:#000;

//Steps
@docs-color: #7F83D6;
@credit-color: #E48F2B;
@immobile-color:#E5444E;
@juridical-color: #2EAF19;
@registry-color: #133C66;
@proposal-approved-color: #4CED69;

//userDetails
@skeleton-background: #EAEDE1;

// Client Status
@status-default: @primary-color;
@ativo: #00CF7B;
@desligado: #DD4E4E;

//formList
@gray: #5B5B5B;
@light-gray: #f2f2f2;
@light-gray-1: #dfdfdf56;
@light-gray-2: #DFDFDF;
@light-gray-3: #a8a8a8;
@light-gray-4: #d9d9d9;

// COLORS
@plus-button-green: #9FC519;
@plus-button-green-hover: #1f6b33;

@soft-approved:   rgba(0, 207, 123, 0.19);
@soft-reproved:   rgba(255, 106, 106, 0.25);
@soft-neutral:   rgba(255, 240, 106, 0.53);


// border
@border-radius: 20px;

//RICH TEXT
@rich-text-toolbar: #ebebeb;
@rich-text-toolbar-read-only: #d9d9d9;
@rich-text-placeholder-color: #979797;
@rich-text-container-read-only-bg: @normal-color;

@input-border-color: #AAAAAA;

//CSS Compatible vars
* {
  --red-status: @refused-status-color;
  --blue-status: @analysis-status-color;
  --green-status: @approved-status-color;
  --orange-status: @remaking-status-color;
  --warning-status: @warning-color;

  --primary-color: @primary-color;
  --secondary-color : @secondary-color;

  --disable-color: @disabled-color;
  --starting-status-color: @starting-status-color;
  --analysis-status-color: @analysis-status-color;
  --refused-status-color:  @refused-status-color;
  --scanned-status-color:  @scanned-status-color;
  --approved-status-color: @approved-status-color;
  --remaking-status-color: @remaking-status-color;
  --canceled-status-color: @canceled-status-color;

  --gradient-primary-color-5: @gradient-primary-color-5;
  --status-client-ativo: @ativo;
  --status-client-desligado: @desligado;
  --status-default: @status-default;
  --payment-type-ted: #E48F2B;
  --payment-type-boleto: #133C66;
  --light-gray: #f2f2f2;
  --light-gray-4: #d9d9d9;
  --white: #fff;
}
