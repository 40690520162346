.wrapper-single-line-table {
  border: 2px solid @secondary-color;
  border-radius: 16px;

  div {
    border-top: 1px solid @secondary-color;
    padding: 5px;

    &:first-child {
      border-top: transparent;
      border-radius: 16px;
    }

    &:last-child {
      border-bottom: transparent;
      border-radius: 0 0 8px 8px;
    }

    &:nth-child(even) {
      background: @secondary-color;
    }

    &:nth-child(odd) {
      background: #FFF;
    }
  }
}

.single-line-simulation-title {
  margin-left: 25px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 10px;
  position: relative;

  &:before {
    content: "";
    width: 20px;
    height: 2px;
    background-color: @primary-color;
    position: absolute;
    bottom: 50%;
    right: 100%;
  }
}

.simulator-resumes-titles {
  font-family: Montserrat;
  font-size: 16px;
  line-height: 33.6px;
  color: @primary-color;
  text-transform: uppercase;
  font-weight: 700;
}

.simulator-resume-sub-titles {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}

.simulator-label {
  color: @primary-color;
  font-size: 15px !important;
  font-weight: 700;
}

.simulator-table {
  &__modified-padding {
    table td {
      padding: 4px 16px !important;
    }

    th {
      line-height: 0 !important;
    }
  }

  .ant-table-pagination-right {
    align-items: center !important;
  }

  table:first-of-type {
    thead th {
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;

      text-transform: uppercase;
      color: white;
      background-color: @primary-color;
    }

    tbody tr {
      color: @primary-color;
      font-weight: 700;

      &:nth-child(even) {
        background: #fff;
      }

      &:nth-child(odd) {
        background: @secondary-color;
      }
    }
  }
}
