@phoneScreen: ~"(max-width: 1024px)";

@keyframes showMenu {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.icon {
  font-size: 20px;
  color: @black;
}

.notification-icon {
  font-size: 20px;
  color: @white !important;
  position: relative;

  &--with-notifications {
    &::after {
      content: attr(data-count);
      position: absolute;
      background-color: var(--red-status);
      border-radius: 50%;
      width: 20px;
      height: 20px;
      top: 0;
      right: 0;
      font-size: 13px;
      transform: translate(50%, -50%);
      padding-top: 9px;
    }
  }
}

.mobile-icon {
  font-size: 25px;
  color:@black !important;
}

.content {
  width: 300px;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  background: @white;
  margin-top: 20px;
  padding: 0 5px;
  border-radius: 4px;

  @media @phoneScreen {
    margin-top: 30px;
  }
}

.context {
  position: relative;
  height: 100%;
  width: 100%;
}

.paragraph{
  position: relative;
}

.navbar-header {
  line-height: 0;
  background-color: @primary-color;
  @media @tab-md {
    padding-right: 0;
    padding-left: 0;
  }

  position: relative;
  @media @tab-xl {
    min-height: 90px;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &__communication {
    cursor: pointer;
    margin-bottom: 0;
  }
}

.navbar-image {
  width: 125px !important;
  margin-bottom: 5px;
}

.userInfo-level {
  &::after {
    content: " -";
    margin-right: 5px;
  }

  @media @phoneScreen {
    display: none;

    &::after {
      content: "";
      margin-right: 0;
    }
  }
}

.row-navbar {
  max-height: 64px;
  width: 100%;

  @media @tab-xl {
    min-height: 90px;
  }

  @media @tab-md {
    padding-right: 1rem;
  }
}

.logo-alignment {
  align-items: center;

  & > div {
    margin-right: 10px;
  }

  @media @tab-xl{
    transform: translateX(36px);
    margin-right: 0;

    flex-direction: column;
    align-items: flex-start;
  }
}

.invisible-col {
  display: none;
  @media @tab-md {
    display: flex;
    max-width: 85px;
  }
}

.alignment-items-dropdown {
  display: flex;
  border-radius: 3px;

  &:hover {
    cursor: pointer;

    svg {
      color: @primary-color;
      cursor: pointer;
    }

    & > p {
      color: @primary-color;
      cursor: pointer;
    }
  }

  &:not(:last-child) {
    border-radius: 0;
    border-bottom: 1px solid rgb(187, 187, 187);
  }

  p {
    font-weight: bold;
    margin: 0px;
  }
}

.dropdown-icons-menu {
  padding: 20px;
  margin: 7px 0px;
  min-width: 240px;
  max-width: 90vw;

  &__product-dropdown {
    display: none;

    @media @tab-md {
      display: flex;
      flex-direction: column;
      margin-top: 5px;
    }
  }
  
  &__user-name {
    width: 160px; 
  }
}

.navbar-product-dropdown {
  @media @tab-md {
    display: none;
  }
}

.expand-menu-icons {
  height: 35px;
  width: 35px;
  background: rgb(216, 216, 216);
  .flex-full-center();
  border-radius: 50%;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.9;
    cursor: pointer;

    svg {
      transition: color 0.2s;

      color: @primary-color;
      cursor: pointer;
    }
  }

  @media @phoneScreen {
    top: -5px;
  }

 @media (max-width: 380px){
    height: 30px;
    width: 30px;
  }
}

.navbar-notification {
  position: relative;

  section {
    display: flex;
    align-items: center;
    transition: color 0.2s;
    width: 100%;

    &:hover {
      cursor: pointer;

      svg {
        color: #fff;
        cursor: pointer;
      }

      & > p {
        color: @tertiary-color;
        cursor: pointer;
      }
    }
  }

}

.ant-notification.ant-notification-topRight {
  div:not([class]) {
    .ant-notification-notice.proposal-notification.ant-notification-notice-closable {
      border-radius: 5px;
      border-left: @tertiary-color 8px solid;
    }
  }

  .proposal-notification {
    .ant-notification-notice {
      &-message {
        color: @tertiary-color;
        font-weight: 800;

        &--error {
          color: var(--red-status) !important;
        }
      }

      &__title {
        margin-left: 5px;

        &--error {
          color: var(--red-status) !important;
        }
      }

      &__description {
        display: flex;
        flex-direction: column;
        color: #227353;

        &--error {
          color: var(--red-status) !important;
        }
      }

      &__details {
        color: @tertiary-color;
        text-decoration: underline;
        margin-left: 0.5px;

        &--error {
          color: var(--red-status) !important;
        }
      }
    }

    &--error {
      border-left: var(--red-status) 8px solid !important;
    }
  }
}

