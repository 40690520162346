@import './components/FeeReviewForm/FeeReviewForm.less';

.fee-drawer {
  .ant-drawer-content-wrapper {
    width: 35% !important;

    @media (max-width: 992px) {
      width: 75% !important;
    }

    @media (max-width: 576px) {
      width: 100% !important;
    }
  }
}