.verify-email-succes-svg {
  &__first-baloon {
    animation: floatingBaloon 4.5s infinite 1s ease-in-out;
  }

  &__second-baloon {
    animation: floatingBaloon 3.5s infinite ease-in-out;
  }

  &__third-baloon {
    animation: floatingBaloon 4s infinite .5s ease-in-out;
  }

  @keyframes floatingBaloon {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(20px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
}