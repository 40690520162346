.proposal-anticipation-modal {
  &__due-list {
    display: flex;
    gap: .5rem;
    flex-wrap: wrap;
  }

  &__due  {
    background-color: rgba(0, 0, 0, 0.75);
    color: @white;
    padding: .25rem 1rem;
    display: flex;
    gap: .5rem;
    border-radius: 5px;
  }

  &__close-icon {
    display: flex;
    align-items: center;
  }

  &__result-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid @light-gray-3;
    padding: .5rem 0;

    @media @phone-sm {
      flex-direction: column;
    }
  }

  &__results-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    @media @phone-sm {
      flex-direction: column;
    }
  }

  &__button {
    @media @phone-sm {
      width: 100%;
    }
  }
}