.docs-nav {
  overflow-y: scroll;
  position: fixed;
  bottom: 0;
  right: 0;
  height: calc(100vh - @navbar-height);
  background-color: #FAFAFA;
  padding: 1rem;
  width: 25%;

  @media @phone-sm {
    display: none;
  }

  &--show {
    display: block;
    bottom: 0;
    right: 0;
    width: 100%;
    height: calc(100vh - 104px); // viewheight - nav height on mobile
  }

  &__topic-title {
    padding: 0.3rem 0.5rem !important;
    border-radius: 10px !important;
    height: 0.5rem !important;

    &:hover {
      background-color: @light-gray;
    }
  }

  &--active {
    background-color: @light-gray;
    border-radius: 10px;
    padding: 0.3rem 0.5rem;
  }

  li {
    list-style: none;
  }

}