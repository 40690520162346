#root .signup-checkbox {
  .ant-checkbox-inner {
    border: 1px solid @primary-color;
    width: 24px;
    height: 24px;
    border-radius: 5px;

    &::after {
      left: 26%;
      top: 45%;
      width: 7px;
      height: 15px;
    }
  }
}