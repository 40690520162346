.simulation-flow-ecpf {

  &__tooltip-insurance {
    width: 100%;
    text-align: left;

    background-color: transparent;
    border: none;
    color: inherit;
    cursor: pointer;
    padding: 0;
    margin: 0;

    transition: all 0.2s;

    &:hover {
      opacity: 0.8;
    }

    &:focus {
      outline: none;
    }
  }

  .ant-radio-group {
    .ant-radio-button-wrapper {
      border: 1px solid var(--primary-color);
      background-color: transparent;
    }

  .ant-radio-button-wrapper:first-child {
    border-radius: 8px 0 0 8px;
  }

    .ant-radio-button-wrapper:last-child {
      border-radius: 0 8px 8px 0;
    }

    .ant-radio-button-wrapper-checked {
      background-color: var(--primary-color);
      color: white;

      &:hover {
        color: white;
      }
    }
  }

  &__form-item-meta-calculo {
    display: inline-block;
    width: 100%;

    @media @phone-sm {
      margin-bottom: 0;
    }
  }

  &__simulate-button {
    @media @phone-sm {
      width: 100%;
    }
  }

  &__form-item-security {
    display: inline-block;

    .ant-form-item-label {
      padding: 0;
    }
  }

  &__more-info-btn {
    padding: 0;
    height: auto !important;
  }

  &__card-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.25rem;
  }

  &__card {
    padding: 1rem 1.875rem;
    border-radius: 20px;
    background-color: transparent;
    border: 1px solid var(--primary-color);
    display: inline-block;
    text-align: center;

    @media @phone-sm {
      flex: 1;
    }

    &--installment {
      background-color: var(--primary-color);
    }
  }

  &__card-title,
  &__card-value {
    color: var(--primary-color);
    margin: 0;
    padding: 0;
    font-weight: bold;

    &--installment {
      color: white
    }
  }

  &__card-title {
    font-size: 0.875rem;
  }

  &__card-value {
    font-size: 1.25rem;
  }

  &__loading-card {
    padding: 4rem;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__loading-instruction-svg {
    padding-bottom: 2rem;

    @media @phone-sm {

      svg {
        width: 300px !important;
      }
    }
  }

  &__simulation-limit-container {
    max-width: 600px;
  }

  &__error-container {
    max-width: unset;
  }

  &__insurance {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;

    label {
      width: 100%;
    }

    @media @phone-sm {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.simulation-flow-ecpf-min-max {
  display: flex;
  justify-content: space-between;
  color: @light-gray-3;
  font-size: 0.7rem;

  .ant-spin {
    line-height: 1 !important;
    font-size: unset;
  }

  .ant-spin-sm .ant-spin-dot {
    font-size: 9px;
  }

  &*>{
    display: block;
  }
}

