.minimalist-table {
  width: 100%;
  border-collapse: collapse;
  min-width: 800px; 

  &__container {
    width: 100%;
    overflow-x: auto;
    &::-webkit-scrollbar {
      background: var(--scroll-bar-color) !important;
      width: 10px;
      height: 10px;
    }
  
    &::-webkit-scrollbar-thumb {
      background: var(--primary-color);
      border-radius: 20px;
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background: lighten(@primary-color, 5%);
    }
  }

  &__section-title {
    font-weight: bold;
    font-size: 1rem;
    color: @primary-color
  }

  &__table {
    width: 100%;
  }

  &__row-header {
    th {
      color: @primary-color;
      height: 40px;
      font-weight: 1.2rem;
    }
  }

  &__row {
    border-bottom: 1px solid @primary-color;
    padding: 1rem 0;
    height: 40px;
  }

  &__item {
    min-width: 100%;
    padding: 5px 10px;
    white-space: nowrap;
  }
}


@media @tab-md {
  .proposal-due {
    overflow: scroll;

    &__title h3 {
      padding: 1rem 0;
      text-align: center;
    }

    &__table {
      overflow: scroll !important;
      margin-left: 1rem;
      width: 120%;
    }

    &__row {
      white-space: nowrap;
      grid-gap: 1rem;
    }
  }
}