.button-send-divulgation {
  text-transform: uppercase;
  margin-top: 30px;
  width: 25%;
}

.divulgation-card {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.divulgation-icon {
  font-size: 20px;
  color: ''
}

.divulgation-download {
  border: 1px solid @approved-status-color;
  border-radius: 15px;
  background-color: #fff;

  & .divulgation-icon {
    font-size: 18px;
    color: @approved-status-color
  }

  &:hover {
    transition: 200ms;
    background-color: @approved-status-color;

    & .divulgation-icon {
      color: #fff;
    }
  }

}

.divulgation-edit {
  border: 1px solid @analysis-status-color;
  border-radius: 15px;
  margin: 0 10px;
  background-color: #fff;

  & .divulgation-icon {
    font-size: 18px;
    color: @analysis-status-color
  }

  &:hover {
    transition: 200ms;
    background-color: @analysis-status-color;

    & .divulgation-icon {
      color: #fff;
    }
  }
}

.divulgation-delete {
  border: 1px solid @refused-status-color;
  border-radius: 15px;
  background-color: #fff;

  & .divulgation-icon {
    font-size: 18px;
    color: @refused-status-color
  }

  &:hover {
    transition: 200ms;
    background-color: @refused-status-color;

    & .divulgation-icon {
      color: #fff;
    }
  }
}

@media (max-width: 1275px) {
  .divulgation-card {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1135px) {
  .divulgation-card {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 769px) {
  .divulgation-card {
    grid-template-columns: repeat(1, 1fr);
  }
}
