.score-form{
  &__info-text {
    color:  @primary-color;
  }

  &__form-list {
    &--card {
      display: flex;
      flex-direction: row;

      @media @tab-xl {
        flex-direction: column;
      }
    }

    &--input-container {
      width: 94%;
      margin-right: 1%;
      background-color: @white;
      padding: 2em;
      border-radius: 30px;

      @media @tab-xl {
        width: 100%;
      }
    }

    &--remove-button {
      width: 5%;
      background-color: @white;
      border: 1px solid transparent;
      border-radius: 30px;
      cursor: pointer;
      transition: all .4s ease-in-out;

      @media @tab-xl  {
        width: 100%;
        margin-top: 20px;
        padding: .5rem;
      }

      &:hover {
        background-color: var(--refused-status-color);
        color: @white;
      }
    }

    &--add-button {
      border: 1px solid @primary-color;
      color: @primary-color;

      &:hover {
        background-color: @primary-color;
        color: @white;
      }
    }

    &--sub-tittle {
      font-size: 1rem;
      font-weight: bold;
    }
  }
}