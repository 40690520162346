.skeleton-text-skeleton {
  display: flex;
  align-items: center;

  &, &__content {
    width: ~"min(200px, 100%)";
  }

  &__content {
    border-radius: 4px;
    &, .ant-skeleton-input {
      height: 16px;
    }
  }
}
