.range-label {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: .9rem;
  gap: 1.5em;
  text-align: center;

  @media @phone-xs {
    font-size: .8rem;
  }

  &__item {
    &:first-child {
      text-align: left;
      margin-right: auto;
    }
    &:last-child {
      text-align: right;
      margin-left: auto;
    }
  }

  &__label {
    white-space: nowrap;
  }

  &__value {
    font-size: 1.05eem;
    font-weight: 600;
  }
}
