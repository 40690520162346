.access-manager-details {
  &__detail-wrapper {
    border-bottom: 1px solid black;

    &__label {
      max-width: 100%;
      font-size: .8rem;
      font-weight: 700;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;

    }

    &__value {
      font-size: .9rem;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }

  &__products-tags-wrapper {
    display: flex;
    flex-direction: column;
    gap: .25rem;
  }

  &__tag {
    font-size: .5rem;
  }

  &__collapse {
    background: transparent;
    border: 0px;
  }

  &__collapse-panel {
    background-color: white;
    border-radius: 14px !important;
    margin-bottom: 8px;
  }
}