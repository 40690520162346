.find-user-covenant-select {
    &__description {
        display: flex;
        .flex-full-center();
        flex-direction: column;
    }

    &__description-icon {
        margin-bottom: 1rem;
    }

    &__input {
        width: ~"min(335px, 100%)";

        button {
            margin-top: 0.5rem;
            width: ~"min(335px, 100%)";
        }
    }

}