#root .proposal-line {
  display: flex;
  flex-direction: column;

  @media @phone-sm {
    align-items: center;
  }

  &__widget {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
