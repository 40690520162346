@import '../ProposalStepButtonWithHistory/ProposalStepButtonWithHistory';
@import '../ProposalStepSkeleton/ProposalStepSkeleton';
@import '../AcceptContract/AcceptContract';
@import './DocumentalStep/DocumentalStep';
@import './ScoreStep/ScoreStep';
@import './UploadStepSection/UploadStepSection';
@import '../DocumentalProcess/DocumentalProcess';

.proposal-step {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  &:last-child figure::after {
    content: unset;
  }

  &__content {
    display: block; 
  }

  &__content-title {
    color: @primary-color;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.1rem;
  }

  &__content-subtitle {
    color: @primary-color;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.1rem;
  }

  &__content-description {
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }
}
