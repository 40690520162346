.sort-configuration {
  &__primary-focus {
    font-weight: bold;
    color: @primary-color;
    margin: 0;
  }

  &__value-label {
    margin: 1rem 0;
  }
  &__value-show {
    background-color: @light-gray;
    .flex-full-center();
    padding: 1rem;
    border-radius: 30px;
    margin-top: 0.5rem;
  }

  &__value-container {
    margin-bottom: 1rem;
  }

  &__radio {
    width: 100%;
    .ant-radio-wrapper{
      margin: 0;
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      align-items: center;
    }
    
  }

  &__loading-input {
    .ant-skeleton-button, .ant-skeleton {
      width: 100%!important;
      display: flex;
      height: 45px!important;
      margin-bottom: 1rem!important;
    }
  }

  &__radio-item {
    padding: 1rem 0;
  }
  
}