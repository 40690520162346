.proposal-debit-balance {
  padding: 2rem;
  display: grid;
  grid-template-columns: 2fr 8fr;

  &__icon {
    font-size: 5rem;
    color: @primary-color;
  }

  &__title {
    display: grid;
    grid-template-columns: 8fr 4fr;
  }

  &__date-container {
    position: relative;
  }

  &__title h3 {
    color: @primary-color;
    font-size: 1.5rem;
    font-weight: bold;
  }

  &__section-title {
    font-weight: bold;
    font-size: 1rem;
    color: @primary-color;
  }

  &__display {
    display: flex;
    margin-bottom: 1rem;
  }

  &__display-item {
    border: 1px solid @primary-color;
    color: @primary-color;
    padding: 0.5rem;
    .flex-full-center();
    border-radius: 5px;
    flex-direction: column;

    &:nth-child(2) {
      margin: 0 1rem;
    }

    span {
      text-align: center;
      font-size: 1.5rem;
      font-weight: bold;
    }
  }

  &__table {
    width: 100%;
  }

  &__row {
    &:not(:first-child) {
      border-bottom: 1px solid @primary-color;
      padding: 1rem 0;
    }
    display: grid;
    grid-template-columns: 20fr 4fr;
    th {
      color: @primary-color;
    }
  }

  &__empty {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__exclamation-icon {
    padding-bottom: 0.5rem;
    font-size: 1.5rem;
    color: @primary-color;
  }

  &__skeleton-container {
    display: flex;
    margin-bottom: 1rem;
  }

  &__display-skeleton {
    height: 2rem;
    margin: 0.5rem;
    width: 6rem;
  }
}

@media @tab-md {
  .proposal-debit-balance {
    grid-template-columns: 1fr;
    overflow: scroll;

    &__title {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
    }

    &__title h3 {
      padding: 1rem 0;
      text-align: center;
    }

    &__display {
      flex-direction: column;
    }

    &__display-item {
      width: 100%;
      &:nth-child(2) {
        margin: 0.5rem 0;
      }
    }

    &__table {
      overflow: scroll !important;
      margin-left: 1rem;
    }
    &__row {
      white-space: nowrap;
      grid-gap: 1rem;
    }

    &__skeleton-container {
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    &__display-skeleton {
      width: 100%;
    }
  }
}
