.backoffice-products-button {

  &__icon {
    background-color: transparent;
    border: none;
    color: inherit;
    cursor: pointer;
    padding: 0;
    margin: 0;
    font: inherit;
    outline: none;

    width: 25px;
    height: 25px;
  }
}