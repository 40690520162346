
.info-card {

  background-color: @light-gray;
  padding: 1rem;
  border-radius: 20px;

  &__loading {
    height: 100px !important;
  }

  &__title {
    color: @primary-color;
    font-size: 0.8rem;
  }

  &__info {
    color: @primary-color;

    .ant-skeleton-paragraph {
      margin: 0;

      li {
        height: 25px;
      }
    }

    &--error {
      color: var(--red-status);

      .anticon {
        color: var(--red-status);
        margin-left: 5px;
      }
    }
  }
}
