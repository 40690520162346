.internal-review-settings {
  .tox {
    border-radius: @border-radius !important;
    border: 1px solid @light-gray-4 !important;
    color: @text-color;
  }

  &__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
  }

  &__display-text {
    background-color: @light-gray;
    padding: 1rem;
    border-radius: @border-radius;
    list-style-position: inside;
  }

  &__text-header {
    display: flex;
    justify-content: space-between;
  }

  &__skeleton {
    height: 300px !important;
    width: 100% !important;
    border-radius: @border-radius;
  }

  &__last-modification {
    font-size: 0.7rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items:flex-end ;
    
  }

  &__history-button {
    width: fit-content;
    margin-top: 0.5rem;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
  }

  &__header-title {
    max-width: 80%;
  }

  &__deleted {
    color: var(--red-status) !important;
  }
}