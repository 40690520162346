.client-initial-ecpf {
  overflow-y: hidden;

  &__title {
    animation: revealContent .6s ease-in-out;

    @media @phone-sm {
      font-size: 1rem !important;
    }
  }

  &__title-container {
    @media @tab-xl {
      width: 100%;
    }
  }

  &__subtitle {
    animation: revealContent .7s ease-in-out;
    font-size: 1rem;
  }

  &__header {
    @media @tab-xl {
      flex-direction: column;
      align-items: start;
    }
  }

  &__button {
    animation: revealContent .8s ease-in-out;

    @media @phone-sm {
      width: 100%;
    }
  }

  &__header-card {
    background-color: @white;
    padding: 1rem;
    border-radius: 12px;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    animation: revealContent .7s ease-in-out;
  }

  &__header-image {
    background-color: rgba(@primary-color, 0.15);
    padding: .8rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    color: @primary-color;
    font-size: 1.2rem;
  }

  &__proposal-list {
    display: grid;
    width: 100%;
    gap: 10px;
    grid-template-columns: repeat(auto-fill, calc(25% - 8px));
    animation: revealContent 2s ease-in-out;

    @media @tab-xl {
      grid-template-columns: repeat(auto-fill, calc(50% - 10px));
    }

    @media @tab-md {
      grid-template-columns: 1fr;
    }
  }

  &__advantages-section {
    display: grid;
    width: 100%;
    gap: 10px;
    grid-template-columns: repeat(auto-fill, calc(20% - 8px));

    @media @tab-xl {
      grid-template-columns: repeat(auto-fill, calc(50% - 10px));
    }

    @media @phone-sm {
      display: flex;
      overflow-x: auto;
    }
  }

  &__proposal-subtitle {
    animation: revealContent 1.7s ease-in-out;
  }

  &__proposal-description {
    animation: revealContent 1.8s ease-in-out;
  }

  &__status-select-container {
    animation: revealContent 1.9s ease-in-out;
  }

  &__pagination {
    animation: revealContent 2.1s ease-in-out;
  }

  &__proposal-card-header {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
  }

  &__proposal-card-header-title {
    font-size: 1rem;
  }

  &__proposal-card-header-subtitle {
    color: @gray;
    font-size: 0.7rem;
  }

  &__proposal-card-label {
    font-size: 0.8rem;
    color: @gray;

    &--end {
      text-align: end;
    }
  }

  &__proposal-card-value {
    &--end {
      text-align: end;
    }
  }

  &__proposal-card {
    background: white;
    padding: 1rem;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: .6rem;
    transition: .2s ease-in-out;

    &--clickable {
      cursor: pointer;

      &:hover {
        filter: brightness(96%);
      }
    }

    & .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph{
      margin-top: 0 !important;
      margin-bottom: 5px;
    }

    & .ant-skeleton-content .ant-skeleton-title {
      margin-top: 5px !important;
      margin-bottom: 5px;
    }
  }

  &__header-card-content {
    width: 50%;
    gap: 1rem;
  }

  &__card-title {
    margin-bottom: 0;
    @media @phone-sm {
      font-size: 1.1rem;
    }
  }

  &__card-description {
    @media @phone-sm {
      font-size: .8rem;
    }

    &--footer {
      font-size: 0.8rem;

      @media @phone-sm {
        font-size: .6rem;
      }
    }
  }

  &__img {
    margin: 25px 0;

    @media @phone-sm {
      height: 150px;
      margin: 15px 0;

      svg {
        height: 125px;
      }
    }
  }

  &__select-status {
    display: flex;
    gap: 10px;
    align-items: center; 
    flex-wrap: wrap;
  }

  &__select-status-option {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    transition: .2s all ease-in-out;
    background-color: @white;
    border-radius: 40px;
    flex-direction: row;
    padding: .5rem .8rem;
    color: var(--primary-color);

    &:hover {
      background-color: var(--primary-color);
      color: @white;
    }

    &--selected {
      background-color: var(--primary-color);
      color: @white;
    }

    &--disabled {
      cursor: not-allowed;
      opacity: .5;
    }

    @media @phone-sm {
      font-size: 12px;
    }
  }
}

.advantage-card {
  background-color: @white;
  min-height: 215px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 1.2rem;
  transition: all .2s ease-in-out;
  gap: 1rem;

  @media @phone-sm {
    padding: 0.9rem;
    flex-direction: row;
    min-height: fit-content;
    align-items: center;
    gap: .8rem;
    max-width: 220px;
    flex: 0 0 auto;
  }

  &:hover {
    transition: .2s ease-in-out;
    transform: scale(1.05);
    filter: brightness(98%);
  }

  &__image {
    height: 65px;

    @media @phone-sm {
      height: 45px;
    }
  }

  &__content-container {
    display: flex;
    gap: 1rem;
    flex-direction: column;

    @media @phone-sm {
      gap: .3rem;
    }
  }

  &__title {
    font-size: 1rem;
    font-weight: bold;
    color: @primary-color;

    @media @phone-sm {
      font-size: .8rem;
      text-align: start;
      line-height: .9rem;
    }
  }

  &__description {
    font-size: .8rem;
    line-height: .8rem;

    @media @phone-sm {
      font-size: .7rem;
      text-align: start;
    }
  }

  &--first-card {
    animation: revealContent .8s ease-in-out;
  }

  &--second-card {
    animation: revealContent 1s ease-in-out;
  }

  &--third-card {
    animation: revealContent 1.2s ease-in-out;
  }

  &--fourth-card {
    animation: revealContent 1.4s ease-in-out;
  }

  &--fifth-card {
    animation: revealContent 1.6s ease-in-out;
  }
}

@keyframes revealContent {
  0% {
    opacity: 0;
    transform: translateY(80px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}