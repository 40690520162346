.simulator-full-page {
  background-color: rgba(245, 247, 249, 0.95);
  min-height: 100vh;
  max-height: 100%;

  @media (max-width: 425px){
    background-image: url('/assets/img/bg-banner-cgi.png');
    background-size: contain;
    background-repeat: no-repeat;
  }

  @media (min-width: 426px) {
    background-image: url('/assets/img/bg-banner-cgi-notebook.png');
    background-size: contain;
    background-repeat: no-repeat;
  }

  @media (min-width: 1024px) {
    background-image: url('/assets/img/bg-banner-cgi-desktop.png');
    background-size: contain;
    background-repeat: no-repeat;
  }

  @media (min-width: 1920px) {
    background-image: url('/assets/img/bg-banner-cgi-desktop.png');
    background-size: cover;
    background-repeat: no-repeat;
  }

  @media (max-width: 1024px) {
    height: 100%;
  }
}

.simulator-wrapper {
  border: '1px solid grey';
  display: flex;
  padding: 13px 150px;

  @media (max-width: 1400px) and (min-width: 1200px) {
    padding: 13px 150px;
  }

  @media (max-width: 1200px) {
    justify-content: center;
    padding: 0 10px;
    justify-content: flex-start;
    flex-direction: column;
  }

  .ant-form-vertical .ant-form-item-label {
    padding-bottom: 3px;
  }

  .ant-form-item {
    margin-bottom: 16px;
  }
}

.simulator-left-side-block-text {
  max-width: 500px;
  text-align: left;
  align-self: flex-start;
  margin-top: 65px;

  @media (max-width: 1200px) {
    max-width: 400px;
    margin-top: 30px;
    align-self: flex-start;
  }

  @media (max-width: 500px) {
    width: 100%;
    margin-top: 30px;
    align-self: flex-start;
  }

  @media (max-width: 375px) {
    margin-top: 30px;
    width: 100%;
  }
}

.simulator-main-text {
  font-family: Montserrat;
  font-weight: 500;
  font-style: normal;
  margin-bottom: 32px;
  margin-top: 64px;
  font-size: clamp(2.75rem,5vw,5rem);
  line-height: 48px;
  letter-spacing: 2px;
  color: white;

  @media (min-width: 1200px) {
    line-height: 75px;
  }

  @media (max-width: 1200px) {
    margin-top: 32px;
  }

  @media (max-width: 320px) {
    margin-bottom: 20px;
  }
}

.simulator-description-text {
  font-family: Montserrat-Regular,-apple-system,BlinkMacSystemFont,Segoe UI,Montserrat,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  color: white;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 1px;

  @media  (max-width: 320px) {
    margin-bottom: 12px;
  }
}

.simulator-layout-link-client {
  border: 1.6px solid @primary-color;
  border-radius: 50px;
  padding: 10px 45px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF !important;

  &:hover {
    color: @primary-color !important;
  }
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: transparent;
  border-bottom: solid;
  border-color: @primary-color !important;
  border-bottom-width: 1.5px;
  margin-left: -15px;
}
