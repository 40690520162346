.info-card {
  &__total-card {
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: space-between;
  }

  &__total-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 70%;
    height: 100%;
  }

  &__title {
    font-size: 1rem;

    @media @phone-sm {
      font-size: .8rem;
    }
  }

  &__value {
    font-size: 1.1rem;
    font-weight: bold;
    color: @primary-color;

    @media @phone-sm {
      font-size: 1rem;
    }
  }

  &__icon {
    .flex-full-center();
  }

  &__icon svg {
    height: 80%;
    width: 100%;
    color: @primary-color;
  }
}