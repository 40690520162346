.pdf-container canvas{
  width: 100% !important;
  height: 100% !important;
}

.react-pdf__Document {
  display: flex;
  justify-content: center;
}

.pager {
  .flex-full-center();

  &__pagination {
    margin: 0 25px;
  }

  &__download {
    padding: 10px;
    border-radius: 50%;
    background-color: @primary-color;

    transition: all .2s;

    &:hover {
      background-color: lighten(@primary-color, 5%);
    }
  }
}