.proposal-line-view-ecpf {

  &__resume-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
  }

  &__buttons-container {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
  }
}