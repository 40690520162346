#root .ecp-signup-form {
  &__verification-input-container {
    padding: 1rem;
    border-radius: @border-radius-base;
    background-color: @secondary-color;
    min-height: 100px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;

    .vi__wrapper {
      height: 60px;
    }
  }

  &__verification-input {
    width: 80%;
    margin: auto;

    * > {
      .flex-full-center();
      line-height: 0;
      font-family: inherit;
      height: 60px;

      font-family: Montserrat !important;
      border-color: @primary-color;
      border-radius: @border-radius-base;
    }

    @media @phone-sm {
      width: 100%;
    }
  }

  &__password-help-container {
    margin: 1.35rem 0;
  }

  &__password-help-text {
    font-size: 1.05rem;
  }
}