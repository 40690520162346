.total-card {
  display: flex;
  align-items: center;
  background-color: @white;
  border-radius: 15px;
  gap: 1rem;

  &__icon-container {
    min-width: 40px;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(@primary-color, 0.2);
    border-radius: 5px;
  }

  &__info-label {
    color: @gray;
  }

  &__default-color {
    background: rgba(@primary-color, 0.2);
  }

  &__error-color {
    background: rgba(@error-text, 0.2);
  }

  &__succes-color {
    background: rgba(@tertiary-color, 0.2);
  }
}