.review-radio {
  &__container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: wrap;

    @media @tab-lg {
      display: flex;
      flex-direction: column;
      height: max-content;
    }
  }

  &__item {
    align-items: center;
    background-color: white;
    border-radius: 20px;
    color: @primary-color;

    height: 50px;
    padding: 0.5rem 0.75rem;
    margin-right: 0;
    
    outline: 1px solid @light-gray-4;
    white-space: normal;
    transform: translateZ(0);
    
    display: flex;
    justify-content: flex-end;
    flex: 1;
    max-width: 250px;
    min-width: 180px;

    &--more-than-three {
      min-width: 160px;
      max-width: 100%;

      @media @notebook {
        min-width: 180px;
      }

      @media @tab-xl {
        min-width: 160px;
      }
    }

    &--big-label {
      order: 0;

      @media @notebook  {
        order: 5;
      }

      @media @tab-lg {
        order: 0;
      }
    }

    &--first {
      order: -1
    }

    @media @tab-lg {
      justify-content: center;
      max-width: 100%;
    }

    @media @phone-sm {
      justify-content: flex-end;
    }

    .ant-radio,
    .ant-radio-checked {
      display: none !important;
    }

    &--approved:hover{
      outline-color: @approved-status-color !important;
    }
    
    &--approvedWithRestriction:hover {
      outline-color: @approved-status-color !important;
    }

    &--approvedManually:hover {
      outline-color: @approved-status-color !important;
    }

    &--reproved:hover {
      outline-color: @refused-status-color !important;
    }

    &--canceled:hover {
      outline-color: @refused-status-color !important;
    }

    &--remaking:hover {
      outline-color: @remaking-status-color !important;
    }

    &--reservation:hover {
      outline-color: @approved-status-color !important;
    }

  }

  &__item-active--aproved {
    background-color: @approved-status-color;
    color: #fff;
  }

  &__select-svg {
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);

    .gooey {
      filter: url('#goo');
    }

    .drop,
    .dropTop,
    .drops {
      opacity: 0;
    }

    .toggler {
      cursor: pointer;
    }
  }

  .ant-radio {
    display: none;
  }

  &__icon {
    position: absolute;
    font-size: 0.8rem;
    opacity: 0;
    left: 1.5rem !important;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
  }

  &__option-label {
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    width: 115px;
    margin-top: 0.3rem;
    text-align: end;

    @media @notebook {
      width: 100%;
    }

  }
}

