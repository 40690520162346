.buttons-node {
  &__button-group {
    display: flex;
    justify-content: space-between;
  }

  &__go-back-button {
    border-color: @primary-color;
    color: @primary-color;
    transition: all .4s ease-in-out;

    &:hover {
      background-color: @primary-color;
      color: @white;
    }
  }
}