.review-step-history {
  padding: 1rem 1.25rem;
  border-radius: @border-radius-base;
  background-color: @light-gray;
  text-align: left;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    @media @tab-lg {
      flex-direction: column;
    }    
  }

  &__tags-container {
    margin-bottom: 0.8rem;
    display: flex;
    flex-wrap: wrap;
    row-gap: 0.5rem;
    align-items: center;
  }

  &__modified-info {
    color: var(--primary-color);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 0.7rem;
    flex-wrap: wrap;

    @media @tab-lg {
      flex-direction: row;
      align-items: flex-start;
      column-gap: 0.5rem;
    }
  }

  &__title-message {
    font-size: 0.85rem;
    font-weight: bold;
    display: block;
    margin: 0rem 0 0.5rem;
    
    @media @tab-lg {
      margin-top: 0.9rem;;
    }   
  }
  
  &__commentary {
    padding: 0.2rem 0 !important;
    margin-bottom: 0.8rem;
    font-size: 0.85rem;
    border-right: none !important;
    border-left: none !important;
  
  }

  &__new-item-properties {
    font-size: 0.8rem;
    
    &--value {
      font-weight: 600;
    }
  }

  &__new-item-separator {
    margin: 0 0.5rem;
  }

  &__share-status-link {
    font-size: 0.7rem;
    text-align: center;
    border-radius: 0.5rem;
    color: @secondary-color;
    white-space: unset !important;
    padding: 0.5rem;
    border: 1px solid var(--primary-color);
    background-color: rgba(0, 68, 31, 0.10);
  }

  &__share-status-expires {
    font-size: 0.7rem;
    font-weight: bold;
  }

  &__change-item {
    font-size: 0.8rem;
    margin: 0;
    padding: 0.1rem;
    transition: 0.2s all ease-in-out;

   &:hover {
    background-color: @light-gray-2;
    font-weight: 500;
   } 
  }

  &__proposal-history-change-container {
    background-color: @light-gray-2;
    border-radius: 0.5rem;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
  }

  &__proposal-history-change-text,
  &__proposal-history-change-link {
    font-size: 0.8rem;
    font-weight: 600;
    text-align: center;
  }

  &__proposal-history-change-link {
    color: var(--primary-color);
    cursor: pointer;
    display: block;
    margin-top: 0.5rem;

    &:hover { text-decoration: underline; }
  }

  .ant-collapse-borderless  {
    margin-bottom: 0.5rem;
  }

  .ant-collapse-borderless >
  .ant-collapse-item >
  .ant-collapse-header {
    padding-left: 1.2rem;
    font-weight: bold;
    font-size: 0.9rem;
    border-bottom: 1px solid var(--light-gray-4);
    padding-bottom: 0.2rem;
    padding-top: 0.5rem;
    margin-bottom: 0;
  }

    
  .ant-collapse-borderless >
  .ant-collapse-item >
  .ant-collapse-header >
  .ant-collapse-arrow {
    left: 0;
    padding-top: 0.5rem;
  }

  .ant-collapse-borderless >
  .ant-collapse-item >
  .ant-collapse-content >
  .ant-collapse-content-box {
    padding: 0.6rem 0 0;
  }

  & td {
    border: 1px solid;
    min-width: 40px;
  }
}