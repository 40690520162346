.ecp-input-share {
  padding: 10px;
  border-radius: @border-radius-base;
  background-color: @light-gray;
  display: flex;
  align-items: center;
  gap: 10px;

  &__button {
    cursor: pointer;
    outline: none;
    border: none;
    background: transparent;
  }

  &__icon {
    font-size: 30px;

    @media @phone-xs {
      display: none;
    }

    &--send {
      display: block;
    }
  }
}
