.client-instructions {
  &__instructions-container {
    display: flex;
    align-items: center;
    background-color: @light-gray;
    padding: 1rem;
    color: @primary-color;
    border-radius: 20px;
    margin-top: 1rem;
  }

  &__skeleton-title {
    width: 40%!important;
    margin-bottom: 0.5rem;
    border-radius: 5px;
  }

  &__skeleton-card {
    border-radius: 20px;
    height: 5rem!important;
  }

  &__skeletons {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
}