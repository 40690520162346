.verify-email {
  max-width: clamp(43rem, 30%, 30%) !important;
  min-height: calc(100vh - 128px);;
  justify-content: center;

  @media @phone-sm {
    max-width: 100%;
    justify-content: space-evenly;
  }

  &__title {
    @media @phone-sm {
      font-size: 22px !important;
    }
  }

  &__result-text {
    @media @phone-sm {
      font-size: 16px !important;
    }
  }

  &__succes-image {
    @media @phone-sm {
      max-height: 200px;
    }
  }

  &__error-image {
    min-height: 300px;
    width: 100%;

    @media @phone-sm {
      max-height: 200px;
      min-height: initial;
    }
  }

  &__arrow-down {
    font-size: 2rem;
    color: @primary-color;
    margin: 1rem;
  }

  &__instruction {
    text-align: center;
    font-size: 1.2rem;
  }

  &__try-again-button {
    border-color: @error-color;
    color: @error-color;
    transition: all .2s ease-in-out;

    &:hover {
      color: @white;
      background-color: @error-color;
      border-color: @error-color;
    }
  }

}
