.menu {
  &__header {
    display: flex;
    align-items: center;
    padding: 2rem 0;

    @media @tab-md {
      display: none;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 2rem;
    color: @primary-color;
  }

  &__text {
    max-width: 80%;
  }

  &__description {
    padding-left: 3rem;
  }

  &__empty-card {
    background-color: white;
    padding: 2rem;
    border-radius: 20px;
  }

  &__search {
    display: flex;
    position: relative;
  }

  &__search-icon {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    color: @light-gray-4;
  }
}

.menu-card {
  background-color: white;
  border-radius: @border-radius-base;
  padding: 1rem 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;

  @media @tab-md {
    flex-direction: column;
    text-align: center;
  }

  &__section {
    .flex-full-center();

    @media @tab-md {
      flex-direction: column;
      text-align: center;
    }
  }

  &__icon>* {
    background-color: @secondary-color;
    font-size: 1.5rem;
    padding: 1rem;
    border-radius: 50%;
    color: @primary-color;
  }

  &__title {
    color: @primary-color;
    font-weight: bold;
    margin: 1rem;
    text-align: center;

    @media @tab-md {
      margin: 0;
    }
  }

  &__description {
    margin: 1rem;
    text-align: center;

    @media @tab-md {
      margin: 0.5rem 0;
    }
  }


  &__texts {
    max-width: 80%;
  }
}