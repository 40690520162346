.unauth-upload-file {
  &__container {
    .flex-full-center();  
    flex-direction: column;
  }

  &__instructions {
    background-color: @light-gray;
    border-radius: 20px;
    padding: 1rem ;
    margin: 1rem 0 ;
  }

  &__list {
    list-style-position: inside;
    margin: 0;
  }

  &__title {
    font-weight: bold;
    color: @primary-color;
  }
  &__card {
    margin: 1rem;
    padding: 2rem;
    width: 900px;

    @media @tab-md{
      width: 95%;
      padding: 1rem;
    }
  }

  &__icon {
    color:  @approved-status-color ;
    font-size: 2rem;
    padding-bottom: 0.5rem;
  }

  &__sent {
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
  }

  &__hide-upload{
    display: none;
  }


  &__skeleton-document {
    min-height: 4.125rem;
    border-radius: 1.25rem;
  }

  &__upload-svg {
    height: 11rem;
    @media @tab-md {
      display: none;
    }
  };

  &__sent-svg {
    height: 12rem;
    width: fit-content;
  }

}

