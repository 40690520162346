html #root {
  .proposal-unauth-contract-wrapper {
    max-width: 1000px;
  }
}

.proposal-unauth-contract {
  &__gap {
    display: flex;
    flex-direction: column;
    gap: 2em;
  }

  &__buttons {
    flex-direction: row-reverse;
    max-width: 600px;
    margin: auto;
    width: 100%;

    & > * {
      flex-grow: 1;
      min-width: 280px;
    }
  }
}
