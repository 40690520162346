.limit-details {
  &__h3 {
    color: var(--primary-color);
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
  }

  &__expand-icon {
    margin-top: 1.5px;
    color: var(--primary-color);
    left: 0 !important;
  }

  &__table {
    tr {
      border-bottom: 1px solid var(--primary-color);
      height: 40px;
    }

    td:last-child {
      white-space: nowrap;
      text-align: right;
    }
    .ant-skeleton-element {
      width: 30px!important
    }

    @media @phone-sm {
      font-size: .75rem;
    }
  }
  @media @phone-sm {
    font-size: .8em;
  }

  &__panel .ant-collapse-header{
    padding-left: 25px !important;
  }

  &__panel .ant-collapse-content-box {
    padding: 0 !important;
  }
}
