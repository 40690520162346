.upload-list-inline {

  .ant-upload-list-item {
    float: left;
    width: 200px;
    margin-right: 8px;
    cursor: pointer;
  }

  .ant-upload-list-item-card-actions {
    span {
      transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 400ms;
    }

    &:hover {
      span  {
        color: red
      }
    }
  }
}


.upload-img-size {
  width: 100%;
  max-height: calc(100vh - 20vh);
  object-fit: scale-down;
}

.upload-button-size-explain {
  border: 1px solid @primary-color;
  border-radius: 5px;
  min-height: 40px;
}

.upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
  float: right;
}

.upload-skeleton {
  width: 200px !important;
  height: 60px !important;

  &:nth-child(2) {
    margin-left: 10px;
  }

  &:nth-child(3) {
    margin-left: 10px;
  }

  @media (max-width: 1115px) {
    &:nth-child(3) {
    display: none;
    }
  }

  @media (max-width: 765px) {
    width: 160px !important;

    &:nth-child(3) {
     display: none;
    }
  }

  @media (max-width: 630px) {
    &:nth-child(2) {
     display: none;
    }
  }

  @media (max-width: 576px) {
    width: 200px !important;

    &:nth-child(2) {
     display: inline;
    }
  }

  @media (max-width: 457px) {
    width: 200px !important;

    &:nth-child(2) {
     display: none;
    }
  }
}
