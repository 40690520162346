.client-details-ecpf {

  &__auth-title {
    line-height: 3;
    margin-bottom: 0;

    @media @phone-sm {
      line-height: 2;
    }
  }

  &__avatar {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    gap: 15px;
    margin-bottom: 50px;

    @media @tab-md {
      display: none;
    }
  }

  &__square-button {
    border-radius: 12px !important;
  }

  &__edit-button {
    @media @phone-sm {
      width: 100%;
    }
  }
}