.request-cnd {
  &__panel {
    .ant-collapse-header {
      color: @primary-color !important;
    }

    .ant-collapse-content-box {
      padding: 0 20px !important;
    }
  }

  &__list {
    list-style-type: none;
    padding: 0 20px;

    li {
      margin: 5px 0;
    }
  }
}