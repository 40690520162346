.proposal-create {
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  align-items: center;

  .center-content-width {
    width: ~"min(100%, 550px)";
    margin: auto
  }
  // ANTD
  .ant-steps-item-wait .ant-steps-item-icon,
  .ant-steps-item-finish .ant-steps-item-icon {
    background: transparent;
    border-color: var(--primary-color);
  }

  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: var(--primary-color);
  }

  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: var(--primary-color);
  }
}
