.transfer-modal {
  &__display-new-admin {
    .flex-full-center();
    text-align: center;
  }

  &__icon {
    background-color: @primary-color;
    color: white;
    padding: 0.5rem;
    font-size: 1.2rem;
    border-radius: 50%;
    margin: 1rem;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
  }

}