.RH-tansfer-save-modal {
  &__table-container {
    max-width: 800px;
    overflow-x: auto;
    border-radius: 5px;
    padding: 10px;
  }

  &__table {
    width: 100%;
    border-collapse: collapse;
    overflow-x: auto;
    border-radius: 10px!important;
  }
  
  &__header {
    font-weight: bolder;
    padding-bottom: 0.5rem;
    color: @primary-color;
  }

  &__info {
    border-radius: 10px;
    width: 100%;
    align-items: center;
    margin: 1rem 0;
    padding: 0.2rem 0;
  }

  &__item-old {
    background-color: rgba(255, 106, 106, 0.25);
    padding: 0.5rem;
    border-radius: 10px;
  }

  &__item-new {
    background-color:  rgba(0, 207, 123, 0.19);
    padding: 0.5rem;
    border-radius: 10px;
  }

  &__column {
    border: 1px solid @light-gray-2;
    padding: 8px;
    text-align: left;
    white-space: nowrap;
  }

  &__button {
    margin-top: 2rem;
  }

}
