@import '../../components/ServerTable/ServerTableFilters.less';

.limiter-table {
  button .ant-pagination-item-link {
    padding-right: 5px;
  }

  .ant-form-item {
    margin-bottom: 0px;
  }

  .table-filter {
    button {
      border-radius: 0px 20px 20px 0px !important;
      padding-left: @filters-space;
      padding-right: @filters-space;
    }
  }

  .ant-table table, .ant-table {
    border-radius: 10px 0px 0px 0px;
  }

  .ant-table-container table > thead > tr:first-child th:first-child  {
    border-top-left-radius: 10px !important;
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 0 !important;
  }


  div#server-table {
    max-height: @server-content-min;
    overflow: auto;

    @media @tab-lg {
      max-height: none;
      height: auto;
    }
  }

  .antd-base-table.server-table--deparment-row div#server-table {
    @department-row-proposal-list-height: 50px;
    max-height: calc(@server-content-min - @department-row-proposal-list-height);

    @media @tab-lg {
      max-height: none;
      height: auto;
    }
  }

  .ant-table-wrapper.antd-base-table {
    overflow: hidden;
  }

  ::-webkit-scrollbar-corner {
    background-color: @secondary-color !important;
  }

  .ant-form.ant-form-vertical {
    margin-bottom: @filters-space;
  }

  #server-table {
    &::-webkit-scrollbar {
      background: @secondary-color !important;
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--primary-color);
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: lighten(@primary-color, 5%);
    }
  }

  .server-table {
    @media @tab-md {
      max-height: 0px;
    }

    &__updateTime {
      font-size: 14px;
    }

    &-expand-image {
      height: 40px;
      width: 40px !important;
      object-fit: scale-down;
      border-radius: 50%;
    }

    &__color-text-total {
      color: var(--primary-color)
    }

    &__limits-pagination {
      margin: 0 5px;
    }

    &__check-icon {
      color: var(--primary-color);
      margin-left: 5px;
    }

    &__pagination {
      height: @pagination-height;
      margin-top: @pagination-margin-top;

      & .ant-pagination-options {
        display: none;
      }
    }
  }

  .switch-responsive-layout {
    @media @tab-lg {
      margin-left: 5px;
      margin-bottom: 5px;
    }
    @media @tab-md {
      margin-bottom: 0px;
    }
  }

  .server-table-switch-filter {
    transform: translateY(30px);

    @media @tab-xl {
      transform: translateY(0);
    }
  }

  .image-cell {
    padding-left: 10px !important;
  }

  .switch-input {
    width: 90px;
    white-space: nowrap
  }

  a.link-details-corban {
    color: @tertiary-color;
    text-decoration: underline;
  }
}
