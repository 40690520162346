.token-validation {
  &__method-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &__description {
    margin-bottom: 20px;
  }

  &__button {
    width: 350px;
    border: 1px solid transparent;
    cursor: pointer;

    &--email-button {
      background-color: @white;
      color: @primary-text;
      border-color: @primary-color;
    }
  }

  &__submit-button,
  &__back-button {
    width: 100%;
    cursor: pointer;
  }

  &__back-button {
    border-color: @primary-color;
  }

  &__input-container {
    width: 100%;
    gap: 15px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  &__input-label {
    margin-bottom: 8px;
  }

  &__header {
    margin-bottom: 20px;
  }

  &__title {
    font-size: 24px;
    color: @primary-color;
    font-weight: bold;
  }

  &__svg {
    margin: 0 auto;
    max-width: 200px;
    height: auto;
    padding-bottom: 2rem;
    display: flex;
    justify-content: center;
  }

  &__buttons-container {
    display: flex;
    gap: 15px;
  }
}
