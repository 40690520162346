.verification-input {
  background: none;
  width: 100% !important;
}

.verification-input .vi__character--default {
  border: none;
  font-size: 1.3rem;
  line-height: 50px;
  color: black;
  background-color: #efefef;
  margin-left: 6%;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: Montserrat;
  font-weight: bold;

  @media @phone-sm {
    margin-left: 10px;
  }
}

.verification-input .vi__character--inactive--default {
  color: transparent;
}