.error-details-ecpf {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__simulation-whatsapp {
    font-size: 0.9rem;
  }

  &__simulation-title {
    margin-bottom: 10px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.8rem;

    max-height: 200px;
    overflow-y: auto;

    max-width: 500px;
    min-width: 300px;

    &--full-height {
      max-height: unset;
    }

    @media @phone-sm {
      min-width: none;
    }

    &::-webkit-scrollbar {
      background: var(--scroll-bar-color) !important;
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--primary-color);
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: lighten(@primary-color, 5%);
    }
  }

  &__warning-svg {
    width: 500px !important;
    padding-bottom: 2rem;

    @media @phone-sm {
      padding-bottom: 1rem;
      width: 300px !important;
    }
  }

  &__item {
    background-color: @light-gray;
    border-radius: 10px;
    padding: 1rem 0.7rem;
    color: @black;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    align-items: flex-start;
  }

  &__result {
    padding: 1rem;
  }

  &__message {
    font-size: 0.8rem;
    word-wrap: break-word;
    text-align: start;
    width: 100%;
  }

  &__code {
    font-size: 0.6rem;
    word-wrap: break-word;
    text-align: start;
  }
}