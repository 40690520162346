@import './components/DocsNavBar/docsNavbar.less';

.docs {
  padding: 0 1rem;

  &__main-content {
    display: flex;

  }

  &__show-nav {
    position: fixed;
    bottom: 5rem;
    z-index: 200;
    right: 10%;
  }

  &__header {
    font-weight: bolder;
    color: @primary-color;
    font-size: 2rem;
  }

  &__show-nav {
    display: none;

    @media @phone-sm {
      display: block;
    }
  }

  &__container {
    width: 70%;

    @media @phone-sm {
      width: 100%;
    }

    padding: 1rem;

    & h2,
    & h1 {
      color: @primary-color;
      font-weight: 600;
      font-size: 1.5rem;
    }

    & h2 {
      padding-top: 0.5rem;
      font-size: 1.2rem;
    }

  }

}