.operation-limit {
  &__container {
    &--fill {
      max-width: 100%;
    }
  }

  &__title {
    color: @primary-text;
    font-weight: 700;
    font-size: 1rem;
  }

  &__value-title {
    text-align: left;
  }

  &__max-value-label {
    color: #65737a !important;
    font-size: 10px !important;
  }


  &__skeleton {
    width: 100%!important;
  }
}

