#root .onidata-logs-details {
  &__list {
    &--load-button {
      border-radius: 20px 20px 0 0;
      
      .ant-collapse-item:last-child {
        border-radius: 0;
      }
    }
  }

  &__button {
    &.ant-btn-primary {
      border-radius: 0 0 20px 20px !important;
      width: 100%;
    }
  }

  &__header {
    align-items: center;
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
    margin-bottom: 2rem;
  }

  &__range-picker {
    max-width: 300px;
  }

  &__status-select {
    width: 15rem;
  }
}