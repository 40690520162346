@import '../AcceptContractModal/AcceptContractModal';

.accept-contract {
  &__title {
    &--terms {
      font-size: 1rem;
    }
  }

  &__inputs-container {
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media @phone-sm {
      padding: 0;
    }
  }

  &__decline-button {
    border-color: @primary-color;
    color: @primary-color;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: @white;
      background-color: @primary-color;
    }

    @media @tab-xl {
      margin-bottom: 10px;
    }
  }
}
