.maintenance-modal {

  &__rodobens-logo {
    height: 80px;
  }

  &__logo-container {
    margin-bottom: 0;
    text-align: center;
  }

  &__base-image-container {
    margin-bottom: 20px;

    @media @phone-sm {
      margin-bottom: 10px;
    }
  }

  &__paragraph {
    font-size: 1.05rem;
    margin-bottom: 30px;

    @media @phone-sm {
      font-size: 1rem;
      margin-bottom: 20px;
    }
  }

  &__contact-texts {
    margin-bottom: 50px;

    @media @phone-sm {
      margin-bottom: 35px;
    }
  }

  &__footer {
    text-align: right;
  }

  &__footer-ball {

    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;

    &:not(:last-child) {
      margin-right: 5px;
    }

    &--gray {
      background-color: #e0e5cf;
    }

    &--green {
      background-color: #ff6600;
    }

    &--orange {
      background-color: #00441f;
    }
  }
}