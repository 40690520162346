.renegotiation-create {
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  align-items: center;

  .center-content-width {
    margin: auto
  }

  &__sub-title {
    color: var(--primary-color);
    font-weight: bold;
    margin: 0;
    font-size: 1.3rem;
  }

  &__card {
    padding: 2rem !important;
    width: 100%;

    @media @phone-sm {
      padding: 1rem;
    }
  }

  &__simulation-form {
    color: var(--primary-color);
    display: flex;
    flex-direction: column;
    grid-gap: 1em;
    gap: 1em;
  }

  &__debit-balance-value {
    cursor: pointer;
    font-weight: bold;
    color: var(--red-status);
    transition: all .2s ease-in-out;
    
    &:hover {
      color: @black;
    }
  }
  

  // ANTD
  .ant-steps-item-wait .ant-steps-item-icon,
  .ant-steps-item-finish .ant-steps-item-icon {
    background: transparent;
    border-color: var(--primary-color);
  }

  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: var(--primary-color);
  }

  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: var(--primary-color);
  }

  & .ant-radio-button-wrapper {
    background: @light-gray !important;
    border-color: @light-gray;
    overflow: hidden;
    font-size: .9rem;
  }

  & .ant-radio-button-checked {
    background-color: @primary-color;
  }
}
