.unauth-content {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.unauth-upload-header {
  .flex-full-center();
  background-color: @secondary-color;
}

.unauth-logo-file-upload {
  max-width: 200px;
  max-height: 68px;
  padding: 5px;
}

.messages {
  background-color: #fff;
  max-width: 950px;
  width: 100%;
  border-radius: 15px;
  margin: 30px 0;
  padding: 15px;
  -webkit-box-shadow: 0px 3px 6px #272D3B33;
  box-shadow: 0px 3px 6px #272D3B33;

  @media screen and (max-width: 965px) {
    width: 75%;
  }

  @media screen and (max-width: 680px) {
    width: 100%;
    margin-top: 0;
    margin-bottom: 30px;
    border-radius: 0 0 8px 8px ;
  }
}

.messages-content-header {
  .flex-full-center();
}

.messages-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.messages-content-documents {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 95%;
  box-shadow: 0 3px 6px rgb(39 45 59 / 20%);

  & .card-style.p-3.flex.flex-column.flex-justify-center.flex-align-center.image-card {
    margin-left: 5px;
  }

  @media screen and (max-width: 520px) {
    flex-direction: column;

    & .card-style.p-3.flex.flex-column.flex-justify-center.flex-align-center.image-card {
      margin-left: 0;
    }
  }
}

.messages-content-info {
  font-size: 30px;
  margin: 0;
}

.document-container {
  background-color: #fff;
  width: 100%;
  border-radius: 15px;
  -webkit-box-shadow: 0px 3px 6px #272D3B33;
  box-shadow: 0px 3px 6px #272D3B33;
}

.document-grid {
  display: grid;
  grid-template-columns: 470px 470px;
  justify-content: center;
  gap: 18px;

  @media screen and (max-width: 965px) {
    grid-template-columns: 470px;
  }

  @media screen and (max-width: 482px) {
    grid-template-columns: 340px;
  }

  @media screen and (max-width: 342px) {
    grid-template-columns: 320px;
  }
}

.document-upload-dashed {
  width: 75%;
  border: 2px dashed @primary-color;
  border-radius: 15px;
  flex-direction: column;
  .flex-full-center();

  & .ant-upload-list.ant-upload-list-picture {
    margin-bottom: 10px;
  }
}

.documents-submit {
  width: 100%;
  display: flex;
  justify-content: center;
}

.documents-submit-button {
  width: 300px;
  min-height: 50px;
  text-transform: uppercase;
  background-color: @tertiary-color !important;
  border: none !important;
  color: white !important;
  -webkit-box-shadow: 0px 3px 6px #272D3B33 !important;
  box-shadow: 0px 3px 6px #272D3B33 !important;
  font-weight: 500 !important;
}

.documents-submit-button:hover {
  transform: translateY(-5px);
}

.documents-list-item {
  margin-bottom: 25px;
}

.document-title {
  font-size: 1.4em;
  font-weight: 600;
  margin-bottom: 0;

  @media screen and (max-width: 600px) {
    font-size: 1em;
    white-space: normal;
  }
}

.documents-done-indicator {
  font-size: 1.4em;
}

.documents-sigCanvas {
  border: solid 1px black;
  width: 90%;
}

.image-animate {
    opacity: 0.7;
}

.rotate-phone {
	-webkit-animation: rotate-phone 2s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite;
          animation: rotate-phone 2s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite;
          -webkit-animation-delay: 2s;
          animation-delay: 2s;
}


 @-webkit-keyframes rotate-phone {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }
  50% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }
  90% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }
}

@keyframes rotate-phone {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: bottom 100%;
            transform-origin: bottom 100%;
  }
  50% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transform-origin: bottom 100%;
            transform-origin: bottom 100%;
    opacity: 1;

  }
  90% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transform-origin: bottom 100%;
            transform-origin: bottom 100%;
    opacity: 0.9;
  }
  91% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transform-origin: bottom 100%;
            transform-origin: bottom 100%;
    opacity: 0;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: bottom 100%;
            transform-origin: bottom 100%;
  }
}
