.full-screen-lgpd {
  .flex-full-center();

  background-color: var(--secondary-color);
  color: var(--primary-color);
  flex-direction: column;
  font-size: 14px;
  min-height: 100vh;
  padding: 1rem;

  @media @phone-xs {
    justify-content: flex-start;
    height: unset;
  }
  
  &__container {
    display: flex;
    flex-direction: inherit;
    gap: 10px;
    max-width: 1280px;
    width: 100%;
  }

  &__header {
    color: var(--primary-color);
    font-size: 18px;
    font-weight: bold;
  }
}
