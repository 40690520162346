.valuation-date-formdate {
  margin-top: 30px;
}

.read-only-formdate-input {
  background-color: #f3f3f3;
}

@media(max-width: 1200px) {
  .email-wrapper-formdate, .phone-wrapper-formdate{
    margin-top: 15px;
  }
}