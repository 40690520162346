.rh-transfers-buttons {
  &__save-button {
    border-color: @primary-color;
    color: @primary-color;
    transition: all .2s ease-in-out;

    &:hover {
      background-color: @primary-color;
      color: @white;
    }
  }
}