.intl-input-group {
  display: flex;
  align-items: center;
  justify-content: center;

  &__money {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &__percentage {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__suffix {
    width: 40px;
    height: 40px;
    background-color: #DFDFDF;
    font-weight: bold;

    .flex-full-center();

    border-radius: @back;
    border: 1px solid #d9d9d9;
  }

  &__preffix-money {
    border-top-left-radius: @border-radius-base;
    border-bottom-left-radius: @border-radius-base;
  }

  &__suffix-percentage {
    border-top-right-radius: @border-radius-base;
    border-bottom-right-radius: @border-radius-base;
  }

  &__footer {
    display: flex;
    font-size: 0.75rem;
    justify-content: space-between;
    margin-top: 0.5rem;
    width: 100%;

    filter: opacity(60%);

    &--only-max {
      justify-content: flex-end;
    }
  }
}