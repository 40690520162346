.ecp-lgpd {
  text-align: center;

  &__pdf {
    height: 70vh;

    @media @phone-xs {
      height: 70vh;
    }

    @media @desktop-xxl-min {
      height: 75vh;
    }
  }

  &__button {
    min-width: 15rem;

    &--logout {
      background: var(--secondary-color);
      border: 2px solid var(--primary-color);
      color: var(--primary-color);

      &:hover {
        color: @white;
      }
    }

    @media @phone-sm {
      min-width: 60%;
    }
  }

  &__signature-title {
    font-size: 1.1rem;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  &__button-container {
    align-items: center;
    display: flex;
    gap: 10px;
    justify-content: center;
  }
}
