
.minuta-preview-step {
  &__section-title {
    font-size: bold;
    font-size: 20px;
  }

  &__signer-section {
    background: @light-gray;
    padding: 8px;
    border-radius: 8px;
  }
}

.form-contract-clausulas {
  & table {
    width: 100%;
    padding: 5px;
  }

  & td {
    border: 1px solid;
    min-width: 40px;
    padding: 3px;
  }
}
