.partner-step-button {
  display: flex;
  align-items: center;
  background: transparent;
  outline: none;
  border-radius: @border-radius-base;

  border: none;

  &--enabled {
    cursor: pointer;

    &:focus-visible {
      outline: 2px solid @analysis-status-color;
      outline-offset: -2px;
    }

    &:hover > p {
      background-color: darken(@white, 5%);
    }
  }
  
  &__icon {
    margin: 0;
    display: flex;
    position: relative;
    z-index: 1;

    svg {
      width: 26px;
      height: 26px;

      @media @tab-lg {
        width: 22px;
        height: 22px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      background-color: @step-button-bg;

      z-index: 0;
      left: 50%;
      top: 100%;

      width: 1px;
      height: 15px;

      @media @tab-lg {
        height: 12px;
      }
    }
  }

  &__name {
    color: @primary-color;
    font-size: .9rem;
    font-weight: 500;
    border-radius: @border-radius-base;

    padding: .3rem;
    padding-left: .5rem;
    margin-bottom: 0;

    @media @tab-lg {
      padding: .2rem;
    }

    @media @tab-lg {
      font-size: .8rem;
    }

    @media @phone-sm {
      display: flex;
      flex-wrap: wrap;
    }
    
    @media @phone-xs {
      text-align: start;
    }
  }
}

.partners-flag {
  padding: 1px 2px;
  border-radius: 4px;
  font-size: 0.75rem;
  margin-top: 1px;
  margin-right: 8px;
  margin-bottom: 0px;
  margin-left: 8px;
  color: @white;
  min-width: 80px;
  max-width: auto;

  @media @phone-sm {
    font-size: .6rem;
    min-width: 45px;
    max-width: auto;
  }
  
  &--signature {
    border: 1px solid @approved-status-color;
    background-color: @approved-status-color;
    
    @media @phone-sm {
      margin-left: 0;
    }
  }

  &--required-step {
    border: 1px solid @immobile-color;
    background-color: @immobile-color;
  }
}

.partners-tooltip {
  align-self: center;
  color: @immobile-color;
  cursor: pointer;
}
