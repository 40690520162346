.cell {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 1.5em;
  font-size: 1em;

  border: 0.5px solid #b8cce4;
  color: #1f497d !important;
  font-weight: 700;

  p {
    font-size: .75em !important;
  }

  .ant-input {
    text-align: center !important;
    outline: none !important;
    border: none !important;
    color: #1f497d !important;
    font-weight: 700 !important;
    border-radius: 0  !important;
    font-size: .75em !important;
    height: 1em !important;

    &:focus {
      outline: none !important;
      border: none !important;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
      margin: 0 !important;
    }
  }

  .ant-select {
    text-align: center !important;
    outline: none !important;
    border: none !important;
    color: #1f497d !important;
    font-weight: 700 !important;
    border-radius: 0  !important;
    width: 100% !important;

  }

  .ant-input-number {
    text-align: center !important;
    outline: none !important;
    border: none !important;
    color: #1f497d !important;
    font-weight: 700 !important;
    border-radius: 0  !important;
    width: 100% !important;
    font-size: .75em !important;
    height: 1.5em !important;
    padding: 0;
    
    .ant-input-number-handler-wrap {
      display: none;
    }

    .ant-input-number-input {
      text-align: center !important;
      outline: none !important;
      border: none !important;
      color: #1f497d !important;
      font-weight: 700 !important;
      border-radius: 0  !important;
      font-size: 1em !important;
      height: 1em !important;
      width: 100% !important;
    }
  }

  .ant-select-selector {
    text-align: center !important;
    outline: none !important;
    border: none !important;
    color: #1f497d !important;
    font-weight: 700 !important;
    border-radius: 0  !important;
    font-size: .75em !important;
    padding: 0 !important;
    height: 1.5em !important;
    width: 100% !important;

    &:focus {
      outline: none !important;
      border: none !important;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
      margin: 0 !important;
    }
  }

  .ant-select-selection-item {
    line-height: inherit !important;
    height: 1.5em !important;
  }
  
  .ant-select-single {
    height: 1em !important;
  }

  p{
    margin: 0px !important;
  }

}

.cell-primary {
  background-color: #1f497d;
  color: #FFF;
  font-weight: bolder;
  justify-content: center;
  display: flex;
  flex-direction: row;
}

.cell-secundary {
  background-color: #dbe5f1;
  color: #1f497d;
  font-weight: 700;

  .ant-input {

    background-color: #dbe5f1 !important;
    color: #1f497d !important;
  }
}

.spreadsheet {
  border: 0.5px solid #b8cce4;
  padding-top: 2em !important;
  padding-bottom: 2em !important;
  text-align: center;
}

.cell-value {
  justify-content: flex-end !important;
  border: none !important;
  padding-right:1em ;

  .ant-input {
    text-align: end !important;
    outline: none !important;
    border: none !important;
    color: #1f497d !important;
    font-weight: 700 !important;
    font-size: 1em !important;
    height: 1.5em !important;
  }
}

.cell-text{
  padding-left:1em ;
  justify-content: flex-start !important;
  border: none !important;
}

.cell-title {
  padding-left:1em ;
  justify-content: flex-start !important;
  text-decoration: underline;
}

.cell-value-final {
  padding-right:1em ;
  justify-content: flex-end !important;
  text-decoration: underline;

  .ant-input {
    padding-right:1em !important;
    justify-content: flex-end !important;
    text-decoration: underline !important;
  }
}

.spreadsheet-cr-model {
  padding-top: 2em !important; 
}

.spreadsheet-limit {
  max-height: calc(15 * 1.5em);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
}
