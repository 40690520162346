.bacenStep {
  &__text {
    position: relative !important;
  }

  &__image {
    width: 40%;
    opacity: 0.35;
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
  }

  &__agreementText {
    font-size: 12px;
  }
}