@import './components/SelectLevel/SelectLevel';
@import './components/GRecaptcha/GRecaptcha';

.signin {
  &__cookies-button {
    background-color: transparent;
    border: none !important;
    color: var(--primary-color) !important;
    cursor: pointer;
    display: block !important;
    font-size: .8rem;
    margin: auto;
    text-align: center;
    text-decoration: underline;

    &:hover {
      background-color: transparent !important;
      opacity: .6;
    }
  }

  &__basic-swam-html {
    p {
      margin-bottom: 10px;
    }

    a {
      color: var(--analysis-status-color);
      text-decoration: underline;
    }
  }
}

