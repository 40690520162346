.rh-transfers-header {
  &__header-container {
    @media @tab-xl {
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
    }
  }

  &__value-title {
    @media @phone-sm {
      margin-top: 1.5rem;
    }
  }

  &__download-button {
    display: flex;
    align-items: center;
    border: 1px solid @primary-color;
    color: @primary-color;

    &:hover {
      background-color: @primary-color;
      color: @white;
    }
    
    @media @tab-xl {
      width: 100%;
      justify-content: center;
    }
  }

  &__cards-container {
    gap: 10px;

    @media @phone-sm {
      flex-direction: column;
    }
  }

  &__info-texts {
    margin-left: 20px;
    text-align: start;
  }

  &__progress-card {
    @media @tab-xl {
      width: 100%;
    }

    @media @phone-sm {
      justify-content: space-between;
    }
  }

  &__value-card {
    min-width: 150px;

    @media @tab-xl {
      width: 50%;
    }
  }
}
