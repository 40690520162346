
.zip-documents-download-modal {

  &__title {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
  }

  &__buttons {
    @media @phone-sm {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

  }

  &__direct-download {
    margin-right: 0.5rem;
    @media @phone-sm {
      margin-right: 0;
      margin-bottom: 0.5rem;
    }
  }

}
