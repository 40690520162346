.access-manager-client {
  &__svg-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
    flex-direction: column;
    gap: 20px;

    @media @phone-sm {
      height: 30%;
    }
  }

  &__search-svg-container {
    @media @phone-sm {
      height: 50%;
    }
  }
}