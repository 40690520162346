.ecp-card {
  background-color: #fff;
  border-radius: @border-radius-base;
  padding: 2rem 2rem 1rem 2rem;
  .client-form__fields {
    padding: 0px !important;
  }

  @media @tab-lg {
    padding: 1rem 1rem 0.5rem 1rem;
  }
}
