@import './InviteShareInput';

.ecp-invite {

  &__medium-margin {
    margin-bottom: 1.65rem;
  }

  &__header {
    margin-bottom: 1.375rem;
  }

  &__title {
    font-size: 1.4rem;
    margin-bottom: 0;
  }

  &__container {
    display: flex;
    gap: 15px;
  }

  &__description {
    font-size: .85rem;
    color: @gray;

    &--header {
      max-width: 80%;
    }

    &--qr-code {
      @media @phone-xs {
        margin-bottom: .85rem;
      }
    }
  }

  &__share-link {
    width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;


    word-break: break-all;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__link-wrapper {
    display: inline-block;

    height: 43px;
    padding: 10px;
    width: 100%;
    overflow: hidden;

    border-radius: @border-radius-base;
    background-color: @light-gray;
  }

  &__qr-code {
    @media @phone-xs {
      margin-bottom: .85rem;
    }
  }

  &__button {
    height: 43px !important;
    border-radius: 15px !important;

    &--qrcode {
      height: 40px !important;
      min-width: 125px;
    }
  }

  &__qr-container {
    display: flex;
    gap: 15px;

    @media @phone-xs {
      display: block;
    }
  }

  &__qr-code-texts-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
}
