
.property-form-owner {

  &__remove-icon {
    .flex-full-center();
    width: 30px!important;
    height: 30px!important;
    font-size: 0.9rem;
  }

  &__checkbox {
    @media @tab-md {
      .ant-form-item-label {
        display: none;
      }
    }
    
  }

}
